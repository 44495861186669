/**
 * Created by mnassih on 25/09/2020.
 */

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import {
  getuserinfo,
  updateuserinfo,
  getaudiences,
} from '../../redux/actions/adminAction';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from 'react-loader-spinner';
import '../../styles/components/userinfo.css';
const UserForm = (props) => {
  const { getIdTokenClaims } = useAuth0();

  const [loaded, setLoaded] = useState(false);
  const [userSelected, setUserSelected] = useState({});
  const [userFromTable, setUserFromTable] = useState({});

  const callApi = async () => {
    setUserFromTable(props.userSelected);
    console.log('userFromTable :', userFromTable);

    const token = await getIdTokenClaims();
    props.dispatch(getaudiences(token)).then(() => {
      props.audiences.forEach((value) => {
        if (value['@id'] == userFromTable.audience) {
          setUserFromTable({ ...userFromTable, audience: value.id });
        }
      });
      setLoaded(!loaded);
    });
  };

  const setUserAudience = () => {
    var value = document.getElementById('ztoaudience-select').value;
    console.log('SELECTED VALUE :', value);
    setUserFromTable({ ...userFromTable, audience: value });
  };

  const setUserStatus = () => {
    var value = document.getElementById('ztouserstatus').value;
    console.log('SELECTED VALUE :', value);
    setUserFromTable({ ...userFromTable, status: value });
  };

  useEffect(() => {
    //  console.log("Selected = ",props.userSelected)
    if (!loaded) callApi();
    //  setUserFromTable(props.userSelected)
  });

  const changeUserRole = () => {
    if (userFromTable.role == 'user')
      setUserFromTable({ ...userFromTable, role: 'admin' });
    else setUserFromTable({ ...userFromTable, role: 'user' });

    console.log('CHANGE USER STATE', userFromTable);
  };

  const updateuser = () => {
    getIdTokenClaims().then((token) => {
      props.dispatch(updateuserinfo(token, userFromTable));
      props.closePopUP();
    });
    //console.log("UPDATE USER")
  };

  const closeModal = () => {
    props.closePopUP();
  };

  const handleChange = (event) => {
    setUserFromTable({ ...userFromTable, familyName: event.target.value });
  };
  const handleGivenNameChange = (event) => {
    setUserFromTable({ ...userFromTable, givenName: event.target.value });
  };

  const userStatusMap = [{ value: 'active' }, { value: 'inactive' }];

  return !loaded ? (
    <div className='center'>
      <Loader type='TailSpin' color='black' height={100} width={100} />
    </div>
  ) : (
    <div className='ztoPopUpCont'>
      <div className='ztogroupedrow ztoModalTitle'>
        <Row className='popUpRow'>
          <Col className='popupItemPadding' xs='10'>
            Modifier un utilisateur
          </Col>
          <Col
            className='popupItemPadding closeArrow'
            xs='2'
            onClick={closeModal}
          >
            <a onClick={closeModal}></a>x
          </Col>
        </Row>
      </div>
      <div className='ztogroupedrow'>
        <Row className='popUpRow'>
          <Col className='popupItemPadding ztoItemLabel' xs='6'>
            NOM
          </Col>
          <Col className='popupItemPadding ztoItemLabel' xs='6'>
            PRENOM
          </Col>
        </Row>
        <Row className='popUpRow'>
          <Col xs='6'>
            <input
              id='userform-name'
              type='text'
              className='popInput'
              value={userFromTable.familyName}
              onChange={handleChange}
            />
          </Col>
          <Col xs='6'>
            <input
              id='userform-lastname'
              type='text'
              className='popInput'
              value={userFromTable.givenName}
              onChange={handleGivenNameChange}
            />
          </Col>
        </Row>
      </div>
      <div className='ztogroupedrow'>
        <Row className='popUpRow'>
          <Col xs='12' className='ztoItemLabel'>
            EMAIL
          </Col>
          <Col xs='12'>
            <input
              id='userform-email'
              type='text'
              value={userFromTable.email}
              className='popInput popInputFull'
            />
          </Col>
        </Row>
      </div>
      <div className='ztogroupedrow'>
        <Row className='popUpRow'>
          <Col xs='12' className='ztoItemLabel'>
            NOM UTILISATEUR
          </Col>
          <Col xs='12'>
            <input
              id='userform-username'
              type='text'
              value={userFromTable.email}
              className='popInput popInputFull'
            />
          </Col>
        </Row>
      </div>
      <div className='ztogroupedrow'>
        <Row className='popUpRow'>
          <Col xs='12' className='ztoItemLabel'>
            STATUT
          </Col>
        </Row>
        <Row className='popUpRow'>
          <Col xs='12'>
            <select
              name='ztouserstatus'
              id='ztouserstatus'
              onChange={setUserStatus}
            >
              {userStatusMap.map((status) =>
                userFromTable.status == status.value ? (
                  <option
                    key={status.value}
                    defaultValue={status.value}
                    selected
                  >
                    {status.value}
                  </option>
                ) : (
                  <option key={status.value} value={status.value}>
                    {status.value}
                  </option>
                )
              )}
              ;
            </select>
          </Col>
        </Row>
      </div>
      <div className='ztogroupedrow'>
        <Row className='popUpRow'>
          <Col xs='12' className='ztoItemLabel'>
            AUDIENCE
          </Col>
        </Row>
        <Row className='popUpRow'>
          <Col xs='12'>
            <select
              name='ztoaudience-select'
              id='ztoaudience-select'
              onChange={setUserAudience}
            >
              <option key='ztoempy' id='ztoempty' value=''>
                Aucune audience
              </option>
              {props.audiences.map((audience) =>
                userFromTable.audience == audience.id ? (
                  <option key={audience.id} defaultValue={audience.id} selected>
                    {audience.name}
                  </option>
                ) : (
                  <option key={audience.id} value={audience.id}>
                    {audience.name}
                  </option>
                )
              )}
              ;
            </select>
          </Col>
        </Row>
      </div>
      <div className='ztogroupedrow'>
        <Row className='popUpRow'>
          <Col xs='12' className='ztoItemLabel'>
            TYPE DE COMPTE
          </Col>
        </Row>
        <Row className='popUpRow'>
          <Col xs='6'>
            <button
              className={
                userFromTable.role == 'user'
                  ? 'popupButton'
                  : 'popupButtonInactive'
              }
              onClick={changeUserRole}
            >
              UTILISATEUR
            </button>
          </Col>
          <Col xs='6'>
            <button
              className={
                userFromTable.role == 'admin'
                  ? 'popupButton'
                  : 'popupButtonInactive'
              }
              onClick={changeUserRole}
            >
              ADMIN
            </button>
          </Col>
        </Row>
      </div>

      <Row className='ztoactionButton popUpRow'>
        <Col xs='12'>
          <Button variant='primary' onClick={updateuser}>
            Modifier utilisateur
          </Button>
          {'   '}
          <Button variant='danger' onClick={closeModal}>
            Fermer
          </Button>
        </Col>
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userinfo: state.adminReducer.userinfo,
    audiences: state.adminReducer.audiences,
  };
}

export default connect(mapStateToProps)(UserForm);
