import { SET_LANGUAGES } from './types';
import axios from 'axios';

export const setLanguages = (langs) => {
  return {
    type: SET_LANGUAGES,
    payload: langs,
  };
};

export function fetchLanguages() {
  return (dispatch) => {
    return axios
      .get(process.env.REACT_APP_API_URL + '/locales', {})
      .then((response) => {
        let langs = {};
        response.data['hydra:member'].map((lang) => {
          if (lang.active) {
            langs[lang.id] = lang.id;
          }
        });
        dispatch(setLanguages(langs));
      })
      .catch((err) => {});
  };
}
