import React, { useState, useEffect } from 'react';
import { Header, CartItem, SubHeader, ItemsContainer } from '../components';
import Footer from '../components/Footer';
import { connect } from 'react-redux';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import '../styles/pages/Cart.css';
import { Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Cart = (props) => {
  const [showNotification, setShowNotification] = useState(false);

  var I18n = require('react-redux-i18n').I18n;

  const notify = () => toast(I18n.t('toast.sendMediasByEmail'));

  async function downloadZip() {
    var zip = new JSZip();
    await Promise.all(
      props.cart.items.map(async (media) => {
        return new Promise((resolve) => {
          JSZipUtils.getBinaryContent(media.url, (err, data) =>
            resolve(zip.file(media.name, data, { binary: true }))
          );
        });
      })
    );
    zip
      .generateAsync({
        type: 'blob',
      })
      .then(function (content) {
        const element = document.createElement('a');
        element.setAttribute('href', window.URL.createObjectURL(content));
        element.setAttribute('download', 'medias.zip');
        element.click();
      });
  }

  function setShowNotificationFromChild() {
    setShowNotification(true);
  }

  useEffect(() => {
    if (props.cart.items.length === 0 && showNotification) {
      notify();
    }
  }, [showNotification, props.cart.items]);

  if (props.cart.items.length > 0) {
    return (
      <div>
        <Header />
        <SubHeader type='Favourites'>
          <Translate value='cart.title' />
          {/* {t('favourites')} */}
        </SubHeader>
        {/* <h1 className='cart-title'>
          <Translate value='cart.title' />
        </h1> */}
        {/* <div className='cart-container'>
          <div className='cart-itemcontainer'>
            {props.cart.items.map((media, index) => {
              return <CartItem url={media.url} id={index} />;
            })}
          </div>
          <button onClick={downloadZip} className='cart-downloadall'>
            <Translate value='cart.download' />
          </button>
        </div> */}
        <div className='cart-container'>
          <div className='cart-subcontainer'>
            <ItemsContainer
              whereFrom='Cart'
              setShowNotificationFromChild={setShowNotificationFromChild}
            />
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  } else {
    return (
      <div>
        <ToastContainer hideProgressBar />
        <Header />
        <SubHeader type='Favourites'>
          <Translate value='cart.title' />
          {/* {t('favourites')} */}
        </SubHeader>
        <div className='cart-noresult-container'>
          <div className='cart-noresult-subcontainer'>
            <p className='cart-noresult-h1'>
              <Translate value='cart.empty' />
            </p>
            <p className='cart-noresult-p'>
              <Translate value='cart.cartIsEmptyMsg' />
            </p>
            <Link to='/homepage' className='cart-noresult-btn'>
              <Translate value='cart.return' />
            </Link>
          </div>
        </div>
      </div>
    );
  }
};

function mapStateToProps(state) {
  return {
    cart: state.cart,
  };
}

export default connect(mapStateToProps)(Cart);
