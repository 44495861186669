/**
 * Created by mnassih on 23/09/2020.
 */
import {
  USER_LIST,
  USER_STATUS,
  ADMIN_USER_INFO,
  ADMIN_UPDATE_USER_INFO,
  ADMIN_GET_ALL_AUDIENCES,
  ADMIN_UPDATE_AUDIENCE,
  ADMIN_DELETE_USER,
  ADMIN_STORE_USER_AUDIENCE,
  ADMIN_GET_ALL_ATTRIBUTES,
  ADMIN_UPDATE_ATTRIBUTE,
  ADMIN_INSTANCE_DATA,
} from '../actions/types';
const initialState = {
  userlist: [],
  userstatus: '',
  gotQuableInfo: false,
  userinfo: {},
  quableUser: {},
  audiences: [],
  audience: {},
  quableuserinstance: {},
  useraudience: null,
  attributes: [],
  instancedata: {},
};
export function adminReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LIST:
      return Object.assign({}, state, {
        userlist: action.payload,
      });
    case USER_STATUS:
      return Object.assign({}, state, {
        quableUser: action.payload.quableuser,
        quableuserinstance: action.payload.quableuserinstance,
        gotQuableInfo: true,
      });
    case ADMIN_USER_INFO:
      return Object.assign({}, state, {
        userinfo: action.payload,
      });
    case ADMIN_UPDATE_USER_INFO:
      return Object.assign({}, state, {
        quableUser: action.payload,
      });
    case ADMIN_GET_ALL_AUDIENCES:
      return Object.assign({}, state, {
        audiences: action.payload,
      });
    case ADMIN_UPDATE_AUDIENCE:
      return Object.assign({}, state, {
        audience: action.payload,
      });
    case ADMIN_STORE_USER_AUDIENCE:
      return Object.assign({}, state, {
        useraudience: action.payload,
      });
    case ADMIN_GET_ALL_ATTRIBUTES:
      return Object.assign({}, state, {
        attributes: action.payload,
      });
    case ADMIN_INSTANCE_DATA:
      return Object.assign({}, state, {
        instancedata: action.payload,
      });

    default:
      return state;
  }
}
