// TO BE DELETED AFTER POPUP IS CREATED IN THE RELATED MEDIA PAGE

import React, { useEffect } from 'react';
import { VisualizeMedia, Header, SubHeader } from '../components';
import Footer from '../components/Footer';

const MediaInfo = (props) => {
  useEffect(() => {}, []);

  return (
    <div>
      <Header />
      <SubHeader />
      <VisualizeMedia imagesInfo={props.location.state.imagesInfo} />
      <Footer></Footer>
    </div>
  );
};

export default MediaInfo;
