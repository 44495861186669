/**
 * Created by mnassih on 23/09/2020.
 */

import {
  USER_LIST,
  USER_STATUS,
  ADMIN_USER_INFO,
  ADMIN_UPDATE_USER_INFO,
  ADMIN_GET_ALL_AUDIENCES,
  ADMIN_UPDATE_AUDIENCE,
  ADMIN_DELETE_USER,
  ADMIN_STORE_USER_AUDIENCE,
  ADMIN_GET_ALL_ATTRIBUTES,
  ADMIN_UPDATE_ATTRIBUTE,
  ADMIN_INSTANCE_DATA,
} from './types';

import axios from 'axios';

var instancename = window.location.hostname.split('.')[0];
if (
  instancename == 'localhost' ||
  instancename == 'recette-quable-portail' ||
  instancename == 'dev-quable-portail'
)
  instancename = 'sisley';
export const getuserlist = (token) => {
  const instance = axios.create({
    headers: {
      Authorization: `Bearer ${token.__raw}`,
    },
  });
  return (dispatch) => {
    let url =
      'https://' +
      instancename +
      process.env.REACT_APP_QUABLE_API_HOST +
      '/user_instances';
    return instance
      .get(url)
      .then((response) => {
        // console.log("response.data['hydra:member'] : ",response.data['hydra:member'])
        let result = [];
        let resultsize = 0;
        let instanceMembers = response.data['hydra:member'];
        instanceMembers.forEach((value) => {
          // console.log("value :",value)
          instance
            .get(
              'https://' +
                instancename +
                process.env.REACT_APP_QUABLE_API_HOST +
                value.user
            )
            .then((response) => {
              value.givenName = response.data.givenName;
              value.familyName = response.data.familyName;
              value.role = response.data.role;
              value.email = response.data.username;
              result.push(value);
              resultsize += 1;
              // console.log("REsult",resultsize, " response.data['hydra:member'].length",instanceMembers.length)
              if (resultsize == instanceMembers.length) {
                result.sort(function (a, b) {
                  if (a.familyName < b.familyName) {
                    return -1;
                  }
                  if (a.familyName > b.familyName) {
                    return 1;
                  }
                  return 0;
                });
                dispatch(getUseritems(result));
              }
            });
        });
      })
      .catch((err) => {
        // dispatch(requestCanalFailed(err));
      });
  };
};

export const getuserinfo = (token, userID) => {
  const instance = axios.create({
    headers: {
      Authorization: `Bearer ${token.__raw}`,
    },
  });
  return (dispatch) => {
    let url =
      'https://' +
      instancename +
      process.env.REACT_APP_QUABLE_API_HOST +
      userID;
    return instance
      .get(url)
      .then((response) => {
        //console.log(response.data)
        dispatch(getUserInfo(response.data));
      })
      .catch((err) => {
        //console.log(err)
        // dispatch(requestCanalFailed(err));
      });
  };
};

export const updateuserinfo = (token, user) => {
  //console.log("updateuserinfo",user)
  const instance = axios.create({
    headers: {
      Authorization: `Bearer ${token.__raw}`,
      'Content-Type': 'application/ld+json',
    },
  });
  return (dispatch) => {
    let url =
      'https://' +
      instancename +
      process.env.REACT_APP_QUABLE_API_HOST +
      user.user;
    return instance
      .put(url, {
        role: user.role,
        givenName: user.givenName ? user.givenName : '',
        familyName: user.familyName ? user.familyName : '',
      })
      .then((response) => {
        let instanceUpdater = { status: user.status, audience: null };
        if (user.audience) instanceUpdater.audience = user.audience;
        // else
        //   instanceUpdater.audience="null"

        // UPDATE USER INSTANCE
        instance
          .put(
            'https://' +
              instancename +
              process.env.REACT_APP_QUABLE_API_HOST +
              user['@id'],
            instanceUpdater
          )
          .then((response) => {
            console.log(response);
            dispatch(
              storeUserAudience(
                token,
                response.data.audience !== null ? response.data.audience : ''
              )
            );
            // dispatch(updateUser(response.data));
          })
          .catch((err) => {
            //console.log(response.error)
            // dispatch(requestCanalFailed(err));
          });
      })
      .catch((err) => {
        //console.log(err)
        // dispatch(requestCanalFailed(err));
      });
  };
};

export const deleteuser = (token, user) => {
  const instance = axios.create({
    headers: {
      Authorization: `Bearer ${token.__raw}`,
    },
  });
  return (dispatch) => {
    let url =
      'https://' +
      instancename +
      process.env.REACT_APP_QUABLE_API_HOST +
      user['@id'];
    return instance
      .delete(url)
      .then((response) => {
        //console.log(response.data)
        dispatch(deleteUser(response.data));
      })
      .catch((err) => {
        //console.log(err)
        // dispatch(requestCanalFailed(err));
      });
  };
};

export function deleteUser(userdeleted) {
  return {
    payload: userdeleted,
    type: ADMIN_DELETE_USER,
  };
}

export function updateUser(updatedUser) {
  return {
    payload: updatedUser,
    type: ADMIN_UPDATE_USER_INFO,
  };
}

export function getUserInfo(user) {
  return {
    payload: user,
    type: ADMIN_USER_INFO,
  };
}

export function getUseritems(items) {
  return {
    payload: items,
    type: USER_LIST,
  };
}

export function storeuserinfo(quableuserinstance, quableuser) {
  return (dispatch) => {
    dispatch(setUserInfo(quableuserinstance, quableuser));
  };
}

export function setUserInfo(quableuserinstance, quableuser) {
  return {
    payload: { quableuserinstance: quableuserinstance, quableuser: quableuser },
    type: USER_STATUS,
  };
}

// Gestion des audiences
export const getaudiences = (token, userID) => {
  const instance = axios.create({
    headers: {
      Authorization: `Bearer ${token.__raw}`,
    },
  });
  return (dispatch) => {
    let url =
      'https://' +
      instancename +
      process.env.REACT_APP_QUABLE_API_HOST +
      '/audiences';
    return instance
      .get(url)
      .then((response) => {
        //console.log(response.data['hydra:member'])
        dispatch(getallaudiences(response.data['hydra:member']));
      })
      .catch((err) => {
        // console.log(err)
        // dispatch(requestCanalFailed(err));
      });
  };
};

export const storeUserAudience = (token, audienceId) => {
  const instance = axios.create({
    headers: {
      Authorization: `Bearer ${token.__raw}`,
    },
  });
  return (dispatch) => {
    let url =
      'https://' +
      instancename +
      process.env.REACT_APP_QUABLE_API_HOST +
      audienceId;
    return instance
      .get(url)
      .then((response) => {
        console.log(response.data);
        dispatch(storeuserAudience(response.data));
      })
      .catch((err) => {
        //console.log(err)
        // dispatch(requestCanalFailed(err));
      });
  };
};

export function storeuserAudience(userAudience) {
  return {
    payload: userAudience,
    type: ADMIN_STORE_USER_AUDIENCE,
  };
}

export function getallaudiences(items) {
  return {
    payload: items,
    type: ADMIN_GET_ALL_AUDIENCES,
  };
}

export const updateaudience = (token, audience) => {
  const instance = axios.create({
    headers: {
      Authorization: `Bearer ${token.__raw}`,
    },
  });
  return (dispatch) => {
    let url =
      'https://' +
      instancename +
      process.env.REACT_APP_QUABLE_API_HOST +
      audience['@id'];
    return instance
      .put(url, {
        name: audience.name,
        filters: audience.filters,
      })
      .then((response) => {
        // console.log(response.data)
        dispatch(updateAudience(response.data));
      })
      .catch((err) => {
        //  console.log(err)
        // dispatch(requestCanalFailed(err));
      });
  };
};

export const createaudience = (token, audience) => {
  const instance = axios.create({
    headers: {
      Authorization: `Bearer ${token.__raw}`,
    },
  });
  let newAudience = {
    name: audience.name,
    filters: {
      attributes: {
        zone_de_diffusion_codes:
          audience.filters.attributes.zone_de_diffusion_codes,
      },
    },
    id: audience.name.replace(/\s/g, '').toLowerCase(),
  };
  return (dispatch) => {
    let url =
      'https://' +
      instancename +
      process.env.REACT_APP_QUABLE_API_HOST +
      '/audiences';
    return instance
      .post(url, newAudience)
      .then((response) => {
        //  console.log(response.data)
        dispatch(updateAudience(response.data));
      })
      .catch((err) => {
        //   console.log(err)
        // dispatch(requestCanalFailed(err));
      });
  };
};

export const deleteaudience = (token, audience) => {
  const instance = axios.create({
    headers: {
      Authorization: `Bearer ${token.__raw}`,
    },
  });
  return (dispatch) => {
    let url =
      'https://' +
      instancename +
      process.env.REACT_APP_QUABLE_API_HOST +
      audience['@id'];
    return instance
      .delete(url)
      .then((response) => {
        // console.log(response.data)
        dispatch(updateAudience(response.data));
      })
      .catch((err) => {
        // console.log(err)
        // dispatch(requestCanalFailed(err));
      });
  };
};
export function updateAudience(audience) {
  return {
    payload: audience,
    type: ADMIN_UPDATE_AUDIENCE,
  };
}

// end gestion audience

// Gestion des attributs

export const getattributes = (token) => {
  console.log('attributes management ');
  const instance = axios.create({
    headers: {
      Authorization: `Bearer ${token.__raw}`,
    },
  });
  return (dispatch) => {
    let url =
      'https://' +
      instancename +
      process.env.REACT_APP_QUABLE_API_HOST +
      '/attributes';
    return instance
      .get(url)
      .then((response) => {
        dispatch(getallattributes(response.data['hydra:member']));
      })
      .catch((err) => {
        console.log(err);
        // dispatch(requestCanalFailed(err));
      });
  };
};

export function getallattributes(items) {
  return {
    payload: items,
    type: ADMIN_GET_ALL_ATTRIBUTES,
  };
}

export const updateattribute = (token, attribute) => {
  const instance = axios.create({
    headers: {
      Authorization: `Bearer ${token.__raw}`,
    },
  });
  return (dispatch) => {
    let url =
      'https://' +
      instancename +
      process.env.REACT_APP_QUABLE_API_HOST +
      attribute['@id'];
    return instance
      .put(url, {
        isPrimary: attribute.isPrimary,
        isFilterable: attribute.isFilterable,
      })
      .then((response) => {
        // console.log(response.data)
        dispatch(updateAttribute(response.data));
      })
      .catch((err) => {
        //  console.log(err)
        // dispatch(requestCanalFailed(err));
      });
  };
};

export function updateAttribute(attribute) {
  return {
    payload: attribute,
    type: ADMIN_UPDATE_ATTRIBUTE,
  };
}
// end gestion des attributs

export function storeInstanceData(instanceData) {
  return {
    payload: instanceData,
    type: ADMIN_INSTANCE_DATA,
  };
}
