import React, { useEffect, useState } from 'react';
import {
  Header,
  ListPathButtons,
  SubHeader,
  MaterialUITreeView,
  TreeViewSelectedPath,
  ItemsContainer,
} from '../components';
import { connect } from 'react-redux';
import {
  fetchCanal,
  updateCanalPath,
  updateVisiblePathItems,
  updateCurrentPathItems,
  initializeItemsContainerAction,
  resetCurrentPathProducts,
  resetProducts,
  setCanalTreeViewExpanded,
  setCurrentCanalClassificationId,
} from '../redux/actions/canalActions';
import '../styles/pages/Produits.css';
import Footer from '../components/Footer';
import history from '../history';

const Produits = (props) => {
  const [currentButtonsLength, setCurrentButtonsLength] = useState();
  const [nbPage, setNbPage] = useState(1);
  const [nbValues, setNbValues] = useState('20');
  const [historyLocationSearch, setHistoryLocationSearch] = useState(
    props.location.search
  );
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    setFirstRender(false);
    setNbPage(1);
    function start() {
      let path = ['Produits'];
      if (historyLocationSearch.length > 0) {
        path = path.concat(
          historyLocationSearch
            .replace('?', '')
            .split('+')
            .map((item) => decodeURIComponent(item))
        );
      } else {
        path = ['Produits'];
      }

      let cpi = [props.currentPathItems[0]];
      path.map((pathitem, parentIndex) => {
        if (parentIndex < 2) {
          cpi[cpi.length - 1][1].map((item, index) => {
            return item[0] === pathitem
              ? cpi.push([
                  cpi[cpi.length - 1][1][index][0],
                  [cpi[cpi.length - 1][1][index][2]],
                  cpi[cpi.length - 1][1][index][3],
                  cpi[cpi.length - 1][1][index][6],
                ])
              : {};
          });
        } else {
          cpi[cpi.length - 1][1][0].map((item, index) => {
            return item[0] === pathitem
              ? cpi.push([
                  cpi[cpi.length - 1][1][0][index][0],
                  [cpi[cpi.length - 1][1][0][index][2]],
                  cpi[cpi.length - 1][1][0][index][3],
                  cpi[cpi.length - 1][1][0][index][6],
                ])
              : {};
          });
        }
      });

      // console.log(path);
      props.dispatch(updateCanalPath(path));
      // console.log(cpi);
      props.dispatch(updateCurrentPathItems(cpi));
      // console.log(cpi[cpi.length - 1][1][0]);
      props.dispatch(updateVisiblePathItems(cpi[cpi.length - 1][1][0]));
      // console.log(cpi[cpi.length - 1][2]);
      props.dispatch(
        initializeItemsContainerAction(
          cpi[cpi.length - 1][2],
          nbPage,
          nbValues,
          props.locale,
          props.audience
        )
      );
      // console.log(cpi[cpi.length - 1][2]);
      props.dispatch(setCurrentCanalClassificationId(cpi[cpi.length - 1][2]));
      // console.log(cpi[cpi.length - 1][1][0].length);
      setCurrentButtonsLength(cpi[cpi.length - 1][1][0].length);
      // console.log(cpi);
      let exp = cpi.slice(1).map((item) => item[3]);
      // console.log(exp);
      props.dispatch(setCanalTreeViewExpanded(exp));
    }

    if (historyLocationSearch.length > 0) {
      start();
    } else {
      props.dispatch(setCurrentCanalClassificationId(null));
      setCurrentButtonsLength(props.currentPathItems[0][1].length);
      props.dispatch(updateCanalPath(['Produits']));
      props.dispatch(updateCurrentPathItems([props.currentPathItems[0]]));
      props.dispatch(updateVisiblePathItems(props.currentPathItems[0][1]));
      props.dispatch(setCanalTreeViewExpanded([]));
    }
  }, [historyLocationSearch]);

  function updateHistory(path) {
    history.push({
      pathname: '/produits',
      search: '?' + path,
    });
    setHistoryLocationSearch(path);
  }

  useEffect(() => {
    if (!firstRender) {
      props.dispatch(resetCurrentPathProducts());
      if (
        nbPage !== undefined &&
        nbValues !== undefined &&
        props.currentCanalClassificationId !== null
      ) {
        //setCurrentButtonsLength(props.currentElts.length);
        props.dispatch(
          initializeItemsContainerAction(
            props.currentCanalClassificationId,
            nbPage,
            nbValues,
            props.locale,
            props.audience
          )
        );
      }
    }
  }, [nbPage, nbValues]);

  function setExpandedFromChild(expanded) {
    props.dispatch(setCanalTreeViewExpanded(expanded));
  }

  function updateCurrentButtonsItemsFromPath(index, newCurrentButtonsItems) {
    let newCurrentPathItems = props.currentPathItems.slice(0, index + 1);
    setCurrentButtonsLength(newCurrentButtonsItems.length);
    props.dispatch(updateVisiblePathItems(newCurrentButtonsItems));
    props.dispatch(updateCurrentPathItems(newCurrentPathItems));

    if (newCurrentPathItems.length === 1) {
      props.dispatch(setCurrentCanalClassificationId(null));
    } else {
      props.dispatch(
        setCurrentCanalClassificationId(
          newCurrentPathItems[newCurrentPathItems.length - 1][2]
        )
      );
    }
  }

  function updatePath(from, newPath) {
    if (from == 'frompath') {
      props.dispatch(resetCurrentPathProducts());
      if (newPath.length === 1) {
        props.dispatch(updateCanalPath([]));
      } else {
        props.dispatch(updateCanalPath(newPath));
      }
    } else if (from == 'frombutton') {
      if (props.path.length === 0) {
        props.dispatch(updateCanalPath([...['Produits'], newPath]));
      } else {
        props.dispatch(updateCanalPath([...props.path, newPath]));
      }
    }
  }

  function setApiCallNumberOfPageAndNumberOfValues(nbPage, nbValues) {
    setNbPage(nbPage);
    setNbValues(nbValues);
  }

  return (
    <div className='produits-outercontainer'>
      <Header />
      <SubHeader type='Main' produitsSelected={true} />
      <div className='produits-container'>
        <div className='produits-subcontainer'>
          <div className='produits-innerview'>
            <TreeViewSelectedPath
              path={props.path}
              updateHistory={updateHistory}
            />
          </div>
          <div className='produits-innerview'>
            <MaterialUITreeView
              whereFrom='Produits'
              items={props.treeItems}
              currentElts={props.currentElts}
              currentPathItems={props.currentPathItems}
              path={props.path}
              expanded={props.expanded}
              updateHistory={updateHistory}
            />
            <ListPathButtons
              whereFrom='Produits'
              style={
                currentButtonsLength === 0 || currentButtonsLength === undefined
                  ? { display: 'none' }
                  : {
                      display: 'flex',
                    }
              }
              currentElts={props.currentElts}
              updateHistory={updateHistory}
              path={props.path}
            />
            <ItemsContainer
              star={true}
              whereFrom='Produits'
              currentElements={props.currentProducts}
              currentButtonsLength={currentButtonsLength}
              setApiCallNumberOfPageAndNumberOfValues={
                setApiCallNumberOfPageAndNumberOfValues
              }
              path={props.path}
              setExpandedFromChild={setExpandedFromChild}
              expanded={props.expanded}
              updatePath={updatePath}
              updateCurrentButtonsItemsFromPath={
                updateCurrentButtonsItemsFromPath
              }
              currentPathItems={props.currentPathItems}
              historyLocationSearch={historyLocationSearch}
              updateHistory={updateHistory}
            />
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    treeItems: state.canal.items,
    path: state.canal.selectedPath,
    currentPathItems: state.canal.currentPathItems,
    currentElts: state.canal.visiblePathItems,
    currentProducts: state.canal.currentProducts,
    currentCanalClassificationId: state.canal.currentCanalClassificationId,
    expanded: state.canal.expanded,
    audience: state.adminReducer.useraudience,
  };
}

export default connect(mapStateToProps)(Produits);
