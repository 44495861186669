import React from 'react';
import { connect } from 'react-redux';
import '../../styles/components/ProductPage/ProductInfoTable.css';

const ProductInfoTable = (props) => {
  let index = -1;
  return (
    <div className='pit-container'>
      <table className='infotable'>
        <tbody>
          {props.fields.map((field) => {
            let isPrimary = false;
            props.attributes.map((attribute) => {
              if (attribute.id === field[2]) {
                if (attribute.isPrimary) {
                  isPrimary = true;
                }
              }
            });
            if (isPrimary) {
              index++;
              return (
                <tr key={'field' + index} className={'inforow' + (index % 2)}>
                  <td className='inforowelement1'>{field[0]}</td>
                  <td className='inforowelement2'>{field[1]}</td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    fields: state.product.attributesData,
    attributes: state.adminReducer.attributes,
  };
}

export default connect(mapStateToProps)(ProductInfoTable);
