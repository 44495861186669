import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';
import {
  storeuserinfo,
  storeUserAudience,
  storeInstanceData
} from '../../redux/actions/adminAction';
import { registerUserInstance } from '../../redux/actions/userActions';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import '../../styles/components/adminPage/adminstyles.css';

const Authenticate = (props) => {
  const {
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
    getIdTokenClaims,
    loginWithRedirect,
    isAuthenticated,
  } = useAuth0();

  let redirectPage = localStorage.getItem('ztoAppState')
    ? localStorage.getItem('ztoAppState')
    : '/homepage';

    const [isAccesDenied, setIsAccesDenied] = useState(false);
    const [isUnauthorised, setIsUnauthorised] = useState(false);

  const callApi = async (pagestate) => {
    console.log('pagestate :', redirectPage);

    // On récupère le nom de domaine pour se connecter à la bonne instance

    let instancename = window.location.hostname.split('.')[0];
    console.log('instancename :', instancename);
    // si on est sur localhost on force la connexion sur sisley pour éviter d'avoir des erreurs de connexion en dev
    if (
      instancename == 'localhost' ||
      instancename == 'recette-quable-portail' ||
      instancename == 'dev-quable-portail'
    )
      instancename = 'sisley';

      getIdTokenClaims().then(token => {
        let url =
      'https://' +
      instancename +
      process.env.REACT_APP_QUABLE_API_HOST +
      '/users/' +
      token.sub;
   // console.log(token.__raw);
    // console.log(token.sub);

    const instance = axios.create({
      headers: {
        Authorization: `Bearer ${token.__raw}`,
      },
    });
    instance.get(url)
      .then((response) => {
        props.dispatch(registerUserInstance(instancename));

        // L'utilisateur existe on regarde si il a le droit d'utiliser l'instance
        if (response.status == 200) {
          let loggedUser = response.data;
          //////
          let instance_url =
            'https://' +
            instancename +
            process.env.REACT_APP_QUABLE_API_HOST +
            '/user_instances/user=' +
            token.sub +
            ';instance=' +
            instancename;
          instance
            .get(instance_url)
            .then((response) => {
              console.log('usr_instance',response.data)
              console.log('usr',loggedUser)
              props.dispatch(storeuserinfo(response.data, loggedUser));
              if (response.data.audience) {
                props.dispatch(
                  storeUserAudience(token, response.data.audience)
                );
              }
              let instance_data_url  ='https://' +
              instancename +
              process.env.REACT_APP_QUABLE_API_HOST +
              '/instances/' + instancename;
              instance.get(instance_data_url).then((resp) => {
                props.dispatch(storeInstanceData(resp.data))
              }).catch((err) => {
                console.log('error  instance', err)
              })
            })
            .catch((err) => {
              // dispatch(requestCanalFailed(err));
              console.log('error user  instance', err)
            });
          //////
        }

        ///dispatch(getUseritems(response.data['hydra:member']))
      })
      .catch((err) => {
        // dispatch(requestCanalFailed(err));
        // L'utilisateur n'existe pas on le crée
        if (err.response.status == 404) {
          instance
            .post(
              'https://' +
                instancename +
                process.env.REACT_APP_QUABLE_API_HOST +
                '/users',
              {},
              {}
            )
            .then((response) => {
              props.dispatch(storeuserinfo({ status: 'standby' }));
            })
            .catch((err) => {
              // dispatch(requestCanalFailed(err));
              console.log('error  user', err)
              if(err.response.status == 403) {
                console.log('access denied')
                setIsAccesDenied(true)
              }
            });
        }
      });
      }).catch(err => {
        console.log('autho',err)
        setIsUnauthorised(true)
      })
  };

  useEffect(() => {
    if (isAuthenticated) {
      callApi('redirect to page ');
    }
  });
  let returnState;

  if (!props.gotQuableInfo)
    returnState = (
      <div className='center'>
        <Loader type='TailSpin' color='black' height={100} width={100} />
      </div>
    );

  if ( isAccesDenied){
    returnState = <Redirect to='/accessdenied' />
  }
  if (isUnauthorised ){
    returnState = <Redirect to='/error' />
  }

  if (props.userstatus == 'active'){
    const rawRedirection = redirectPage.split('?')
    if(rawRedirection[0] == '/'){
      redirectPage = '/homepage'
    }
    returnState = <Redirect to={redirectPage} />;
  }
  if (props.userstatus == 'inactive')
    returnState = <Redirect to='/accessdenied' />;
  if (props.userstatus == 'standby') returnState = <Redirect to='/standby' />;

  return returnState;
};

function mapStateToProps(state) {
  return {
    userstatus: state.adminReducer.quableuserinstance.status,
    gotQuableInfo: state.adminReducer.gotQuableInfo,
  };
}

export default connect(mapStateToProps)(Authenticate);
