import React, {useState, useEffect} from 'react';
import '../../styles/components/LanguageDropdown.css';
import '../../styles/components/userinfo.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
// import i18n from '../i18n (UNUSED)';
// import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {Translate} from 'react-redux-i18n';


const UserProfileDropdown = (props) => {
    return (
        <div className={props.className + ' dropdown'}>
            <button style={{color: props.color}} className='dropbtn'>
                <p>
                    <img
                        src={props.profileImg}
                        alt="Profile"
                        className=" profileImg rounded-circle img-fluid profile-picture mb-10 mb-md-0"

                    />
                </p>
                <FontAwesomeIcon className='chevrondown' icon={faChevronDown}/>
            </button>
            <div className='dropdown-content userinfodropdowncontent'>
                <p>
                    <Translate value='header.language'/>
                </p>
                <div key="manageUser" className='userinfodropdowncontent '>
                    <Link to="usermanagement">
                        Gestion des users
                    </Link>
                </div>
                <div key="manageUserAudience" className='userinfodropdowncontent'>

                    <Link to="usermanagement">
                        Gestion des audiences
                    </Link>
                </div>
            </div>
        </div>
    );
};

UserProfileDropdown.propTypes = {
    color: PropTypes.string,
};


export default connect()(UserProfileDropdown);
