import React from 'react';
import '../../styles/components/ProductPage/DocumentLinksTable.css';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const DocumentLinksTable = (props) => {
  return (
    <div
      className='dlt-root'
      style={
        props.product.data.documentLinks.length === 0 ? { display: 'none' } : {}
      }
    >
      <div className='dlt-title-container'>
        <h5 className='dlt-skus'>
          {props.type === 'link_checklist_articles'
            ? 'Checklist articles associés'
            : 'Ventes croisées'}
        </h5>
      </div>
      <div className='dlt-subroot'>
        <table style={{ width: '100%' }}>
          <thead>
            <tr className='dlt-header'>
              <th className='dlt-hitem'>id</th>
            </tr>
          </thead>
          <tbody>
            {props.product.data.documentLinks.map((link, index) => {
              if (link.linkType.id === props.type) {
                return (
                  <tr
                    key={'link' + index}
                    className={'dlt-row' + ((index % 2) + 1)}
                  >
                    <Link
                      to={{
                        pathname: '/product',
                        search: '?' + link.target.id,
                      }}
                      className='dlt-row-link'
                    >
                      {link.target.id}
                    </Link>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    product: state.product.productData.product,
    loading: state.product.isFetchingProduct,
  };
}

export default connect(mapStateToProps)(DocumentLinksTable);
