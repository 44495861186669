/**
 * Created by mnassih on 23/09/2020.
 */
// TO BE DELETED AFTER POPUP IS CREATED IN THE RELATED MEDIA PAGE

import React, { useEffect, useState } from 'react';
import { VisualizeMedia, Header, SubHeader } from '../components';
import Footer from '../components/Footer';
import DataTable from 'react-data-table-component';
import { getuserlist } from '../redux/actions/adminAction';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

const UserManagement = (props) => {
  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    callApi();
  }, []);

  const callApi = async () => {
    const token = await getIdTokenClaims();
    props.dispatch(getuserlist(token));
  };

  const data = [
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
    {
      id: 1,
      name: 'Nassih',
      lastName: 'Al Mostafa',
      audience: 'IT SEPHORA',
      profil: 'user',
      email: 'mnassih@gmail.com',
    },
  ];
  const columns = [
    {
      name: 'Nom',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Prénom',
      selector: 'lastName',
      sortable: true,
      right: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      right: true,
    },
    {
      name: 'Audience',
      selector: 'audience',
      sortable: true,
      right: true,
    },
    {
      name: 'Profil',
      selector: 'profil',
      sortable: true,
      right: true,
    },
  ];

  return (
    <div>
      <Header />
      <DataTable
        title='Gestion des utilisateurs'
        columns={columns}
        data={data}
        pagination
        fixedHeader
        fixedHeaderScrollHeight='300px'
      />
      <Footer></Footer>
    </div>
  );
};

function mapStateToProps(state) {
  console.log(state);
  return {
    userlist: state.userlist,
  };
}

export default connect(mapStateToProps)(UserManagement);
