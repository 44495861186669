import {
  REQUEST_MEDIAS_CLASSIFICATION,
  REQUEST_MEDIAS_CLASSIFICATION_FAILED,
  RECEIVE_MEDIAS_CLASSIFICATION,
  REQUEST_MEDIAS,
  RECEIVE_MEDIAS,
  REQUEST_MEDIAS_FAILED,
  UPDATE_MEDIAS_PATH,
  UPDATE_MEDIAS_CURRENT_PATH_ITEMS,
  UPDATE_MEDIAS_VISIBLE_PATH_ITEMS,
  SET_CURRENT_PATH_ASSETS_LENGTH,
  ADD_CURRENT_PATH_ASSET,
  RESET_CURRENT_PATH_ASSETS,
  SET_CURRENT_ASSETS_CLASSIFICATION_ID,
  SET_MEDIAS_TREEVIEW_EXPANDED,
  RESET_MEDIAS,
  ADD_MEDIA_ATTRIBUTE_TO_MAPPING,
  RECEIVE_MEDIAS_CLASSIFICATION_IDS,
  SET_MEDIAS_IDS_TO_IMAGES,
} from '../actions/types';

const initialState = {
  status: 'ok',
  error: {},
  isFetchingMediasClassification: false,
  isFetchingMedias: false,
  items: [],
  classificationIds: [],
  idsToImages: {},
  selectedPath: [],
  currentPathItems: [],
  visiblePathItems: [],
  currentAssetsClassificationId: null,
  currentAssets: [],
  currentAssetsLength: 0,
  expanded: [],
  mediasAttributesMapping: {},
};

export function mediasReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_MEDIAS_CLASSIFICATION:
      return Object.assign({}, state, {
        isFetchingMediasClassification: true,
      });
    case RECEIVE_MEDIAS_CLASSIFICATION:
      return Object.assign({}, state, {
        status: 'ok',
        error: {},
        isFetchingMediasClassification: false,
        items: action.payload,
        lastUpdated: action.receivedAt,
      });
    case RECEIVE_MEDIAS_CLASSIFICATION_IDS:
      return Object.assign({}, state, {
        classificationIds: action.payload,
      });
    case SET_MEDIAS_IDS_TO_IMAGES:
      return Object.assign({}, state, { idsToImages: action.payload });
    case REQUEST_MEDIAS_CLASSIFICATION_FAILED:
      return Object.assign({}, state, {
        status: 'error',
        isFetchingMediasClassification: false,
        error: action.payload,
        lastUpdated: action.receivedAt,
      });
    case REQUEST_MEDIAS:
      return Object.assign({}, state, {
        isFetchingMedias: true,
      });
    case RECEIVE_MEDIAS:
      return Object.assign({}, state, {
        isFetchingMedias: false,
      });
    case REQUEST_MEDIAS_FAILED:
      return Object.assign({}, state, {
        status: 'error',
        isFetchingMedias: false,
        error: action.payload,
        lastUpdated: action.receivedAt,
      });
    case UPDATE_MEDIAS_PATH:
      return Object.assign({}, state, {
        selectedPath: action.payload,
        lastUpdated: action.changedAt,
      });
    case UPDATE_MEDIAS_CURRENT_PATH_ITEMS:
      return Object.assign({}, state, {
        currentPathItems: action.payload,
        lastUpdated: action.changedAt,
      });
    case UPDATE_MEDIAS_VISIBLE_PATH_ITEMS:
      return Object.assign({}, state, {
        visiblePathItems: action.payload,
        lastUpdated: action.changedAt,
      });
    case SET_CURRENT_ASSETS_CLASSIFICATION_ID:
      return Object.assign({}, state, {
        currentAssetsClassificationId: action.payload,
        lastUpdated: action.addedAt,
      });
    case SET_CURRENT_PATH_ASSETS_LENGTH:
      return Object.assign({}, state, {
        currentAssetsLength: action.payload,
        lastUpdated: action.addedAt,
      });
    case ADD_CURRENT_PATH_ASSET:
      return Object.assign({}, state, {
        currentAssets: [...state.currentAssets, action.payload],
        lastUpdated: action.addedAt,
      });
    case RESET_CURRENT_PATH_ASSETS:
      return Object.assign({}, state, {
        currentAssets: [],
        currentAssetsLength: 0,
        lastUpdated: action.changedAt,
      });
    case SET_MEDIAS_TREEVIEW_EXPANDED:
      return Object.assign({}, state, {
        expanded: action.payload,
        lastUpdated: action.changedAt,
      });
    case RESET_MEDIAS:
      return Object.assign({}, state, {
        status: 'ok',
        error: {},
        isFetchingMediasClassification: false,
        isFetchingMedias: false,
        items: [],
        selectedPath: [],
        currentPathItems: [],
        visiblePathItems: [],
        currentAssetsClassificationId: '',
        currentAssets: [],
        currentAssetsLength: 0,
        expanded: [],
      });
    case ADD_MEDIA_ATTRIBUTE_TO_MAPPING:
      let newMediasAttributesMapping = { ...state.mediasAttributesMapping };
      newMediasAttributesMapping[action.payload[0]] = action.payload[1];
      return Object.assign({}, state, {
        ...state,
        mediasAttributesMapping: newMediasAttributesMapping,
      });
    default:
      return state;
  }
}
