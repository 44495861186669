import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../styles/components/ListPathButtons.css';
import Fade from 'react-reveal/Fade';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { faFile, faStar } from '@fortawesome/free-regular-svg-icons';

const ListPathButtons = (props) => {
  function updateButtons(index) {
    let p = [...props.path];
    p.push(props.currentElts[index][0]);
    props.updateHistory(p.slice(1, p.length).join('+'));
  }

  return (
    <div style={props.style} className='lpb-produits'>
      {props.currentElts.map((elt, index) => {
        return (
          <div className='lpb-produit-container'>
            <button
              onClick={() => updateButtons(index)}
              className='lpb-produit'
              style={
                props.whereFrom === 'Produits'
                  ? props.idsToImages[elt[3]] !== null
                    ? {
                        background:
                          'url(' +
                          props.idsToImages[elt[3]] +
                          ') no-repeat center',
                      }
                    : {}
                  : props.mediasIdsToImages[elt[3]] !== null
                  ? {
                      background:
                        'url(' +
                        props.mediasIdsToImages[elt[3]] +
                        ') no-repeat center',
                    }
                  : {}
              }
            >
              {/* <span>{elt[0]}</span> */}
              <div className='lpb-item-info-container'>
                <div className='lpb-item-info'>
                  <p className='lpb-item-title'>{elt[0]}</p>
                  <div className='lpb-item-dates'>
                    <FontAwesomeIcon className='lpb-item-date2' icon={faFile} />
                    <p className='lpb-item-date1-p'>{elt[4].split('T')[0]}</p>
                    <FontAwesomeIcon className='lpb-item-date1' icon={faSync} />
                    <p>{elt[5].split('T')[0]}</p>
                  </div>
                </div>
              </div>
            </button>
          </div>
        );
      })}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    idsToImages: state.canal.idsToImages,
    mediasIdsToImages: state.classification_medias.idsToImages,
  };
}

export default connect(mapStateToProps)(ListPathButtons);
