import React, { useEffect } from 'react';
import { Header, SubHeader, ItemsContainer } from '../components';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';

const MajPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <SubHeader>
        <Translate
          value={
            props.location.whereFrom === 'ProductsMAJ'
              ? 'maj.productstitle'
              : 'maj.mediastitle'
          }
        />
      </SubHeader>

      <div className='cart-container'>
        <div className='cart-subcontainer'>
          <ItemsContainer
            whereFrom={
              props.location.whereFrom === 'ProductsMAJ'
                ? 'ProductsMAJ'
                : 'MediasMAJ'
            }
            tests={
              props.location.whereFrom === 'ProductsMAJ'
                ? props.productsMAJ
                : props.mediasMAJ
            }
          />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    productsMAJ: state.homepageReducer.productsMAJ.data,
    mediasMAJ: state.homepageReducer.mediasMAJ.data,
  };
}

export default connect(mapStateToProps)(MajPage);
