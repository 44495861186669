import {
  REQUEST_CANAL,
  RECEIVE_CANAL,
  REQUEST_CANAL_FAILED,
  UPDATE_PATH,
  UPDATE_CURRENT_PATH_ITEMS,
  UPDATE_VISIBLE_PATH_ITEMS,
  ADD_CURRENT_PATH_PRODUCT,
  RESET_CURRENT_PATH_PRODUCTS,
  SET_CURRENT_PATH_PRODUCTS_LENGTH,
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCTS,
  REQUEST_PRODUCTS_FAILED,
  RESET_PRODUCTS,
  SET_CANAL_TREEVIEW_EXPANDED,
  SET_CURRENT_CANAL_CLASSIFICATION_ID,
  RECEIVE_CLASSIFICATION_IDS,
  SET_IDS_TO_IMAGES,
} from '../actions/types';

const initialState = {
  status: 'ok',
  error: {},
  isFetchingCanal: false,
  isFetchingProducts: false,
  items: [],
  classificationIds: [],
  idsToImages: {},
  selectedPath: [],
  currentPathItems: [],
  visiblePathItems: [],
  currentCanalClassificationId: null,
  currentProductsLength: 0,
  currentProducts: [],
  expanded: [],
};

export function canalReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CANAL:
      return Object.assign({}, state, {
        isFetchingCanal: true,
      });
    case RECEIVE_CANAL:
      return Object.assign({}, state, {
        status: 'ok',
        error: {},
        isFetchingCanal: false,
        items: action.payload,
        lastUpdated: action.receivedAt,
      });
    case RECEIVE_CLASSIFICATION_IDS:
      return Object.assign({}, state, {
        classificationIds: action.payload,
      });
    case SET_IDS_TO_IMAGES:
      return Object.assign({}, state, { idsToImages: action.payload });
    case REQUEST_CANAL_FAILED:
      return Object.assign({}, state, {
        status: 'error',
        isFetchingCanal: false,
        error: action.payload,
        lastUpdated: action.receivedAt,
      });
    case REQUEST_PRODUCTS:
      return Object.assign({}, state, {
        isFetchingProducts: true,
      });
    case RECEIVE_PRODUCTS:
      return Object.assign({}, state, {
        isFetchingProducts: false,
      });
    case REQUEST_PRODUCTS_FAILED:
      return Object.assign({}, state, {
        status: 'error',
        isFetchingProducts: false,
        error: action.payload,
        lastUpdated: action.receivedAt,
      });
    case UPDATE_PATH:
      return Object.assign({}, state, {
        selectedPath: action.payload,
        lastUpdated: action.changedAt,
      });
    case UPDATE_CURRENT_PATH_ITEMS:
      return Object.assign({}, state, {
        currentPathItems: action.payload,
        lastUpdated: action.changedAt,
      });
    case UPDATE_VISIBLE_PATH_ITEMS:
      return Object.assign({}, state, {
        visiblePathItems: action.payload,
        lastUpdated: action.changedAt,
      });
    case SET_CURRENT_CANAL_CLASSIFICATION_ID:
      return Object.assign({}, state, {
        currentCanalClassificationId: action.payload,
        lastUpdated: action.addedAt,
      });
    case SET_CURRENT_PATH_PRODUCTS_LENGTH:
      return Object.assign({}, state, {
        currentProductsLength: action.payload,
        lastUpdated: action.addedAt,
      });
    case ADD_CURRENT_PATH_PRODUCT:
      return Object.assign({}, state, {
        currentProducts: [...state.currentProducts, action.payload],
        lastUpdated: action.addedAt,
      });
    case RESET_CURRENT_PATH_PRODUCTS:
      return Object.assign({}, state, {
        currentProducts: [],
        currentProductsLength: 0,
        lastUpdated: action.changedAt,
      });
    case SET_CANAL_TREEVIEW_EXPANDED:
      return Object.assign({}, state, {
        expanded: action.payload,
        lastUpdated: action.changedAt,
      });
    case RESET_PRODUCTS:
      return Object.assign({}, state, {
        status: 'ok',
        error: {},
        isFetchingCanal: false,
        isFetchingProducts: false,
        items: [],
        selectedPath: [],
        currentPathItems: [],
        visiblePathItems: [],
        currentProductsLength: 0,
        currentProducts: [],
      });
    default:
      return state;
  }
}
