import React from 'react';
import '../../styles/components/Cart/CartItem.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import Downloader from 'js-file-downloader';
import { removeFromCart } from '../../redux/actions/cartActions';
import { connect } from 'react-redux';

const CartItem = (props) => {
  function downloadMedia() {
    new Downloader({
      url: props.url,
    })
      .then(function () {})
      .catch(function (error) {});
  }

  function handleRemove() {
    props.dispatch(removeFromCart(props.id));
  }

  return (
    <div
      style={
        props.id % 2 === 0
          ? { backgroundColor: 'rgb(242, 246, 248)' }
          : { backgroundColor: 'white' }
      }
      className='ci-container'
    >
      <div
        className='ci-img'
        style={{
          backgroundImage: 'url(' + props.url + ')',
        }}
      ></div>
      <div>
        <FontAwesomeIcon
          className='ci-download-btn'
          icon={faDownload}
          onClick={downloadMedia}
        />
        <FontAwesomeIcon
          className='ci-trash'
          icon={faTrash}
          onClick={handleRemove}
        />
      </div>
    </div>
  );
};

export default connect()(CartItem);
