import React from 'react';
import '../styles/components/HeadingText1.css';

const HeadingText1 = props => {
  return (
    <h1 style={props.style} className={'headingText1 ' + props.className}>
      {props.children}
    </h1>
  );
};

export default HeadingText1;
