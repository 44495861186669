import React from 'react';
import '../styles/components/Button_1.css';

const Button_1 = (props) => {
  return (
    <button
      style={props.style}
      className={'button_1 ' + props.className}
      onClick={props.onClick}>
      {props.children}
    </button>
  );
};

export default Button_1;
