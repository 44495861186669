import React from 'react';
import { ItemsContainer } from '../';
import '../../styles/components/ProductPage/DocumentLinksInfo.css';
import { connect } from 'react-redux';

const DocumentLinksInfo = (props) => {
  let linksEmpty = true;
  Object.values(props.documentLinks).map((item) => {
    if (item.products.length > 0) {
      linksEmpty = false;
    }
  });

  if (!linksEmpty) {
    return (
      <div className='dli-container'>
        <div className='dli-subcontainer'>
          <ItemsContainer whereFrom='DocumentLinks' />
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

function mapStateToProps(state) {
  return {
    documentLinks: state.product.documentLinksData.documentLinks,
  };
}

export default connect(mapStateToProps)(DocumentLinksInfo);
