/**
 * Created by mnassih on 05/11/2020.
 */
import React, { useState, useEffect } from 'react';
import '../styles/pages/Parametres.css';
import { Header, SubHeader } from '../components';
import { UserForm } from '../components/authentification';
import { Translate } from 'react-redux-i18n';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Footer from '../components/Footer';
import { ParamMenu } from '../components';
import { getattributes, updateattribute } from '../redux/actions/adminAction';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Row, Col } from 'react-bootstrap';
import { AudienceForm } from '../components/authentification';

import Loader from 'react-loader-spinner';

const Attributes = (props) => {
  var gridApi;
  const initialPageSize = 5;
  const [gridEditApi, setGridEditApi] = useState({});
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [gridLoaded, setGridLoaded] = useState(false);
  const { getIdTokenClaims } = useAuth0();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [userSelected, setUserSelected] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [createMode, setCreateMode] = useState(false);
  const [audienceSelected, setAudienceSelected] = useState({});

  const closeModal = () => {
    setGridLoaded(false);
    setOpen(false);
    setTimeout(() => {
      callApi();
    }, 1000);
  };

  useEffect(() => {
    callApi();
  }, []);

  const onGridReady = (params) => {
    gridApi = params.api;
    setGridEditApi(params.api);
    setGridColumnApi(params.columnApi);
    gridApi.sizeColumnsToFit();
    gridApi.paginationSetPageSize(pageSize);
  };

  const onPageSizeChanged = (e) => {
    setPageSize(Number(e.target.value));
    gridEditApi.paginationSetPageSize(e.target.value);
  };

  function onFilterTextBoxChanged(e) {
    gridEditApi.setQuickFilter(e.target.value);
  }

  const pageNumberMap = () => {
    let numberArray = [];
    for (let i = 10; i <= 50; i += 10) {
      numberArray.push({ value: i });
    }
    return numberArray;
  };

  const callApi = async () => {
    const token = await getIdTokenClaims();
    props.dispatch(getattributes(token)).then(() => {
      setGridLoaded(true);
    });
  };

  const updateSelectedAttribute = async (attribute) => {
    const token = await getIdTokenClaims();
    props.dispatch(updateattribute(token, attribute)).then(() => {
      setGridLoaded(true);
    });
  };

  const columnDefs = [
    {
      headerName: 'Document type code',
      field: 'documentTypeCode',
      sortable: true,
      unSortIcon: true,
      resizable: true,
    },
    {
      headerName: 'Attribute code',
      field: 'id',
      sortable: true,
      unSortIcon: true,
      resizable: true,
    },
    {
      headerName: 'Filtrable',
      field: 'isFilterable',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      cellRendererFramework: AgGridCheckbox,
    },
    {
      headerName: 'Main',
      field: 'isPrimary',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      cellRendererFramework: AgGridCheckbox,
    },
  ];

  function AgGridCheckbox(props) {
    const boolValue = props.value && props.value.toString() === 'true';
    const [isChecked, setIsChecked] = useState(boolValue);
    const onChanged = () => {
      const selectedNodes = gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);

      props.setValue(!isChecked);
      setIsChecked(!isChecked);

      updateSelectedAttribute(selectedData[0]);
    };
    return (
      <div>
        <input type='checkbox' checked={isChecked} onChange={onChanged} />
      </div>
    );
  }

  const gridContent = () => {
    return !gridLoaded ? (
      <div className='parametres-right'>
        <Loader type='TailSpin' color='black' height={100} width={100} />
      </div>
    ) : (
      <div className='parametres-right'>
        <Row className='row_bottom_margin'>
          <Col xs='8'>
            <Translate value='settings.listAttributes' />
          </Col>
          <Col xs='4'>
            <input
              type='text'
              id='filter-text-box'
              placeholder='Rechercher un attribut'
              onInput={onFilterTextBoxChanged}
              className='grid-search-box'
            />
          </Col>
        </Row>
        <div className='ag-theme-alpine' style={{ width: '100%' }}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={props.attributes}
            pagination={true}
            paginationPageSize={initialPageSize}
            domLayout='autoHeight'
            onGridReady={onGridReady}
            rowSelection='single'
          ></AgGridReact>
          <Row className='page-size-select-row'>
            <Col xs='12'>
              <Translate value='settings.show' />
              <select
                onChange={onPageSizeChanged}
                id='page-size'
                className='page-size-select'
              >
                {pageNumberMap().map((increment) =>
                  increment.value == pageSize ? (
                    <option value={increment.value} selected>
                      {increment.value}
                    </option>
                  ) : (
                    <option value={increment.value}>{increment.value}</option>
                  )
                )}
              </select>{' '}
              <Translate value='settings.perPage' />
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  // Ouverture de la PopUp en mode création

  const openInCreateMode = () => {
    let newAudience = { name: '', filters: {} };
    newAudience.filters.attributes = { zone_de_diffusion_codes: '' };
    setAudienceSelected(newAudience);
    setCreateMode(true);
    setOpen2((o) => !o);
  };

  return (
    <div>
      <Header />
      <SubHeader>
        <Translate value='settings.title' />
      </SubHeader>
      <div className='parametres-container'>
        <div className='parametres-subcontainer'>
          <ParamMenu openInCreateMode={openInCreateMode} />

          {gridContent()}
        </div>
      </div>
      <Footer></Footer>
      <Popup
        open={open}
        closeOnDocumentClick
        onClose={closeModal}
        contentStyle={{
          width: '33%',
          margin: 'auto',
          border: '1px solid black',

          height: '740px',
          borderRadius: '5px',
        }}
        arrow={true}
      >
        {(close) => <UserForm userSelected={userSelected} closePopUP={close} />}
      </Popup>
      <Popup
        open={open2}
        closeOnDocumentClick
        onClose={closeModal}
        contentStyle={{
          width: '35%',
          margin: 'auto',
          border: '1px solid black',
          padding: '5px',
          height: '390px',
          borderRadius: '5px',
        }}
        modal
        position='top center'
      >
        {(close) => (
          <AudienceForm
            audienceSelected={audienceSelected}
            createMode={createMode}
            closePopUP={close}
          />
        )}
      </Popup>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    attributes: state.adminReducer.attributes,
    editeduser: state.adminReducer.editeduser,
  };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  Attributes
);
