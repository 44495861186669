import React, { useState } from 'react';
import {
  Header,
  SubHeader,
  ProductsBlocView,
  ProductsListView,
} from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThList } from '@fortawesome/free-solid-svg-icons';
import { faThLarge } from '@fortawesome/free-solid-svg-icons';
import '../styles/pages/Favoris.css';
// import { useTranslation } from 'react-i18next';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

const Favoris = (props) => {
  const [viewbloccolor, setViewbloccolor] = useState('black');
  const [viewlistcolor, setViewlistcolor] = useState('lightgrey');
  const [itemview, setItemview] = useState(
    <ProductsBlocView
      whereFrom='Favoris'
      tests={props.wishlist}
      star={true}
      bloctxtcolor={'black'}
    />
  );

  // const { t } = useTranslation();

  function handleBlocClick() {
    setViewbloccolor('black');
    setViewlistcolor('lightgrey');
    setItemview(
      <ProductsBlocView
        whereFrom='Favoris'
        tests={props.wishlist}
        star={true}
        bloctxtcolor={'black'}
      />
    );
  }

  function handleListClick() {
    setViewbloccolor('lightgrey');
    setViewlistcolor('black');
    setItemview(
      <ProductsListView
        whereFrom='Favoris'
        tests={props.wishlist}
        star={true}
      />
    );
  }

  if (props.wishlist.length === 0) {
    return (
      <div>
        <Header />
        <SubHeader type='Favourites'>
          <Translate value='favourites.title' />
          {/* {t('favourites')} */}
        </SubHeader>
        <div className='favoris-noresult-container'>
          <div className='favoris-noresult-subcontainer'>
            <p className='favoris-noresult-h1'>
              <Translate value='favourites.empty' />
            </p>
            <p className='favoris-noresult-p'>
              <Translate value='favourites.favouritesIsEmptyMsg' />
            </p>
            <Link to='/homepage' className='favoris-noresult-btn'>
              <Translate value='favourites.return' />
            </Link>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Header />
        <SubHeader type='Favourites'>
          <Translate value='favourites.title' />
          {/* {t('favourites')} */}
        </SubHeader>
        <div className='favoris-container'>
          <div className='favoris-subcontainer'>
            <div className='switchviewcontainer'>
              <FontAwesomeIcon
                className='favorisbloc'
                style={{ color: viewbloccolor }}
                icon={faThLarge}
                onClick={handleBlocClick}
              />
              <FontAwesomeIcon
                className='favorislist'
                style={{ color: viewlistcolor }}
                icon={faThList}
                onClick={handleListClick}
              />
            </div>
            <div className='favoris-itemview'>{itemview}</div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
};

function mapStateToProps(state) {
  return {
    wishlist: state.user.wishlist,
  };
}

export default connect(mapStateToProps)(Favoris);
