import React, { useEffect, Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import {
  Help,
  Preview,
  NotFound,
  Homepage,
  Produits,
  Medias,
  Favoris,
  Search,
  ProductPage,
  MediaInfo,
  Cart,
  Login,
  Parametres,
  MediaPage,
  AccessDenied,
  Profils,
  StandBy,
  MajPage,
  Attributes,
  Loading,
} from './pages';
import history from './history';
import { connect } from 'react-redux';
import { fetchLanguages } from './redux/actions/languagesActions';
import { fetchCanal } from './redux/actions/canalActions';
import { fetchMedias } from './redux/actions/mediasActions';
import { fetchDocumentTypeInfo } from './redux/actions/productActions';
import { storeInstanceData } from './redux/actions/adminAction';
import { PrivateRoute, Authenticate } from './components/authentification';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import Prismic from 'prismic-javascript';
import Favicon from 'react-favicon';
import { getattributes } from './redux/actions/adminAction';
import { setLocale } from 'react-redux-i18n';

/**
 * Main application component
 */
const App = (props) => {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();

  const [cookies, setCookie, removeCookie] = useCookies(['user']);

  const [pr_home, setPr_home] = useState();
  const [repoName, setRepoName] = useState();
  const [prismicParametersSet, setPrismicParametersSet] = useState(false);

  let instancename = window.location.hostname.split('.')[0];
  console.log('instancename :', instancename);
  // si on est sur localhost on force la connexion sur sisley pour éviter d'avoir des erreurs de connexion en dev
  if (
    instancename == 'localhost' ||
    instancename == 'recette-quable-portail' ||
    instancename == 'dev-quable-portail'
  )
    instancename = 'sisley';

  const updateInstanceData = async () => {
    const token = await getIdTokenClaims();
    props.dispatch(getattributes(token));
    const instance = axios.create({
      headers: {
        Authorization: `Bearer ${token.__raw}`,
      },
    });
    let instance_data_url =
      'https://' +
      instancename +
      process.env.REACT_APP_QUABLE_API_HOST +
      '/instances/' +
      instancename;
    // props.quableuserinstance.instance;
    instance
      .get(instance_data_url)
      .then((resp) => {
        props.dispatch(storeInstanceData(resp.data));
        props.dispatch(
          fetchCanal(
            props.locale,
            props.instancedata.pimParameters.classificationCatalog
          )
        );
        props.dispatch(fetchMedias(props.locale));
        props.dispatch(fetchDocumentTypeInfo(props.locale));
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (props.locale === undefined) {
      props.dispatch(setLocale('fr_FR'));
    }
    props.dispatch(fetchLanguages());
  }, []);

  useEffect(() => {
    removeCookie();

    if (props.locale !== undefined && isAuthenticated) {
      if (
        props.instancedata !== undefined &&
        props.instancedata.pimParameters !== undefined &&
        props.instancedata.prismicParameters !== undefined
      ) {
        const client = Prismic.client(
          props.instancedata.prismicParameters.apiUrl +
            '?access_token=' +
            props.instancedata.prismicParameters.accessToken
        );

        const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(
          props.instancedata.prismicParameters.apiUrl +
            '?access_token=' +
            props.instancedata.prismicParameters.accessToken
        );
        setRepoName(repoNameArray[1]);
        client
          .query(Prismic.Predicates.at('document.type', 'homepage'), {
            lang: props.locale.replace('_', '-').toLowerCase(),
          })
          .then((res) => {
            if (res.results.length > 0) {
              setPr_home(res.results[0]);
            } else {
              client
                .query(Prismic.Predicates.at('document.type', 'homepage'), {
                  lang: 'en-gb',
                })
                .then((res) => {
                  setPr_home(res.results[0]);
                });
            }
          });

        client
          .query(Prismic.Predicates.at('document.type', 'parametres'), {
            lang: props.locale.replace('_', '-').toLowerCase(),
          })
          .then((res) => {
            if (res.results.length > 0) {
              window.$login_logo = res.results[0].data.login_logo.url;
              window.$logo = res.results[0].data.logo.url;
              window.$favicon = res.results[0].data.favicon.url;
              console.log(res.results[0].data.favicon.url);
              window.$bandeau = res.results[0].data.bandeau.url;
              window.$image_connexion = res.results[0].data.image_connexion.url;
              window.$visuel_defaut = res.results[0].data.visuel_par_defaut.url;
              window.$contenu = res.results[0].data.contenu;
              document.title = res.results[0].data.document_title[0].text;
              document.documentElement.style.setProperty(
                '--global-color-1',
                res.results[0].data.global_color_1
              );
              document.documentElement.style.setProperty(
                '--global-color-2',
                res.results[0].data.global_color_2
              );
              document.documentElement.style.setProperty(
                '--global-color-3',
                res.results[0].data.global_color_3
              );
              document.documentElement.style.setProperty(
                '--entete',
                res.results[0].data.entete
              );
              setPrismicParametersSet(true);
            } else {
              client
                .query(Prismic.Predicates.at('document.type', 'parametres'), {
                  lang: 'en-gb',
                })
                .then((res) => {
                  window.$login_logo = res.results[0].data.login_logo.url;
                  window.$logo = res.results[0].data.logo.url;
                  window.$favicon = res.results[0].data.favicon.url;
                  console.log(res.results[0].data.favicon.url);
                  window.$bandeau = res.results[0].data.bandeau.url;
                  window.$image_connexion =
                    res.results[0].data.image_connexion.url;
                  window.$visuel_defaut =
                    res.results[0].data.visuel_par_defaut.url;
                  window.$contenu = res.results[0].data.contenu;
                  document.title = res.results[0].data.document_title[0].text;
                  document.documentElement.style.setProperty(
                    '--global-color-1',
                    res.results[0].data.global_color_1
                  );
                  document.documentElement.style.setProperty(
                    '--global-color-2',
                    res.results[0].data.global_color_2
                  );
                  document.documentElement.style.setProperty(
                    '--global-color-3',
                    res.results[0].data.global_color_3
                  );
                  document.documentElement.style.setProperty(
                    '--entete',
                    res.results[0].data.entete
                  );
                  setPrismicParametersSet(true);
                });
            }
          });

        props.dispatch(
          fetchCanal(
            props.locale,
            props.instancedata.pimParameters.classificationCatalog
          )
        );
        props.dispatch(fetchMedias(props.locale));
        props.dispatch(fetchDocumentTypeInfo(props.locale));
      } else {
        updateInstanceData();
      }
    }
    return () => {
      removeCookie();
    };
  }, [isAuthenticated, props.locale, props.instancedata.pimParameters]);

  if (
    isAuthenticated &&
    repoName !== undefined &&
    pr_home !== undefined &&
    prismicParametersSet
  ) {
    return (
      <div>
        <Favicon url={window.$favicon} />
        <Fragment>
          <Helmet>
            <script
              async
              defer
              src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`}
            />
          </Helmet>
          <BrowserRouter history={history}>
            <Switch>
              <PrivateRoute exact path='/' component={Authenticate} />
              <PrivateRoute exact path='/produits' component={Produits} />
              <PrivateRoute exact path='/medias' component={Medias} />
              <PrivateRoute exact path='/parametres' component={Parametres} />
              <PrivateRoute exact path='/profils' component={Profils} />
              <PrivateRoute exact path='/favoris' component={Favoris} />
              <PrivateRoute exact path='/cart' component={Cart} />
              <PrivateRoute exact path='/search' component={Search} />
              <PrivateRoute exact path='/product' component={ProductPage} />
              <PrivateRoute exact path='/mediapage' component={MediaPage} />
              <PrivateRoute exact path='/media-info' component={MediaInfo} />
              <PrivateRoute exact path='/majpage' component={MajPage} />
              <PrivateRoute exact path='/help' component={Help} />
              <PrivateRoute exact path='/preview' component={Preview} />
              <PrivateRoute exact path='/attributes' component={Attributes} />
              <PrivateRoute
                exact
                path='/accessdenied'
                component={AccessDenied}
              />
              <PrivateRoute exact path='/standby' component={StandBy} />

              <PrivateRoute
                exact
                path='/homepage'
                component={() => <Homepage {...props} pr_home={pr_home} />}
              />
              <Redirect from='/' to='/homepage' />
              <Route component={NotFound} />
            </Switch>
          </BrowserRouter>
        </Fragment>
      </div>
    );
  } else {
    return (
      <Fragment>
        <Helmet>
          <script
            async
            defer
            src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`}
          />
        </Helmet>
        <BrowserRouter history={history}>
          <Switch>
            <PrivateRoute exact path='/' component={Authenticate} />
            <PrivateRoute exact path='/produits' component={Loading} />
            <PrivateRoute exact path='/medias' component={Loading} />
            <PrivateRoute exact path='/parametres' component={Loading} />
            <PrivateRoute exact path='/profils' component={Loading} />
            <PrivateRoute exact path='/favoris' component={Loading} />
            <PrivateRoute exact path='/cart' component={Loading} />
            <PrivateRoute exact path='/search' component={Loading} />
            <PrivateRoute exact path='/product' component={Loading} />
            <PrivateRoute exact path='/mediapage' component={Loading} />
            <PrivateRoute exact path='/media-info' component={Loading} />
            <PrivateRoute exact path='/majpage' component={Loading} />
            <PrivateRoute exact path='/help' component={Loading} />
            <PrivateRoute exact path='/preview' component={Loading} />
            <PrivateRoute exact path='/attributes' component={Loading} />
            <PrivateRoute exact path='/accessdenied' component={Loading} />
            <PrivateRoute exact path='/standby' component={Loading} />

            <PrivateRoute exact path='/homepage' component={Loading} />
            <Redirect from='/' to='/homepage' />
            <Route exact path='/error' component={NotFound} />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </Fragment>
    );
  }
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    instancedata: state.adminReducer.instancedata,
    quableuserinstance: state.adminReducer.quableuserinstance,
  };
}

export default connect(mapStateToProps)(App);
