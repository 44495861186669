import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import '../styles/pages/Login.css';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { setUserCredentials } from '../redux/actions/userActions';

const Login = (props) => {
  function setSubmitButtonState() {
    if (
      document.getElementById('utilisateur').value.length === 0 ||
      document.getElementById('motdepasse').value.length === 0
    ) {
      document.getElementById('login-button').disabled = true;
    } else {
      document.getElementById('login-button').disabled = false;
    }
  }

  function setSubmitButtonStateFromLocalStorage() {
    if (localStorage.username == null) {
      document.getElementById('login-button').disabled = true;
    } else {
      document.getElementById('login-button').disabled = false;
    }
  }

  useEffect(() => {
    setSubmitButtonStateFromLocalStorage();
    if (
      localStorage.getItem('username') !== null &&
      localStorage.getItem('password') !== null
    ) {
      setToRemember(true);
      setUsername(localStorage.username);
      setPassword(localStorage.password);
    }
  }, []);

  const languageOptions = [
    { value: 'francais', label: 'Français' },
    { value: 'english', label: 'English' },
  ];

  const [toRemember, setToRemember] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      marginLeft: 10,
      cursor: 'pointer',
      fontSize: 14,
      fontWeight: 600,
    }),
    control: (provided, state) => ({
      ...provided,
      width: 260,
      height: 44,
      border: '1px solid #f0f0f0',
    }),
    indicatorSeparator: () => ({}),
    dropdownIndicator: () => ({
      marginRight: 5,
      background:
        'rgba(0,0,0,0.08) url(' + faAngleDown + ') no-repeat center center',
      cursor: 'pointer',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
      borderRadius: 0,
    }),
    option: () => ({
      textAlign: 'left',
      marginLeft: 20,
      cursor: 'pointer',
      color: '#b6b6b6',
      '&:hover': {
        color: '#232323',
      },
      fontSize: 14,
      fontWeight: 600,
    }),
  };

  function handleRememberMeChange() {
    setToRemember(!toRemember);
  }

  function handleUsernameInputChange(event) {
    setSubmitButtonState();
    setUsername(event.target.value);
  }

  function handlePasswordInputChange(event) {
    setSubmitButtonState();
    setPassword(event.target.value);
  }

  function handleSubmit() {
    props.dispatch(
      setUserCredentials({
        username: username,
        password: password,
      })
    );
    if (document.getElementById('remember').checked) {
      localStorage.username = username;
      localStorage.password = password;
    }
    props.history.push('/homepage');
  }

  return (
    <div
      className='login-container'
      style={{
        background:
          'center / cover no-repeat url(' + window.$image_connexion + ')',
      }}
    >
      <div className='login-subcontainer'>
        <img className='login-logo' src={window.$login_logo}></img>
        <input
          className='login-input'
          type='text'
          id='utilisateur'
          name='utilisateur'
          placeholder="Nom d'utilisateur"
          value={username}
          onChange={handleUsernameInputChange}
        />
        <input
          className='login-input'
          type='password'
          id='motdepasse'
          name='motdepasse'
          placeholder='Mot de passe'
          value={password}
          onChange={handlePasswordInputChange}
        />
        <Select
          classNamePrefix='select'
          defaultValue={languageOptions[0]}
          name='language'
          options={languageOptions}
          styles={customStyles}
          isSearchable={false}
        />
        <div>
          <input
            type='checkbox'
            id='remember'
            name='remember'
            checked={toRemember}
            onChange={handleRememberMeChange}
          ></input>
          <label className='login-checkbox-label' for='remember'>
            Se souvenir de moi
          </label>
        </div>
        <button
          className='login-button'
          id='login-button'
          onClick={handleSubmit}
        >
          Connexion
        </button>
      </div>
    </div>
  );
};

export default connect()(Login);
