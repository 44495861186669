import React, { useState, useEffect } from 'react';
import '../styles/components/Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faStar,
  faBars,
  faPowerOff,
  faShoppingBasket,
  faCog,
} from '@fortawesome/free-solid-svg-icons';
import LanguageDropdown from './LanguageDropdown';
import { Link, useHistory } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import { ParameterLink } from './authentification';
import { Translate } from 'react-redux-i18n';
import {
  fetchSearchResults,
  requestSearchResults,
} from '../redux/actions/searchActions';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
const Header = (props) => {
  const logo = window.$logo;
  const history = useHistory();
  const { logout } = useAuth0();

  var I18n = require('react-redux-i18n').I18n;

  const [dropdownMenuActive, setDropdownMenuActive] = useState(false);
  const [search, setSearch] = useState();

  useEffect(() => {
    setSearch(props.search_input);
    document.getElementById('header-searchinput-input').value =
      props.search_input;
  }, [props.search_input]);

  function handleBarsClick() {
    setDropdownMenuActive(!dropdownMenuActive);
    // !dropdownMenuActive
    //   ? (document.getElementById('dropdownheadermenu').style.display = 'flex')
    //   : (document.getElementById('dropdownheadermenu').style.display = 'none');
  }

  function handleSearchSubmit(e) {
    e.preventDefault();
    if (search !== props.search_input) {
      props.dispatch(requestSearchResults(search));
    }
    // props.dispatch(fetchSearchResults(search, 1, props.locale));
    history.push('/search');
  }

  function handleSearchInputChange(e) {
    setSearch(e.target.value);
  }

  return (
    <div>
      <div className='header-container'>
        <div className='header-subcontainer'>
          <div className='header-leftleft'>
            <FontAwesomeIcon
              onClick={handleBarsClick}
              className='header-bars'
              icon={faBars}
            />
          </div>
          <div className='header-left'>
            <Link to='/homepage'>
              <img
                className='header-logo'
                src={
                  window.$logo
                  // window.location.origin + '/images/sisley-demo_logo-sisley.jpeg'
                }
              ></img>
            </Link>
          </div>
          <div className='header-center'>
            <form onSubmit={handleSearchSubmit} className='header-searchinput'>
              <input
                id='header-searchinput-input'
                type='text'
                placeholder={I18n.t('search.placeholder')}
                onChange={handleSearchInputChange}
              ></input>
            </form>
          </div>
          <div className='header-right'>
            <LanguageDropdown
              className='header-lng-dropdown'
              color={'white'}
              className={'langs'}
            />
            <ParameterLink />
            <Link className='header-favoris' to='/favoris'>
              <FontAwesomeIcon className='header-favoris-star' icon={faStar} />
            </Link>
            <Link className='header-cart' to='/cart'>
              <FontAwesomeIcon
                className='header-cart-icon'
                icon={faShoppingBasket}
              />
            </Link>
            <div className='divider'></div>
            <a
              className='header-logout'
              href='#'
              onClick={() =>
                logout({
                  returnTo: window.location.origin,
                })
              }
            >
              <FontAwesomeIcon
                className='header-logout-icon'
                icon={faPowerOff}
              />
              <p className='header-logout-p'>
                <Translate value='header.logout' />
              </p>
            </a>
            <Link className='header-search' to='/search'>
              <FontAwesomeIcon className='header-searchIcon' icon={faSearch} />
            </Link>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: 66 }}></div>
      <div
        id='dropdownheadermenu'
        style={
          window.innerWidth < 840 && dropdownMenuActive
            ? { display: 'flex' }
            : { display: 'none' }
        }
      >
        <div className='header-forsmallscreensize'>
          <LanguageDropdown color={'black'} className={'dropdownlangs'} />
          <div className='dropdownoverlay'></div>
          <Link className='dropdown-linkitem' to='/parametres'>
            <FontAwesomeIcon className='dropdown-icon' icon={faCog} />
            <p className='dropdown-linkp'>
              <Translate value='header.dropdown.settings' />
            </p>
          </Link>
          <Link className='dropdown-linkitem' to='/favoris'>
            <FontAwesomeIcon className='dropdown-icon' icon={faStar} />
            <p className='dropdown-linkp'>
              <Translate value='header.dropdown.favourites' />
            </p>
          </Link>
          <Link className='dropdown-linkitem' to='/cart'>
            <FontAwesomeIcon
              className='dropdown-icon'
              icon={faShoppingBasket}
            />
            <p className='dropdown-linkp'>
              <Translate value='header.dropdown.cart' />
            </p>
          </Link>
          <Link className='dropdown-linkitem' to='/login'>
            <FontAwesomeIcon className='dropdown-icon' icon={faPowerOff} />
            <p className='dropdown-linkp'>
              <Translate value='header.dropdown.logout' />
              {/* {t('header.logout')} */}
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    search_input: state.search.search_input,
  };
}

export default connect(mapStateToProps)(Header);
