import React from 'react';
import { connect } from 'react-redux';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import '../../styles/components/ProductPage/DocumentLinks.css';
import { Link } from 'react-router-dom';

const DocumentLinks = (props) => {
  return (
    <div className='dl-container'>
      <div className='dl-subcontainer'>
        <Carousel
          slidesPerPage={3}
          itemWidth={280}
          arrowLeft={
            <div className='dl-arrow'>
              <FontAwesomeIcon icon={faChevronLeft} className='dl-arrow-icon' />
            </div>
          }
          arrowRight={
            <div className='dl-arrow'>
              <FontAwesomeIcon
                icon={faChevronRight}
                className='dl-arrow-icon'
              />
            </div>
          }
          className='dl-carousel'
          addArrowClickHandler
        >
          {props.product.data.documentLinks.map((link, index) => (
            <Link
              to={{
                pathname: '/product',
                search: '?' + link.target.id,
              }}
              className='dl-element-link'
            >
              <div className='dl-element-container'>
                <img
                  className='dl-element-img'
                  src={window.$visuel_defaut}
                ></img>
                <p className='dl-element-p'>{link.target.id}</p>
              </div>
            </Link>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    product: state.product.productData.product,
  };
}

export default connect(mapStateToProps)(DocumentLinks);
