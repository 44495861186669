import {
  REQUEST_CANAL,
  RECEIVE_CANAL,
  REQUEST_CANAL_FAILED,
  UPDATE_PATH,
  UPDATE_VISIBLE_PATH_ITEMS,
  UPDATE_CURRENT_PATH_ITEMS,
  SET_CURRENT_PATH_PRODUCTS_LENGTH,
  ADD_CURRENT_PATH_PRODUCT,
  RESET_CURRENT_PATH_PRODUCTS,
  REQUEST_PRODUCTS_FAILED,
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCTS,
  SET_CANAL_TREEVIEW_EXPANDED,
  SET_CURRENT_CANAL_CLASSIFICATION_ID,
  RECEIVE_CLASSIFICATION_IDS,
  SET_IDS_TO_IMAGES,
} from './types';
import axios from 'axios';

function createArborescenceList(data, locale) {
  return data.map((item, index) => {
    return item.children !== undefined && item.children.length > 0
      ? [
          item.fieldData.quable_classification_name.values[locale],
          createArborescenceList(item.children, locale),
        ]
      : item.fieldData.quable_classification_name.values[locale];
  });
}
export { createArborescenceList };

function createArborescenceListOfIds(data, locale) {
  return data.map((item) => {
    return item.children !== undefined && item.children.length > 0
      ? [
          {
            value: item.id,
            label: item.fieldData.quable_classification_name.values[locale],
          },
          createArborescenceListOfIds(item.children, locale),
        ]
      : {
          value: item.id,
          label: item.fieldData.quable_classification_name.values[locale],
        };
  });
}
export { createArborescenceListOfIds };

function buttonsSetup(buttons, parentIndex, locale) {
  let newButtons = [];
  buttons.map((item, index) => {
    newButtons.push([
      item.fieldData.quable_classification_name.values[locale],
      '',
      buttonsSetup(
        item.children,
        parentIndex.toString() + index.toString(),
        locale
      ),
      item.id,
      item.dateCreated,
      item.dateModified,
      parentIndex.toString() + index.toString(),
    ]);
  });
  return newButtons;
}

function pathSetup(followedPath, locale) {
  let newPath = [];
  followedPath.map((item, index) => {
    newPath.push(item.fieldData.quable_classification_name.values[locale]);
  });
  return newPath;
}

// ACTION CREATORS

export function requestCanal() {
  return {
    type: REQUEST_CANAL,
  };
}

export function receiveCanal(data) {
  return {
    type: RECEIVE_CANAL,
    payload: data,
    receivedAt: Date.now(),
  };
}

function requestCanalFailed(err) {
  return {
    type: REQUEST_CANAL_FAILED,
    payload: err,
  };
}

export function fetchCanal(locale, canalId) {
  return (dispatch) => {
    dispatch(requestCanal());
    dispatch(setCanalTreeViewExpanded([]));
    const catalogId = canalId.split('_')[1];
    console.log('catalogId: ', catalogId);
    return axios
      .get(
        process.env.REACT_APP_API_URL +
          '/catalogs/' +
          canalId +
          '/classifications',
        {}
      )
      .then((response) => {
        let newButtons = buttonsSetup(
          response.data['hydra:member'][0].children,
          '',
          locale
        );
        let newPath = pathSetup([], locale);
        dispatch(updateCurrentPathItems([['Produits', newButtons]]));
        dispatch(updateVisiblePathItems(newButtons));
        dispatch(updateCanalPath(newPath));
        dispatch(
          receiveClassificationIds(
            createArborescenceListOfIds(
              response.data['hydra:member'][0].children,
              locale
            ).flat(Infinity)
          )
        );
        dispatch(
          receiveCanal(
            createArborescenceList(
              response.data['hydra:member'][0].children,
              locale
            )
          )
        );
        axios
          .get(
            process.env.REACT_APP_API_URL +
              '/classifications?catalog.code=' +
              canalId,
            {}
          )
          .then((response) => {
            axios
              .get(
                process.env.REACT_APP_API_URL +
                  '/classifications?catalog.code=' +
                  canalId +
                  '&limit=' +
                  response.data['hydra:totalItems'] +
                  '&pagination=false',
                {}
              )
              .then((response) => {
                let idsToImages = {};
                response.data['hydra:member'].map((classification) => {
                  idsToImages[classification.id] = classification.mainAssetUrl;
                });
                dispatch(setIdsToImages(idsToImages));
              });
          });
      })
      .catch((err) => {
        dispatch(requestCanalFailed(err));
      });
  };
}

export function updateCanalPath(path) {
  return {
    type: UPDATE_PATH,
    payload: path,
    changedAt: Date.now(),
  };
}

export function updateVisiblePathItems(items) {
  return {
    type: UPDATE_VISIBLE_PATH_ITEMS,
    payload: items,
    changedAt: Date.now(),
  };
}

export function updateCurrentPathItems(items) {
  return {
    type: UPDATE_CURRENT_PATH_ITEMS,
    payload: items,
    changedAt: Date.now(),
  };
}

function audienceParameterToArray(object, array) {
  var a = Object.entries(object);
  if (typeof a[0][1] === 'string') {
    array.push(a[0][0]);
    return [array.join('.'), a[0][1]];
  } else {
    array.push(a[0][0]);
    return audienceParameterToArray(a[0][1], array);
  }
}

export function initializeItemsContainerAction(
  id,
  nbPage,
  nbValues,
  locale,
  audience
) {
  let addFilterAudience = '';
  if (audience && audience.filters) {
    let audienceParameter = audienceParameterToArray(audience.filters, []);
    addFilterAudience = '&' + audienceParameter[0] + '=' + audienceParameter[1];
  }
  return (dispatch) => {
    dispatch(requestProducts());
    return axios
      .get(
        process.env.REACT_APP_API_URL +
          '/documents?classifications.id=' +
          id +
          '&page=' +
          nbPage +
          '&limit=' +
          nbValues +
          addFilterAudience,
        {}
      )
      .then(async (response) => {
        if (response.data['hydra:totalItems'] === 0) {
          dispatch(receiveProducts());
        }
        dispatch(resetCurrentPathProducts());
        // dispatch(setCurrentCanalClassificationId(id));
        dispatch(
          setCurrentPathProductsLength(response.data['hydra:totalItems'])
        );
        let lastIndex;
        await Promise.all(
          response.data['hydra:member'].map(async (item, index) => {
            lastIndex = index;
            dispatch(addCurrentPathProduct(item));
          })
        );

        if (
          lastIndex === nbValues - 1 ||
          nbValues * nbPage - (nbValues - lastIndex) ===
            response.data['hydra:totalItems'] - 1
        ) {
          dispatch(receiveProducts());
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(requestProductsFailed(err));
      });
  };
}

export function setCurrentPathProductsLength(totalLength) {
  return {
    type: SET_CURRENT_PATH_PRODUCTS_LENGTH,
    payload: totalLength,
    addedAt: Date.now(),
  };
}

export function addCurrentPathProduct(product) {
  return {
    type: ADD_CURRENT_PATH_PRODUCT,
    payload: product,
    addedAt: Date.now(),
  };
}

export function resetCurrentPathProducts() {
  return {
    type: RESET_CURRENT_PATH_PRODUCTS,
    changedAt: Date.now(),
  };
}

function requestProducts() {
  return {
    type: REQUEST_PRODUCTS,
    receivedAt: Date.now(),
  };
}

function requestProductsFailed(err) {
  return {
    type: REQUEST_PRODUCTS_FAILED,
    payload: err,
  };
}

function receiveProducts() {
  return {
    type: RECEIVE_PRODUCTS,
    receivedAt: Date.now(),
  };
}

export function resetProducts() {
  return {
    type: 'RESET_PRODUCTS',
    updatedAt: Date.now(),
  };
}

export function setCanalTreeViewExpanded(expanded) {
  return {
    type: SET_CANAL_TREEVIEW_EXPANDED,
    payload: expanded,
    changedAt: Date.now,
  };
}

export function setCurrentCanalClassificationId(id) {
  return {
    type: SET_CURRENT_CANAL_CLASSIFICATION_ID,
    payload: id,
    addedAt: Date.now(),
  };
}

export function receiveClassificationIds(data) {
  return {
    type: RECEIVE_CLASSIFICATION_IDS,
    payload: data,
    receivedAt: Date.now(),
  };
}

export function setIdsToImages(data) {
  return {
    type: SET_IDS_TO_IMAGES,
    payload: data,
    receivedAt: Date.now(),
  };
}
