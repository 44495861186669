import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faShoppingBasket,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ProductInfoTable, MultiProductImageCarousel } from '../../components';
import '../../styles/components/ProductPage/ImagesAndInfo.css';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import {
  addItemToWishlist,
  removeItemFromWishlist,
} from '../../redux/actions/userActions';
import { Tooltip } from '@material-ui/core';
import { Translate } from 'react-redux-i18n';
import { noImage } from '../../images/no-image.png';
import { addToCart, removeFromCart } from '../../redux/actions/cartActions';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

const ImagesAndInfo = (props) => {
  const [currentImageUrl, setCurrentImageUrl] = useState('');

  var getUrl = window.location;
  var baseUrl = getUrl.protocol + '//' + getUrl.host + '/';

  var I18n = require('react-redux-i18n').I18n;

  const [productInCart, setProductInCart] = useState(false);

  const addNotify = () => toast(I18n.t('toast.addProductMediasToCart'));

  const removeNotify = () => toast(I18n.t('toast.removeProductMediasFromCart'));

  useEffect(() => {
    if (
      props.product.assetsData !== undefined &&
      Object.entries(props.product.assetsData.assets).length === 0
    ) {
      // setCurrentImageUrl(window.$visuel_defaut);
      setCurrentImageUrl('');
    }
  }, [props.product.assetsData]);

  useEffect(() => {
    if (props.product.productData.product != undefined) {
      let assetsContained = 0;
      props.product.productData.product.data.assetLinks.map(
        (assetLink, index) => {
          props.cartitems
            .map((item, index) => item['id'])
            .includes(assetLink['target']['id'])
            ? assetsContained++
            : (assetsContained = assetsContained);
        }
      );
      if (
        assetsContained ===
        props.product.productData.product.data.assetLinks.length
      ) {
        setProductInCart(true);
      }
    }
  }, [props.product.productData.product]);

  function setCurrentImageUrlFromChild(url) {
    setCurrentImageUrl(url);
  }

  function handleStarClick(e, index) {
    e.preventDefault();
    if (
      props.wishlist
        .map((item) => item['id'])
        .includes(props.product.productData.product.data.id)
    ) {
      props.dispatch(
        removeItemFromWishlist(
          props.wishlist
            .map((item) => item['id'])
            .indexOf(props.product.productData.product.data.id)
        )
      );
    } else {
      if (props.product.assetsData.assets.article_images_media !== undefined) {
        props.dispatch(
          addItemToWishlist(props.product.productData.product.data)
        );
      } else {
        props.dispatch(
          addItemToWishlist(props.product.productData.product.data)
        );
      }
    }
  }

  function handleCartClick() {
    if (!productInCart) {
      setProductInCart(true);
      Object.entries(props.product.assetsData.assets).map((category, index) => {
        category[1].map((asset, index) => {
          let items = props.cartitems.map((item) => JSON.stringify(item));
          let as = {
            name: asset['name'],
            id: asset['id'],
            url: asset['url'],
          };
          if (!items.includes(JSON.stringify(as))) {
            props.dispatch(addToCart(asset));
          }
        });
      });
      addNotify();
    } else {
      setProductInCart(false);
      Object.entries(props.product.assetsData.assets).map((category, index) => {
        category[1].map((asset, index) => {
          let items = props.cartitems.map((item) => JSON.stringify(item));
          let as = {
            name: asset['name'],
            id: asset['id'],
            url: asset['url'],
          };
          if (!items.includes(JSON.stringify(as))) {
            props.dispatch(removeFromCart(asset['id']));
          }
        });
      });
      removeNotify();
    }
  }

  let extension = currentImageUrl
    .substring(0, currentImageUrl.lastIndexOf('.'))
    .substring(currentImageUrl.lastIndexOf('-') + 1, currentImageUrl.length);

  if (props.product.productData.product !== undefined) {
    let name =
      props.product.productData.product.data.attributes[
        props.product.productData.product.data.documentType.id + '_name'
      ][props.locale];
    return (
      <div className='iai-container'>
        <ToastContainer hideProgressBar />
        <div className='iai-productpage-subcontainer'>
          {!props.loading ? (
            <div
              className='iai-productpage-image'
              style={
                currentImageUrl.length > 0 &&
                (extension === 'jpg' ||
                  extension === 'png' ||
                  extension === 'jpeg')
                  ? {
                      backgroundImage: 'url(' + currentImageUrl + ')',
                    }
                  : { backgroundImage: 'url(' + window.$visuel_defaut + ')' }
              }
            ></div>
          ) : (
            <div className='iai-productpage-loadingimage'>
              <Loader type='TailSpin' color='black' height={100} width={100} />
            </div>
          )}
          <div className='iai-fullinfocontainer'>
            <div className='iai-infofavori-container'>
              <Tooltip
                title={<Translate value='tooltips.cart' />}
                arrow
                placement='top'
              >
                <div>
                  <FontAwesomeIcon
                    className={
                      productInCart ? 'iai-infocart-active' : 'iai-infocart'
                    }
                    icon={faShoppingBasket}
                    onClick={(e) => handleCartClick(e)}
                  />
                </div>
              </Tooltip>
              <Tooltip
                style={{ marginLeft: 10 }}
                title={<Translate value='tooltips.favourite' />}
                arrow
                placement='top'
              >
                <div>
                  <FontAwesomeIcon
                    className={
                      props.wishlist
                        .map((item) => item['id'])
                        .includes(props.product.productData.product.data.id)
                        ? 'iai-infofavori-active'
                        : 'iai-infofavori'
                    }
                    icon={faStar}
                    onClick={(e) => handleStarClick(e)}
                  />
                </div>
              </Tooltip>
              <a
                style={{ marginLeft: 10 }}
                href={
                  'mailto:?subject=' +
                  props.admin.quableUser.givenName +
                  ' ' +
                  props.admin.quableUser.familyName +
                  ' vous envoie le document ' +
                  name +
                  '&body=' +
                  baseUrl +
                  'product?' +
                  props.product.productData.product.data.id
                }
              >
                <FontAwesomeIcon
                  className='iai-infofavori'
                  icon={faPaperPlane}
                  onClick={(e) => e.stopPropagation()}
                ></FontAwesomeIcon>
              </a>
            </div>
            <div className='iai-infocontainer'>
              <p className='iai-infotitle'>
                {
                  props.product.productData.product.data.attributes[
                    props.product.productData.product.data.documentType.id +
                      '_name'
                  ][props.locale]
                }
              </p>
              <ProductInfoTable />
              {!props.loading ? (
                <MultiProductImageCarousel
                  setCurrentImageUrl={setCurrentImageUrlFromChild}
                />
              ) : (
                <Loader type='TailSpin' color='black' height={50} width={50} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    loading: state.product.isFetchingAssets,
    wishlist: state.user.wishlist,
    product: state.product,
    cartitems: state.cart.items,
    admin: state.adminReducer,
  };
}

export default connect(mapStateToProps)(ImagesAndInfo);
