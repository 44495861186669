// export const supportedLocales = {
//   cs_CZ: 'cs_CZ',
//   de_DE: 'de_DE',
//   en_GB: 'en_GB',
//   en_US: 'en_US',
//   es_ES: 'es_ES',
//   fr_FR: 'fr_FR',
//   it_IT: 'it_IT',
//   ja_JP: 'jp_JP',
//   ko_KR: 'ko_KR',
//   nl_NL: 'nl_NL',
//   pl_PL: 'pl_PL',
//   pt_PT: 'pt_PT',
//   ru_RU: 'ru_RU',
//   th_TH: 'th_TH',
//   zh_CN: 'zh_CN',
// };

export const fallbackLocale = 'en_GB';
