import { SET_LANGUAGES } from '../actions/types';

const initialState = {
  locales: {},
};

export const languagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGES:
      return {
        locales: action.payload,
      };
    default:
      return state;
  }
};
