import { setLocale } from 'react-redux-i18n';
import { supportedLocales, fallbackLocale } from '../../config/i18n';
import { translations } from '../../l10n/translations';

export function setLocaleWithFallback(desiredLocale) {
  const finalLocale = Object.keys(translations).includes(desiredLocale)
    ? desiredLocale
    : fallbackLocale;
  return (dispatch) => dispatch(setLocale(finalLocale));
}
