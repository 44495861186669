import React, { useState, useEffect } from 'react';
import '../styles/components/LanguageDropdown.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
// import i18n from '../i18n (UNUSED)';
// import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setLocale } from 'react-redux-i18n';
import { setLocaleWithFallback } from '../redux/actions/i18nActions';
import { Translate } from 'react-redux-i18n';
// import { supportedLocales } from '../config/i18n';

const LanguageDropdown = (props) => {
  const [langs, setLangs] = useState([]);

  useEffect(() => {
    setLangs(props.languages);
  }, [props.languages]);

  return (
    <div className={props.className + ' ld-dropdown'}>
      <button style={{ color: props.color }} className='ld-dropbtn'>
        <p>{props.languages[props.locale]}</p>
        <FontAwesomeIcon className='ld-chevrondown' icon={faChevronDown} />
      </button>
      <div className='ld-dropdown-content'>
        <p>
          <Translate value='header.language' />
        </p>
        {Object.entries(langs).map((language, index) => (
          <div key={index} className='ld-dropdown-element'>
            <div
              className='ld-dropdown-element-name'
              onClick={() => props.dispatch(setLocaleWithFallback(language[0]))}
            >
              {props.languages[language[0]]}
            </div>
            <FontAwesomeIcon
              style={
                props.locale === language[0]
                  ? { display: 'block' }
                  : { display: 'none' }
              }
              id={'check' + index}
              className='ld-check'
              icon={faCheck}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

LanguageDropdown.propTypes = {
  color: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    translations: state.i18n.translations,
    languages: state.languagesReducer.locales,
  };
}

export default connect(mapStateToProps)(LanguageDropdown);
