import React, { useState, useEffect } from 'react';
import { Link_1 } from './';
import '../styles/components/SubHeader.css';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import { useTranslation } from 'react-i18next';
import { Translate } from 'react-redux-i18n';
import Prismic from 'prismic-javascript';
import { connect } from 'react-redux';

const SubHeader = (props) => {
  const bandeau = window.$bandeau;
  const client = Prismic.client(
    props.instancedata.prismicParameters.apiUrl +
      '?access_token=' +
      props.instancedata.prismicParameters.accessToken
  );

  const [options, setOptions] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    client
      .query(Prismic.Predicates.at('document.type', 'subheader'), {
        lang: props.locale.replace('_', '-').toLowerCase(),
      })
      .then((res) => {
        if (res.results.length > 0) {
          let pr_sh = res.results[0].data;
          setVisible(pr_sh.visible);
          setOptions(
            pr_sh.body.map((link, index) => {
              return {
                value: link.primary.value.url,
                label: link.primary.label[0].text,
              };
            })
          );
        } else {
          client
            .query(Prismic.Predicates.at('document.type', 'subheader'), {
              lang: 'en-gb',
            })
            .then((res) => {
              let pr_sh = res.results[0].data;
              setVisible(pr_sh.visible);
              setOptions(
                pr_sh.body.map((link, index) => {
                  return {
                    value: link.primary.value.url,
                    label: link.primary.label[0].text,
                  };
                })
              );
            });
        }
      });
  }, [props.locale]);

  function handleClick(value) {
    var a = document.createElement('a');
    a.href = value;
    // a.target = '_blanc';
    a.click();
  }

  if (props.type === 'Main') {
    return (
      <div className='home-subheader'>
        {/* <div className='sh-img-bandeau'></div> */}
        <div
          className='sh-subcontainer'
          style={{
            background:
              'linear-gradient(to left, rgba(0,0,0,0), rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 1)), url(' +
              bandeau +
              ')',
          }}
        >
          <div className='sh-subsubcontainer'>
            <div style={{ width: '100%', marginTop: 16 }}></div>
            {window.$contenu !== 'Médias seulement' ? (
              <Link_1 selected={props.produitsSelected} to='/produits'>
                <Translate value='subheader.produits' />
                {/* {t('subheader.produits')} */}
              </Link_1>
            ) : (
              ''
            )}
            {window.$contenu !== 'Produits seulement' ? (
              <Link_1 selected={props.mediasSelected} to='/medias'>
                <Translate value='subheader.medias' />
                {/* {t('subheader.medias')} */}
              </Link_1>
            ) : (
              ''
            )}
            {options.map((option) => (
              <div
                style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                className='link enabledLink'
                onClick={() => handleClick(option.value)}
              >
                {option.label}
              </div>
            ))}
            <div style={{ width: '100%', marginBottom: 16 }}></div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className='home-subheader'>
        <div className='sh-subcontainer'>
          <div className='sh-subsubcontainer'>
            <Link_1 selected={props.dashboardSelected} to='/homepage'>
              <div className='retour'>
                <FontAwesomeIcon className='icon' icon={faArrowLeft} />
                <Translate value='subheader.goback' />
                {/* <p>{t('subheader.goback')}</p> */}
              </div>
            </Link_1>
            <h1 className='subheader-title'>{props.children}</h1>
          </div>
        </div>
      </div>
    );
  }
};

SubHeader.propTypes = {
  type: PropTypes.string,
  dashboardSelected: PropTypes.bool,
  produitsSelected: PropTypes.bool,
  mediasSelected: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    instancedata: state.adminReducer.instancedata,
  };
}

export default connect(mapStateToProps)(SubHeader);
