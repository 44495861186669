import React from 'react';
import Loader from 'react-loader-spinner';

const Loading = () => {
  return (
    <div className='center'>
      <Loader type='TailSpin' color='black' height={100} width={100} />
    </div>
  );
};

export default Loading;
