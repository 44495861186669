import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/components/Link_1.css';
import PropTypes from 'prop-types';

const Link_1 = props => {
  return (
    <Link
      className={
        props.selected === true ? 'link disabledLink' : 'link enabledLink'
      }
      to={props.to}>
      {props.children}
    </Link>
  );
};

Link_1.propTypes = {
  selected: PropTypes.bool
};

export default Link_1;
