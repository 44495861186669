/**
 * Created by mnassih on 25/09/2020.
 */

import React, {useState, useEffect} from 'react';
import {Container, Row, Col,Button} from "react-bootstrap";
import {updateaudience,createaudience} from '../../redux/actions/adminAction';
import {connect} from 'react-redux';
import {useAuth0} from "@auth0/auth0-react";
import Loader from 'react-loader-spinner'
import '../../styles/components/userinfo.css';
const AudienceForm = (props) => {
    const {

        getIdTokenClaims

    } = useAuth0();

    const [loaded, setLoaded] = useState(false);
    const [audienceSelected, setAudienceSelected]=useState({})
    const [hasError,setHasError]=useState(false)


    const callApi = async() => {
        setAudienceSelected(props.audienceSelected)
        setLoaded(!loaded)
    }


    useEffect(() => {
        //  console.log("Selected = ",props.userSelected)
        if (!loaded)
            callApi()


    });


    const updatCurrenteAudience = () => {

        if(audienceSelected.name=='' || audienceSelected.filters.attributes.zone_de_diffusion_codes =='')
            setHasError(true)
else{
            // Mode mise à jour
            if (!props.createMode)
                getIdTokenClaims().then((token) => {
                    props.dispatch(updateaudience(token, audienceSelected));
                    props.closePopUP()

                });
// Mode création
            else
                getIdTokenClaims().then((token) => {
                    props.dispatch(createaudience(token, audienceSelected));
                    props.closePopUP()

                });
        }
    }

    const errorMsg=()=>{
        return (
        (hasError)?
            <Row popUpRow><Col xs="12" className="ztoerrormsg">Veuillez saisir les champs obligatoires </Col></Row>:
            <div></div>
        )
    }

    const closeModal = () => {
        props.closePopUP()
    }

    const handleChange = (event) => {
        setHasError(true)
        setAudienceSelected({...audienceSelected, name: event.target.value})
    }

    const handleFilterChange = (event) => {
        setHasError(true)
        audienceSelected.filters.attributes.zone_de_diffusion_codes = event.target.value
        setAudienceSelected({...audienceSelected})
    }
    const hideError=()=>{
        setHasError(false)
    }

    const ModalTitle = () => {
        return (
            (!props.createMode) ?
                ( "Modifier ") :
                ("Créer ")
        )
    }

    return (!loaded) ? (<div className="center"><Loader type='TailSpin' color='black' height={100} width={100}/></div>) : (
            <div className="ztoAudiencePopUpCont">

                <div className="ztogroupedrow ztoModalTitle">
                    <Row className="popUpRow">
                        <Col className="popupItemPadding" xs="10">{ModalTitle()} une audience </Col>
                        <Col className="popupItemPadding closeArrow" xs="2"  onClick={ closeModal}>
                            <a onClick={ closeModal}></a>
                            x</Col>
                    </Row>
                </div>
                <div className="ztogroupedrow">
                    <Row  className="popUpRow">
                        <Col className="popupItemPadding ztoItemLabel" xs="12">NOM <sup className="ztomandory">*</sup></Col>

                    </Row>
                    <Row  className="popUpRow">

                        <Col xs="12">
                            <input
                                id='userform-name'
                                type='text'
                                className="popInput"
                                value={audienceSelected.name}
                                onChange={handleChange}
                                onFocus={ hideError}

                            />
                        </Col>

                    </Row>
                </div>
                <div className="ztogroupedrow">
                    <Row  className="popUpRow">
                        <Col xs="12" className="ztoItemLabel">FILTRE <sup className="ztomandory">*</sup></Col>
                        <Col xs="12">
                            <input
                                id='userform-diffusioncode'
                                type='text'
                                className="popInput"
                                value={audienceSelected.filters.attributes.zone_de_diffusion_codes}
                                onChange={handleFilterChange}
                                onFocus={ hideError}

                            />
                        </Col>
                    </Row>

                </div>
                {errorMsg()}


                <Row className="ztoactionButton popUpRow">
                    <Col xs="12">
                        <Button variant="primary"  onClick={updatCurrenteAudience}>{ModalTitle()} audience</Button>
                        {'   '}
                        <Button variant="danger"  onClick={ closeModal}>Fermer</Button>
                    </Col>
                </Row>

            </div>)


}

function mapStateToProps(state) {
    return {
        audiences: state.adminReducer.audiences
    };
}

export default connect(mapStateToProps)(AudienceForm);