import React from 'react';
import '../styles/components/Footer.css';

const Footer = () => {
  return (
    <div className='footer-container'>
      <div className='footer-subcontainer'>
        <div class='footer'>
          <span>2020 - POWERED BY</span>
          <div class='imgFooter'></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
