import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import '../../styles/components/MediaPage/MultiMediaImageCarousel.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const MultiMediaImageCarousel = (props) => {
  const [currentIndex, setCurrentIndex] = useState();

  useEffect(() => {
    if (props.currentAssets.length > 0) {
      setCurrentIndex(props.initialIndex);
      props.setCurrentImageInfo(props.currentAssets[props.initialIndex]);
    }
  }, []);

  function handleImageClick(image, index) {
    setCurrentIndex(index);
    props.setCurrentImageInfo(image);
  }

  function handleLeftArrowClick() {
    if (currentIndex <= 0) {
      // console.log(
      //   props.currentAssets.length -
      //     (Math.abs(currentIndex) % props.currentAssets.length) -
      //     1
      // );
      props.setCurrentImageInfo(
        props.currentAssets[
          props.currentAssets.length -
            (Math.abs(currentIndex) % props.currentAssets.length) -
            1
        ]
      );
      // setCurrentIndex(props.currentAssets.length - 1);
    } else if (currentIndex > props.currentAssets.length) {
      let newIndex = (currentIndex % props.currentAssets.length) - 1;
      newIndex <= -1
        ? (newIndex = props.currentAssets.length + newIndex)
        : (newIndex = newIndex);
      // console.log(newIndex);
      props.setCurrentImageInfo(props.currentAssets[newIndex]);
      // setCurrentIndex(currentIndex - 1);
    } else {
      // console.log(currentIndex - 1);
      props.setCurrentImageInfo(props.currentAssets[currentIndex - 1]);
    }
  }

  function handleRightArrowClick() {
    if (currentIndex >= props.currentAssets.length - 1) {
      // console.log((currentIndex + 1) % props.currentAssets.length);
      props.setCurrentImageInfo(
        props.currentAssets[(currentIndex + 1) % props.currentAssets.length]
      );
      // setCurrentIndex(0);
    } else if (currentIndex < -1) {
      let newIndex =
        props.currentAssets.length -
        (Math.abs(currentIndex) % props.currentAssets.length) +
        1;
      newIndex >= props.currentAssets.length
        ? (newIndex = newIndex - props.currentAssets.length)
        : (newIndex = newIndex);
      // console.log(newIndex);
      props.setCurrentImageInfo(props.currentAssets[newIndex]);
    } else {
      // console.log(currentIndex + 1);
      props.setCurrentImageInfo(props.currentAssets[currentIndex + 1]);
    }
  }

  function onChange(value) {
    setCurrentIndex(value);
    // if (value === props.currentAssets.length) {
    //   setCurrentIndex(0);
    // } else if (value === -1) {
    //   setCurrentIndex(props.currentAssets.length - 1);
    // } else {
    //   setCurrentIndex(value);
    // }
  }

  return (
    <div className='mmic-container'>
      <Carousel
        itemWidth={82}
        arrowLeft={
          <div className='mmic-arrow' onClick={handleLeftArrowClick}>
            <FontAwesomeIcon style={{ color: 'white' }} icon={faChevronLeft} />
          </div>
        }
        arrowRight={
          <div className='mmic-arrow' onClick={handleRightArrowClick}>
            <FontAwesomeIcon style={{ color: 'white' }} icon={faChevronRight} />
          </div>
        }
        addArrowClickHandler
        clickToChange
        value={currentIndex}
        onChange={(e) => onChange(e)}
        infinite={props.currentAssets.length > 1}
        centered={props.currentAssets.length === 1}
      >
        {props.currentAssets.map((image, index) => {
          let extension = image['url']
            .substring(0, image['url'].lastIndexOf('.'))
            .substring(image['url'].lastIndexOf('-') + 1, image['url'].length);
          if (
            extension !== 'jpg' &&
            extension !== 'png' &&
            extension !== 'jpeg' &&
            extension !== 'pdf' &&
            extension !== 'xlsx' &&
            extension !== 'pptx' &&
            extension !== 'mp4' &&
            extension !== 'mp3' &&
            extension !== 'wma' &&
            extension !== 'ogg'
          ) {
            extension = image['url'].substring(
              image['url'].lastIndexOf('.') + 1,
              image.length
            );
          }
          console.log(image);
          return index === currentIndex % props.currentAssets.length ? (
            <img
              onClick={() => handleImageClick(image, index)}
              key={index}
              className='mmic-carouselimg-active'
              src={
                extension === 'jpg' ||
                extension === 'png' ||
                extension === 'jpeg'
                  ? image['thumbnailUrl']
                  : window.$visuel_defaut
              }
            />
          ) : (
            <img
              onClick={() => handleImageClick(image, index)}
              key={index}
              className='mmic-carouselimg'
              src={
                extension === 'jpg' ||
                extension === 'png' ||
                extension === 'jpeg'
                  ? image['url']
                  : window.$visuel_defaut
              }
            />
          );
        })}
      </Carousel>
    </div>
  );
};

export default MultiMediaImageCarousel;
