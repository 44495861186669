import {
  REQUEST_PRODUCT,
  RECEIVE_PRODUCT,
  REQUEST_PRODUCT_FAILED,
  REQUEST_PATH,
  REQUEST_PATH_FAILED,
  RECEIVE_PATH,
  REQUEST_ATTRIBUTES,
  RECEIVE_ATTRIBUTES,
  SET_ASSETS_LINK_TYPES_MAPPING,
  REQUEST_ASSETS,
  RECEIVE_ASSETS,
  REQUEST_ASSET_FAILED,
  REQUEST_VARIANTS,
  RECEIVE_VARIANTS,
  REQUEST_VARIANT_FAILED,
  REQUEST_DOCUMENT_LINKS,
  RECEIVE_DOCUMENT_LINKS,
  REQUEST_DOCUMENT_LINK_FAILED,
  REQUEST_DOCUMENT_TYPE_INFO,
  RECEIVE_DOCUMENT_TYPE_INFO,
  ADD_DOCUMENT_TYPE_INFO_ATTRIBUTE_TO_MAPPING,
} from './types';
import React from 'react';
import { bool } from 'prop-types';
import axios from 'axios';

import { pptxIcon } from '../../images/pptx-icon.png';
import { pdfIcon } from '../../images/pdf-icon.png';
import { xlsxIcon } from '../../images/xlsx-icon.png';

import {
  updateCanalPath,
  setCanalTreeViewExpanded,
  updateCurrentPathItems,
  setCurrentCanalClassificationId,
  updateVisiblePathItems,
  initializeItemsContainerAction,
} from './canalActions';

// ACTION CREATORS

export function requestProduct() {
  return {
    type: REQUEST_PRODUCT,
  };
}

function requestProductFailed(id, err) {
  return {
    type: REQUEST_PRODUCT_FAILED,
    payload: [id, err],
  };
}

export function receiveProduct(data) {
  return {
    type: RECEIVE_PRODUCT,
    payload: data,
    receivedAt: Date.now(),
  };
}

export function requestPath() {
  return {
    type: REQUEST_PATH,
  };
}

export function requestPathFailed() {
  return {
    type: REQUEST_PATH_FAILED,
  };
}

export function receivePath(data) {
  return {
    type: RECEIVE_PATH,
    payload: data,
    receivedAt: Date.now(),
  };
}

export function requestAttributes() {
  return {
    type: REQUEST_ATTRIBUTES,
  };
}

export function receiveAttributes(data) {
  return {
    type: RECEIVE_ATTRIBUTES,
    payload: data,
    receivedAt: Date.now(),
  };
}

export function setAssetsLinkTypesMapping(mapping) {
  return {
    type: SET_ASSETS_LINK_TYPES_MAPPING,
    payload: mapping,
    setAt: Date.now(),
  };
}

export function requestAssets() {
  return {
    type: REQUEST_ASSETS,
  };
}

function requestAssetFailed(asset, err) {
  return {
    type: REQUEST_ASSET_FAILED,
  };
}

export function receiveAssets(data) {
  return {
    type: RECEIVE_ASSETS,
    payload: data,
    receivedAt: Date.now(),
  };
}

export function requestVariants() {
  return {
    type: REQUEST_VARIANTS,
  };
}

function requestVariantFailed(id, err) {
  return {
    type: REQUEST_VARIANT_FAILED,
    payload: [id, err],
  };
}

export function receiveVariants(variants, variantsNamesToCodesMapping) {
  return {
    type: RECEIVE_VARIANTS,
    payload: {
      variants: variants,
      variantsNamesToCodesMapping: variantsNamesToCodesMapping,
    },
    receivedAt: Date.now(),
  };
}

export function requestDocumentLinks() {
  return {
    type: REQUEST_DOCUMENT_LINKS,
  };
}

function requestDocumentLinkFailed(id, err) {
  return {
    type: REQUEST_DOCUMENT_LINK_FAILED,
    payload: [id, err],
  };
}

export function receiveDocumentLinks(documentLinks) {
  return {
    type: RECEIVE_DOCUMENT_LINKS,
    payload: documentLinks,
    receivedAt: Date.now(),
  };
}

export function fetchProduct(
  id,
  locale,
  pathItems,
  pathCurrentPathItems,
  audience,
  classificationIds,
  canalId
) {
  return (dispatch) => {
    dispatch(requestProduct());
    return axios
      .get(process.env.REACT_APP_API_URL + '/documents/' + id, {})
      .then((response) => {
        dispatch(receiveProduct(response));
        dispatch(
          fetchPath(
            id,
            locale,
            pathItems,
            pathCurrentPathItems,
            audience,
            canalId
          )
        );
        dispatch(fetchAttributes(response.data.attributes, locale));
        dispatch(fetchAssets(response.data.assetLinks, locale));
        dispatch(fetchVariants(response.data.variants, canalId, locale));
        dispatch(
          fetchDocumentLinks(
            response.data.documentLinks,
            id,
            classificationIds,
            locale,
            audience
          )
        );
      })
      .catch((err) => {
        dispatch(requestProductFailed(id, err));
      });
  };
}

function findExpanded(pathFollowed, pathItems, expanded) {
  return (dispatch) => {
    if (pathItems !== undefined) {
      for (let index = 0; index < pathItems.length; index++) {
        if (
          Array.isArray(pathItems[index]) &&
          pathFollowed[0] === pathItems[index][0]
        ) {
          pathFollowed.shift();
          expanded.push(index);
          dispatch(findExpanded(pathFollowed, pathItems[index][1], expanded));
          break;
        } else if (pathFollowed[0] === pathItems[index]) {
          pathFollowed.shift();
          expanded.push(index);
          if (pathFollowed.length === 0) {
            dispatch(setCanalTreeViewExpanded(expanded));
          } else {
            dispatch(findExpanded(pathFollowed, pathItems[index], expanded));
          }
          break;
        }
      }
    }
  };
}

function findCurrentPathItems(
  pathFollowed,
  pathCurrentPathItems,
  newCurrentPathItems,
  locale,
  audience
) {
  return (dispatch) => {
    if (pathCurrentPathItems.length > 0) {
      for (let i = 0; i < pathCurrentPathItems.length; i++) {
        if (pathCurrentPathItems[i][0] === pathFollowed[0]) {
          pathFollowed.shift();
          newCurrentPathItems.push([
            pathCurrentPathItems[i][0],
            pathCurrentPathItems[i][2],
            pathCurrentPathItems[i][3],
          ]);
          dispatch(
            findCurrentPathItems(
              pathFollowed,
              pathCurrentPathItems[i][2],
              newCurrentPathItems,
              locale,
              audience
            )
          );
          break;
        }
      }
    } else {
      dispatch(
        setCurrentCanalClassificationId(
          newCurrentPathItems[newCurrentPathItems.length - 1][3]
        )
      );
      dispatch(updateCurrentPathItems(newCurrentPathItems));
      dispatch(updateVisiblePathItems([]));
      dispatch(
        initializeItemsContainerAction(
          newCurrentPathItems[newCurrentPathItems.length - 1][3],
          1,
          20,
          locale,
          audience
        )
      );
    }
  };
}

function updateCanal(
  pathFollowed,
  pathItems,
  pathCurrentPathItems,
  locale,
  audience
) {
  return (dispatch) => {
    dispatch(updateCanalPath(['Produits', ...pathFollowed]));
    dispatch(findExpanded([...pathFollowed], pathItems, []));
    dispatch(
      findCurrentPathItems(
        [...pathFollowed],
        pathCurrentPathItems[0][1],
        [pathCurrentPathItems[0]],
        locale,
        audience
      )
    );
  };
}

function findPath(
  data,
  locale,
  id,
  pathFollowed,
  pathItems,
  pathCurrentPathItems,
  audience
) {
  return (dispatch) => {
    data.map((item, index) => {
      let newPathFollowed = [...pathFollowed];
      if (item.children.length > 0) {
        newPathFollowed.push(
          item.fieldData.quable_classification_name.values[locale]
        );
        dispatch(
          findPath(
            item.children,
            locale,
            id,
            newPathFollowed,
            pathItems,
            pathCurrentPathItems
          )
        );
      } else {
        newPathFollowed.push(
          item.fieldData.quable_classification_name.values[locale]
        );
        axios
          .get(
            process.env.REACT_APP_API_URL +
              '/documents?classifications.id=' +
              item.id +
              '&page=1&limit=1&pagination=true',
            {}
          )
          .then((response) => {
            axios
              .get(
                process.env.REACT_APP_API_URL +
                  '/documents?classifications.id=' +
                  item.id +
                  '&page=1&limit=' +
                  response.data['hydra:totalItems'] +
                  'pagination=true',
                {}
              )
              .then((response) => {
                let found = false;
                response.data['hydra:member'].map((product, index) => {
                  if (product.id === id) {
                    found = true;
                    dispatch(
                      updateCanal(
                        newPathFollowed,
                        pathItems,
                        pathCurrentPathItems,
                        locale,
                        audience
                      )
                    );
                    newPathFollowed.unshift('Produits');
                    dispatch(receivePath(newPathFollowed));
                  } else if (
                    index === response.data['hydra:member'].length - 1 &&
                    found === false
                  ) {
                    dispatch(requestPathFailed());
                  }
                });
              });
          });
      }
    });
  };
}

function fetchPath(
  id,
  locale,
  pathItems,
  pathCurrentPathItems,
  audience,
  canalId
) {
  return (dispatch) => {
    dispatch(requestPath());
    const catalogId = canalId.split('_')[1];
    console.log('catalogId: ', catalogId);
    return axios
      .get(
        process.env.REACT_APP_API_URL +
          '/catalogs/' +
          canalId +
          '/classifications',
        {}
      )
      .then((response) => {
        dispatch(
          findPath(
            response.data['hydra:member'][0].children,
            locale,
            id,
            [],
            pathItems,
            pathCurrentPathItems
          )
        );
      });
  };
}

function fetchAttributes(attributes, locale) {
  return async (dispatch) => {
    dispatch(requestAttributes());
    let flds = [];
    await Promise.all(
      Object.entries(attributes).map(async (attribute, index) => {
        await axios
          .get(
            process.env.REACT_APP_API_URL + '/attributes/' + attribute[0],
            {}
          )
          .then((attribute_name) => {
            if (!isNaN(attribute[1] || typeof attribute[1] === bool)) {
              flds.push([
                attribute_name.data.name[locale.replace('_', '-')],
                attribute[1].toString(),
                attribute[0],
              ]);
            } else if (Array.isArray(attribute[1])) {
              flds.push([
                attribute_name.data.name[locale.replace('_', '-')],
                attribute[1][0].labels[locale],
                attribute[0],
              ]);
            } else {
              flds.push([
                attribute_name.data.name[locale.replace('_', '-')],
                attribute[1][locale],
                attribute[0],
              ]);
            }
          });
      })
    );
    dispatch(receiveAttributes(flds));
  };
}

function fetchAssets(assetLinks, locale) {
  return async (dispatch) => {
    dispatch(requestAssets());
    // dispatch(requestAssetsLinkTypes());
    let assetLinksTypesMapping = {};
    let assetLinksTypes = {};
    await Promise.all(
      assetLinks.map(async (assetLink, index) => {
        await axios
          .get(
            process.env.REACT_APP_API_URL +
              '/link-types/' +
              assetLink.linkType.id,
            {}
          )
          .then((response) => {
            if (Object.keys.assetLinksTypesMapping === undefined) {
              assetLinksTypesMapping[assetLink.linkType.id] =
                response.data.name[locale.replace('_', '-')];
              assetLinksTypes[assetLink.linkType.id] = [];
            } else {
              if (
                !Object.keys(assetLinksTypesMapping).includes(
                  response.data.name[locale.replace('_', '-')]
                )
              ) {
                assetLinksTypesMapping[assetLink.linkType.id] =
                  response.data.name[locale.replace('_', '-')];
                assetLinksTypes[assetLink.linkType.id] = [];
              }
            }
          })
          .catch((err) => {});
      })
    );
    dispatch(setAssetsLinkTypesMapping(assetLinksTypesMapping));
    let assetInfo = [];
    await Promise.all(
      assetLinks.map(async (assetLink, index) => {
        await axios
          .get(
            process.env.REACT_APP_API_URL +
              '/assets/' +
              assetLink['target']['id'],
            {}
          )
          .then((asset) => {
            for (let i = 0; i < asset.data.documentLinks.length; i++) {
              if (
                assetLinksTypes[asset.data.documentLinks[i].linkType.id] !==
                undefined
              ) {
                assetLinksTypes[asset.data.documentLinks[i].linkType.id].push({
                  ...asset.data,
                  id: asset.data['id'],
                  slide: <img className='vm-image' src={asset.data['url']} />,
                  thumbnail: (
                    <img className='vm-thumbnail' src={asset.data['url']} />
                  ),
                  dateCreated: asset.data['dateCreated'].split('T')[0],
                  dateModified: asset.data['dateModified'].split('T')[0],
                  name: asset.data['name'],
                  url: asset.data['url'],
                });
              }
            }
          })
          .catch((err) => {
            dispatch(requestAssetFailed(assetLink['target']['id'], err));
          });
      })
    );
    dispatch(receiveAssets(assetLinksTypes));
  };
}

function fetchVariants(variants, canalId, locale) {
  return async (dispatch) => {
    dispatch(requestVariants());
    let vnts = [];
    let mapping = [];
    console.log(canalId);
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          '/attributes?catalogs.id=' +
          canalId +
          '&documentTypeId=Sku',
        {}
      )
      .then(async (response) => {
        console.log(response.data['hydra:member']);
        response.data['hydra:member'].map((attribute) =>
          mapping.push([attribute.id, attribute.name[locale.replace('_', '-')]])
        );
        console.log(mapping);
        await Promise.all(
          variants.map(async (variant, index) => {
            await axios
              .get(
                process.env.REACT_APP_API_URL + '/variants/' + variant.id,
                {}
              )
              .then(async (response) => {
                if (response !== undefined) {
                  vnts.push(response.data.attributes);
                }
              })
              .catch((err) => {
                dispatch(requestVariantFailed(variant.id, err));
              });
          })
        ).then(() => {
          dispatch(receiveVariants(vnts, mapping));
        });
      });
  };
}

function audienceParameterToArray(object, array) {
  var a = Object.entries(object);
  if (typeof a[0][1] === 'string') {
    array.push(a[0][0]);
    return [array.join('.'), a[0][1]];
  } else {
    array.push(a[0][0]);
    return audienceParameterToArray(a[0][1], array);
  }
}

function fetchDocumentLinks(
  documentLinks,
  originId,
  classificationIds,
  locale,
  audience
) {
  let addFilterAudience = '';
  if (audience && audience.filters) {
    let audienceParameter = audienceParameterToArray(audience.filters, []);
    addFilterAudience = '&' + audienceParameter[0] + '=' + audienceParameter[1];
  }
  return async (dispatch) => {
    let classificationIdsCall =
      classificationIds !== undefined
        ? classificationIds.map((item) => '&classifications.id%5B%5D=' + item)
        : [];
    dispatch(requestDocumentLinks());
    let docLinks = {};
    let options = {};
    let optionsIds = [];
    await Promise.all(
      documentLinks.map(async (documentLink, index) => {
        if (optionsIds.includes(documentLink.linkType.id)) {
        } else {
          optionsIds.push(documentLink.linkType.id);
          await axios
            .get(
              process.env.REACT_APP_API_URL +
                '/link-types/' +
                documentLink.linkType.id,
              {}
            )
            .then(async (response) => {
              options[documentLink.linkType.id] =
                response.data.parent.documentTypeId === 'modele_vente'
                  ? 'Documents associés - ' + response.data.name['fr-FR']
                  : 'Documents parents - ' + response.data.name['fr-FR'];
              response.data.parent.documentTypeId === 'modele_vente'
                ? await axios
                    .get(
                      process.env.REACT_APP_API_URL +
                        '/documents?documentLinks.' +
                        documentLink.linkType.id +
                        '.origin.id=' +
                        originId +
                        classificationIdsCall.join('') +
                        addFilterAudience,
                      {}
                    )
                    .then(async (response) => {
                      docLinks[documentLink.linkType.id] = {
                        products: response.data['hydra:member'],
                        productsShortcut: response.data['hydra:member'],
                      };
                    })
                    .catch((err) => {
                      // dispatch(requestDocumentLinkFailed(variant.id, err));
                    })
                : await axios
                    .get(
                      process.env.REACT_APP_API_URL +
                        '/documents?documentLinks.' +
                        documentLink.linkType.id +
                        '.target.id=' +
                        originId +
                        classificationIdsCall.join('') +
                        addFilterAudience,
                      {}
                    )
                    .then(async (response) => {
                      docLinks[documentLink.linkType.id] = {
                        products: response.data['hydra:member'],
                        productsShortcut: response.data['hydra:member'],
                      };
                    })
                    .catch((err) => {
                      // dispatch(requestDocumentLinkFailed(variant.id, err));
                    });
            })
            .catch((err) => {});
        }
      })
    ).then(() => {
      dispatch(
        receiveDocumentLinks({
          documentLinks: docLinks,
          documentLinksNamesToCodesMapping: options,
        })
      );
    });
  };
}

export function fetchDocumentTypeInfo(locale) {
  return (dispatch) => {
    return axios
      .get(
        process.env.REACT_APP_API_URL + '/document-types?pagination=false',
        {}
      )
      .then((response) => {
        return response.data['hydra:member'].map((documentType) => {
          if (documentType.objectType === 'document') {
            axios
              .get(
                process.env.REACT_APP_API_URL +
                  '/document-types/' +
                  documentType.id +
                  '/attributes?catalogs.id=pub',
                {}
              )
              .then((response) => {
                dispatch(requestDocumentTypeInfo(documentType.id));
                dispatch(
                  setMapping(
                    documentType.id,
                    response.data['hydra:member'],
                    locale
                  )
                );
                dispatch(
                  receiveDocumentTypeInfo(
                    documentType.id,
                    response.data['hydra:member']
                  )
                );
              })
              .catch((err) => {});
          }
        });
      })
      .catch((err) => {});
  };
}

function requestDocumentTypeInfo(data) {
  return {
    type: REQUEST_DOCUMENT_TYPE_INFO,
    payload: data,
    addedAt: Date.now(),
  };
}

function receiveDocumentTypeInfo(id, data) {
  return {
    type: RECEIVE_DOCUMENT_TYPE_INFO,
    payload: { id: id, data: data },
    addedAt: Date.now(),
  };
}

function addDocumentTypeInfoAttributeToMapping(id, key, value) {
  return {
    type: ADD_DOCUMENT_TYPE_INFO_ATTRIBUTE_TO_MAPPING,
    payload: { id: id, data: [key, value] },
    addedAt: Date.now(),
  };
}

function setMapping(id, data, locale) {
  return (dispatch) => {
    data.map((item, index) => {
      if (item.type !== undefined) {
        // dispatch(requestDocumentTypeInfo());
        axios
          .get(process.env.REACT_APP_API_URL + '/attributes/' + item.id, {})
          .then((response) => {
            dispatch(
              addDocumentTypeInfoAttributeToMapping(
                id,
                item.id,
                response.data.name[locale.replace('_', '-')]
              )
            );
          })
          .catch((err) => {});
      } else {
        dispatch(setMapping(id, item.children, locale));
      }
    });
  };
}
