/**
 * Created by mnassih on 29/09/2020.
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/components/Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import {
  faSearch,
  faStar,
  faBars,
  faPowerOff,
  faShoppingBasket,
  faCog,
} from '@fortawesome/free-solid-svg-icons';

const ParameterLink = (props) => {
  return props.quableUser.role == 'admin' ? (
    <Link className='header-parametres' to='/parametres'>
      <FontAwesomeIcon className='header-parametres-icon' icon={faCog} />
      <p className='header-parametres-p'>
        <Translate value='header.settings' />
      </p>
    </Link>
  ) : (
    <div></div>
  );
};

function mapStateToProps(state) {
  return {
    quableUser: state.adminReducer.quableUser,
  };
}
export default connect(mapStateToProps)(ParameterLink);
