import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faCheckCircle,
  faCaretDown,
  faCaretRight,
  faDownload,
  faPaperPlane,
  faShoppingBasket,
  faSync,
} from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { Link } from 'react-router-dom';
import '../styles/components/ProductsBlocView.css';
import PropTypes from 'prop-types';
import {
  addItemToWishlist,
  removeItemFromWishlist,
} from '../redux/actions/userActions';
import { addToCart, removeFromCart } from '../redux/actions/cartActions';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { Translate } from 'react-redux-i18n';
import Downloader from 'js-file-downloader';
import arrowDown from '../images/icons/iconfinder_caret-down_383170.png';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import '../styles/Toast.css';

const ProductsBlocView = (props) => {
  var selected = (
    <div className='pbv-selected'>
      <FontAwesomeIcon
        className='blocCheckIcon'
        icon={faCheckCircle}
      ></FontAwesomeIcon>
    </div>
  );

  var notSelected = <div></div>;
  const [selectedItems, setSelectedItems] = useState([]);
  const [vignetteWidth, setVignetteWidth] = useState(window.outerWidth / 11);

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();

  const [productsInCart, setProductsInCart] = useState([]);

  var getUrl = window.location;
  var baseUrl = getUrl.protocol + '//' + getUrl.host + '/';

  var I18n = require('react-redux-i18n').I18n;

  const addNotify = () => toast(I18n.t('toast.addProductMediasToCart'));

  const noAssetsNotify = () => toast(I18n.t('toast.productNoMedias'));

  const removeNotify = () => toast(I18n.t('toast.removeProductMediasFromCart'));

  const addMediaNotify = () => toast(I18n.t('toast.addMediaToCart'));

  const removeMediaNotify = () => toast(I18n.t('toast.removeMediaFromCart'));

  useEffect(() => {
    setSelectedItems(props.selectedItems);
    if (
      props.whereFrom === 'RelatedMedia' &&
      props.selectedItems.length === 0
    ) {
      let newSelectedItems = {};
      Object.keys(props.tests).map((key, index) => {
        newSelectedItems[key] = [];
      });
      setSelectedItems(newSelectedItems);
      props.setSelectedItems(newSelectedItems);
    }
    // window.addEventListener('resize', updateVignetteWidth);
  }, [props.selectedItems]);

  useEffect(() => {
    if (props.whereFrom === 'RelatedMedia') {
      let options = [];
      Object.keys(props.tests).map((test, index) => {
        options.push({
          value: index,
          label: props.mapping[test],
        });
      });
      setOptions(options);
      setSelectedOption(props.selectedCategory);
    }
  }, [props.tests]);

  useEffect(() => {
    updateProductsInCart();
  }, []);

  async function updateProductsInCart() {
    if (
      props.whereFrom === 'Produits' ||
      props.whereFrom === 'Favoris' ||
      props.whereFrom === 'HProductsMAJ' ||
      props.whereFrom === 'DocumentLinks'
    ) {
      let newProductsInCart = [];
      await Promise.all(
        props.tests.map(async (test, index) => {
          let assetsContained = 0;
          let totalAssets;
          await axios
            .get(process.env.REACT_APP_API_URL + '/documents/' + test['id'], {})
            .then((response) => {
              totalAssets = response.data.assetLinks.length;
              response.data.assetLinks.map(async (assetLink, index) => {
                props.cart.items
                  .map((item, index) => item['id'])
                  .includes(assetLink['target']['id'])
                  ? assetsContained++
                  : (assetsContained = assetsContained);
              });
              if (assetsContained === totalAssets && totalAssets > 0) {
                newProductsInCart.push(test['id']);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
      );
      setProductsInCart(newProductsInCart);
    }
  }

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      marginLeft: 20,
      marginRight: 20,
      maxWidth: 500,
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'rgb(32, 32, 32)',
      border: 'none',
      boxShadow: 'none',
      // backgroundColor: 'transparent',
      // border: state.isFocused ? 0 : 0,
      // boxShadow: state.isFocused ? 0 : 0,
      // '&:hover': {
      //   border: state.isFocused ? 0 : 0,
      // },
      // color: 'white',
      // cursor: 'text',
      // whiteSpace: 'nowrap',
    }),
    input: (provided, state) => ({
      ...provided,
      // display: 'none',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      // fontSize: 40,
      // backgroundColor: 'transparent',
      // color: 'white',
      // paddingLeft: 68,
      // fontFamily: 'OpenSans-Bold',
    }),
    singleValue: (provided, state) => ({ ...provided, color: 'white' }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      // display: 'none',
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      // position: 'absolute',
      // textAlign: 'center',
      // right: 50,
      // color: !state.isFocused ? 'transparent' : 'grey',
      // '&:hover': {
      //   color: '#aaaaaa',
      // },
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      color: 'white',
      // '&:hover': {
      //   opacity: state.isFocused ? 0.5 : 0.5,
      // },
      // display: 'flex',
      // flexDirection: 'row',
      // justifyContent: 'center',
      // alignItems: 'center',
      // width: 50,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: 'white',
      '&:hover': {
        opacity: state.isFocused ? 0.9 : 0.9,
      },
      // color: 'transparent',
      // width: '100%',
      // height: '100%',
      // background: 'transparent url(' + arrowDown + ') no-repeat center center',
      // backgroundSize: 'contain',
      // cursor: 'pointer',
    }),
    menu: (provided) => ({
      ...provided,
      // backgroundColor: 'white',
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      // paddingLeft: 68,
      // backgroundColor: isFocused ? 'rgba(0,0,0,0.16)' : null,
      // color: 'black',
      // cursor: 'pointer',
    }),
  };

  function handleChange(selectedOption) {
    setSelectedOption(selectedOption.value);
    let newSelectedItems = {};
    Object.keys(props.tests).map((key, index) => {
      newSelectedItems[key] = [];
    });
    setSelectedItems(newSelectedItems);
    props.setSelectedItems(newSelectedItems);
    props.setSelectedCategory(selectedOption.value, 'bloc');
  }

  function updateVignetteWidth() {
    let outerWidth = window.outerWidth;
    if (outerWidth > 1200) {
      setVignetteWidth(window.outerWidth / 12);
    } else if (outerWidth > 800) {
      setVignetteWidth(window.outerWidth / 5);
    } else {
      setVignetteWidth(window.outerWidth / 2);
    }
  }

  function handleSelectionClick(index) {
    if (!selectedItems.includes(index)) {
      setSelectedItems([...selectedItems, index]);
      props.setSelectedItems([...selectedItems, index]);
    } else {
      let si = selectedItems;
      si.splice(si.indexOf(index), 1);
      setSelectedItems([...si]);
      props.setSelectedItems([...si]);
    }
  }

  function handleSelectionClickFromRelatedMedia(linkType, index) {
    let newSelectedItems = { ...selectedItems };
    if (!newSelectedItems[linkType].includes(index)) {
      newSelectedItems[linkType] = [...newSelectedItems[linkType], index];
      setSelectedItems({ ...newSelectedItems });
      props.setSelectedItems({ ...newSelectedItems });
    } else {
      let si = newSelectedItems[linkType];
      si.splice(si.indexOf(index), 1);
      newSelectedItems[linkType] = [...si];
      setSelectedItems({ ...newSelectedItems });
      props.setSelectedItems({ ...newSelectedItems });
    }
  }

  function handleStarClick(e, media) {
    e.preventDefault();
    e.stopPropagation();
    if (props.wishlist.map((item) => item['id']).includes(media['id'])) {
      props.dispatch(
        removeItemFromWishlist(
          props.wishlist.map((item) => item['id']).indexOf(media['id'])
        )
      );
    } else {
      props.dispatch(addItemToWishlist(media));
    }
  }

  function handleClick() {}

  function downloadMedia(e, url) {
    e.preventDefault();
    e.stopPropagation();
    new Downloader({
      url: url,
    })
      .then(function () {})
      .catch(function (error) {});
  }

  function handleMediaCartClick(e, media) {
    e.preventDefault();
    e.stopPropagation();
    if (props.cart.items.map((item) => item['id']).includes(media['id'])) {
      props.dispatch(removeFromCart(media['id']));
      if (props.whereFrom !== 'Cart') {
        removeMediaNotify();
      }
    } else {
      props.dispatch(addToCart(media));
      addMediaNotify();
    }
  }

  function handleProduitCartClick(e, id) {
    e.preventDefault();
    e.stopPropagation();
    if (!productsInCart.includes(id)) {
      let newProductsInCart = [...productsInCart];
      axios
        .get(process.env.REACT_APP_API_URL + '/documents/' + id, {})
        .then((response) => {
          if (response.data.assetLinks.length > 0) {
            response.data.assetLinks.map(async (assetLink, index) => {
              axios
                .get(
                  process.env.REACT_APP_API_URL +
                    '/assets/' +
                    assetLink['target']['id'],
                  {}
                )
                .then((response) => {
                  props.dispatch(addToCart(response.data));
                })
                .catch((err) => {
                  console.log(err);
                });
            });
            addNotify();
            newProductsInCart.push(id);
            setProductsInCart(newProductsInCart);
          } else {
            noAssetsNotify();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let newProductsInCart = [...productsInCart];
      newProductsInCart.splice(newProductsInCart.indexOf(id), 1);
      setProductsInCart(newProductsInCart);
      axios
        .get(process.env.REACT_APP_API_URL + '/documents/' + id, {})
        .then((response) => {
          response.data.assetLinks.map(async (assetLink, index) => {
            axios
              .get(
                process.env.REACT_APP_API_URL +
                  '/assets/' +
                  assetLink['target']['id'],
                {}
              )
              .then((response) => {
                props.dispatch(removeFromCart(assetLink['target']['id']));
              })
              .catch((err) => {
                console.log(err);
              });
          });
        })
        .catch((err) => {
          console.log(err);
        });
      removeNotify();
    }
  }

  function handleSendClick(e) {
    // e.preventDefault();
    e.stopPropagation();
  }

  if (props.whereFrom === 'HProductsMAJ' || props.whereFrom === 'HMediasMAJ') {
    return (
      <div className='pbv-hroot-container'>
        <ToastContainer hideProgressBar />
        {props.tests.map((test, index) => {
          return (
            <Fade key={index}>
              <Link
                to={
                  props.whereFrom === 'HMediasMAJ' &&
                  (test['@id'] !== undefined || test['extension'] !== undefined)
                    ? {
                        pathname: '/mediapage',
                        query: 'Favoris',
                        mediaIndex: 0,
                        media: test,
                      }
                    : {
                        pathname: '/product',
                        search: '?' + test['id'],
                      }
                }
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  // width: vignetteWidth,
                }}
              >
                <div
                  className='pbv-button-subcontainer'
                  // style={{ width: vignetteWidth }}
                >
                  <div
                    style={
                      props.whereFrom === 'HMediasMAJ'
                        ? {
                            backgroundImage:
                              'url(' + test['thumbnailUrl'] + ')',
                          }
                        : props.whereFrom === 'HProductsMAJ' &&
                          test['mainAssetUrl'] !== null
                        ? {
                            backgroundImage:
                              'url(' + test['mainAssetUrl'] + ')',
                          }
                        : {
                            backgroundImage:
                              'url(' + window.$visuel_defaut + ')',
                          }
                    }
                    className='pbv-button'
                  >
                    <div className='pbv-raccourcis-container'>
                      <FontAwesomeIcon
                        className={
                          (props.whereFrom === 'HProductsMAJ' &&
                            productsInCart.includes(test['id'])) ||
                          (props.whereFrom === 'HMediasMAJ' &&
                            props.cart.items
                              .map((item) => item['id'])
                              .includes(test['id']))
                            ? 'pbv-star'
                            : 'pbv-starv2'
                        }
                        icon={faShoppingBasket}
                        onClick={(e) =>
                          props.whereFrom === 'HProductsMAJ'
                            ? handleProduitCartClick(e, test['id'])
                            : handleMediaCartClick(e, test)
                        }
                      />
                      <Tooltip
                        // className='pbv-favoris-tooltip'
                        title={<Translate value='tooltips.favourite' />}
                        arrow
                        placement='top'
                      >
                        <div>
                          <FontAwesomeIcon
                            className={
                              props.wishlist
                                .map((item) => item['id'])
                                .includes(test['id'])
                                ? 'pbv-star'
                                : 'pbv-starv2'
                            }
                            icon={faStar}
                            onClick={(e) => handleStarClick(e, test)}
                          />
                        </div>
                      </Tooltip>
                      <a
                        href={
                          'mailto:?subject=' +
                          props.admin.quableUser.givenName +
                          ' ' +
                          props.admin.quableUser.familyName +
                          ' vous envoie le document ' +
                          (props.whereFrom === 'HProductsMAJ'
                            ? test.attributes[
                                test.documentType.id + '_name'
                              ] !== undefined
                              ? test.attributes[test.documentType.id + '_name'][
                                  props.locale
                                ]
                              : test['id']
                            : test['name']) +
                          '&body=' +
                          baseUrl +
                          'product?' +
                          test['id']
                        }
                      >
                        <FontAwesomeIcon
                          className='pbv-starv2'
                          icon={faPaperPlane}
                          onClick={(e) => handleSendClick(e)}
                        ></FontAwesomeIcon>
                      </a>
                      <FontAwesomeIcon
                        style={
                          props.whereFrom === 'HMediasMAJ'
                            ? {}
                            : { display: 'none' }
                        }
                        className='pbv-starv2'
                        icon={faDownload}
                        onClick={(e) => downloadMedia(e, test['url'])}
                      />
                    </div>
                  </div>
                  <p
                    className='pbv-media-name'
                    style={{ color: props.bloctxtcolor, fontSize: 10 }}
                  >
                    {props.whereFrom === 'HProductsMAJ'
                      ? test.attributes[test.documentType.id + '_name'] !==
                        undefined
                        ? test.attributes[test.documentType.id + '_name'][
                            props.locale
                          ]
                        : ''
                      : test['name']}
                  </p>
                  <p className='pbv-subtitle'>{test['id']}</p>
                </div>
              </Link>
            </Fade>
          );
        })}
      </div>
    );
  } else if (props.whereFrom === 'Homepage') {
    return (
      <div className='pbv-hroot-container'>
        {props.tests.map((test, index) => {
          let extension;
          if (test['thumbnailUrl'] !== undefined) {
            extension = test['thumbnailUrl']
              .substring(0, test['thumbnailUrl'].lastIndexOf('.'))
              .substring(
                test['thumbnailUrl'].lastIndexOf('-') + 1,
                test['thumbnailUrl'].length
              );
          }
          return (
            <Fade key={index}>
              <Link
                to={
                  test.documentType === undefined
                    ? {
                        pathname: '/mediapage',
                        query: 'Favoris',
                        mediaIndex: 0,
                        media: test,
                      }
                    : {
                        pathname: '/product',
                        search: '?' + test['id'],
                      }
                }
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  // width: vignetteWidth,
                }}
              >
                <div
                  className='pbv-hbutton-subcontainer'
                  // style={{ width: vignetteWidth }}
                >
                  <div
                    style={
                      test.documentType === undefined
                        ? extension === 'jpg' ||
                          extension === 'png' ||
                          extension === 'jpeg'
                          ? {
                              backgroundImage:
                                'url(' + test['thumbnailUrl'] + ')',
                            }
                          : {
                              backgroundImage:
                                'url(' + window.$visuel_defaut + ')',
                            }
                        : test['mainAssetUrl'] !== null
                        ? {
                            backgroundImage:
                              'url(' + test['mainAssetUrl'] + ')',
                          }
                        : {
                            backgroundImage:
                              'url(' + window.$visuel_defaut + ')',
                          }
                    }
                    className='pbv-hbutton'
                  >
                    <Tooltip
                      title={<Translate value='tooltips.favourite' />}
                      arrow
                      placement='top'
                    >
                      <div>
                        <FontAwesomeIcon
                          className={
                            props.wishlist
                              .map((item) => item['id'])
                              .includes(test['id'])
                              ? 'pbv-hstar'
                              : 'pbv-hstarv2'
                          }
                          icon={faStar}
                          onClick={(e) => handleStarClick(e, test, test['id'])}
                        />
                      </div>
                    </Tooltip>
                  </div>
                  <p
                    className='pbv-hmedia-name'
                    style={{ color: props.bloctxtcolor, fontSize: 10 }}
                  >
                    {test.documentType !== undefined
                      ? test.attributes[test.documentType.id + '_name'][
                          props.locale
                        ]
                      : test['name']}
                  </p>
                  <p className='pbv-hsubtitle'>{test['id']}</p>
                </div>
              </Link>
            </Fade>
          );
        })}
      </div>
    );
  } else if (
    props.whereFrom === 'Favoris' ||
    props.whereFrom === 'Produits' ||
    props.whereFrom == 'ProductsMAJ'
  ) {
    return (
      <div
        style={
          props.whereFrom === 'Favoris' ? { justifyContent: 'flex-start' } : {}
        }
        className='pbv-root-container'
      >
        <ToastContainer hideProgressBar />
        {props.tests.map((test, index) => {
          let extension;
          if (test['thumbnailUrl'] !== undefined) {
            extension = test['thumbnailUrl']
              .substring(0, test['thumbnailUrl'].lastIndexOf('.'))
              .substring(
                test['thumbnailUrl'].lastIndexOf('-') + 1,
                test['thumbnailUrl'].length
              );
          }
          return (
            <Fade key={index}>
              <Link
                to={
                  props.whereFrom === 'Favoris' &&
                  test.documentType === undefined
                    ? {
                        pathname: '/mediapage',
                        query: 'Favoris',
                        mediaIndex: 0,
                        media: test,
                      }
                    : {
                        pathname: '/product',
                        search: '?' + test['id'],
                      }
                }
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  // width: vignetteWidth,
                }}
              >
                <div
                  className='pbv-button-subcontainer'
                  // style={{ width: vignetteWidth }}
                >
                  <div
                    style={
                      props.whereFrom === 'Favoris'
                        ? test.documentType === undefined
                          ? extension === 'jpg' ||
                            extension === 'png' ||
                            extension === 'jpeg'
                            ? {
                                backgroundImage:
                                  'url(' + test['thumbnailUrl'] + ')',
                              }
                            : {
                                backgroundImage:
                                  'url(' + window.$visuel_defaut + ')',
                              }
                          : test['mainAssetUrl'] !== null &&
                            test['mainAssetUrl'] !== undefined
                          ? {
                              backgroundImage:
                                'url(' + test['mainAssetUrl'] + ')',
                            }
                          : {
                              backgroundImage:
                                'url(' + window.$visuel_defaut + ')',
                            }
                        : test['mainAssetUrl'] !== null &&
                          test['mainAssetUrl'] !== undefined
                        ? {
                            backgroundImage:
                              'url(' + test['mainAssetUrl'] + ')',
                          }
                        : {
                            backgroundImage:
                              'url(' + window.$visuel_defaut + ')',
                          }
                    }
                    className='pbv-button'
                  >
                    <div className='pbv-raccourcis-container'>
                      <FontAwesomeIcon
                        className={
                          (props.documentType !== undefined &&
                            productsInCart.includes(test['id'])) ||
                          (props.documentType === undefined &&
                            props.cart.items
                              .map((item) => item['id'])
                              .includes(test['id']))
                            ? 'pbv-star'
                            : 'pbv-starv2'
                        }
                        icon={faShoppingBasket}
                        onClick={(e) =>
                          props.documentType !== undefined
                            ? handleProduitCartClick(e, test['id'])
                            : handleMediaCartClick(e, test)
                        }
                      />
                      <Tooltip
                        // className='pbv-favoris-tooltip'
                        title={<Translate value='tooltips.favourite' />}
                        arrow
                        placement='top'
                      >
                        <div>
                          <FontAwesomeIcon
                            className={
                              props.wishlist
                                .map((item) => item['id'])
                                .includes(test['id'])
                                ? 'pbv-star'
                                : 'pbv-starv2'
                            }
                            icon={faStar}
                            onClick={(e) => handleStarClick(e, test)}
                          />
                        </div>
                      </Tooltip>
                      <a
                        href={
                          'mailto:?subject=' +
                          props.admin.quableUser.givenName +
                          ' ' +
                          props.admin.quableUser.familyName +
                          ' vous envoie le document ' +
                          (test.documentType !== undefined &&
                          test.attributes[test.documentType.id + '_name'] !==
                            undefined
                            ? test.attributes[test.documentType.id + '_name'][
                                props.locale
                              ]
                            : test['name']) +
                          '&body=' +
                          baseUrl +
                          'product?' +
                          test['id']
                        }
                      >
                        <FontAwesomeIcon
                          className='pbv-starv2'
                          icon={faPaperPlane}
                          onClick={(e) => handleSendClick(e)}
                        ></FontAwesomeIcon>
                      </a>
                      <FontAwesomeIcon
                        style={
                          props.whereFrom === 'Favoris' &&
                          test.documentType === undefined
                            ? {}
                            : { display: 'none' }
                        }
                        className='pbv-starv2'
                        icon={faDownload}
                        onClick={(e) => downloadMedia(e, test['url'])}
                      />
                    </div>
                  </div>
                  <p
                    className='pbv-media-name'
                    style={{ color: props.bloctxtcolor }}
                  >
                    {test.documentType !== undefined &&
                    test.attributes[test.documentType.id + '_name'] !==
                      undefined
                      ? test.attributes[test.documentType.id + '_name'][
                          props.locale
                        ]
                      : test['name']}
                  </p>
                  <p className='pbv-subtitle'>{test['id']}</p>
                </div>
              </Link>
            </Fade>
          );
        })}
      </div>
    );
  } else if (props.whereFrom === 'Medias' || props.whereFrom == 'MediasMAJ') {
    return (
      <div className='pbv-root-container'>
        {props.tests.map((test, index) => {
          let extension = test['thumbnailUrl']
            .substring(0, test['thumbnailUrl'].lastIndexOf('.'))
            .substring(
              test['thumbnailUrl'].lastIndexOf('-') + 1,
              test['thumbnailUrl'].length
            );
          return (
            <Fade key={index}>
              <Link
                onClick={(e) =>
                  props.selectionMode ? e.preventDefault() : null
                }
                to={{
                  pathname: '/mediapage',
                  query: props.whereFrom === 'Medias' ? 'Medias' : 'MediasMAJ',
                  mediaIndex: index,
                }}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <div
                  className='pbv-button-subcontainer'
                  onClick={
                    props.selectionMode
                      ? () => handleSelectionClick(index)
                      : handleClick()
                  }
                >
                  <div
                    style={
                      test['thumbnailUrl'] !== undefined &&
                      test['thumbnailUrl'] !== '' &&
                      (extension === 'jpg' ||
                        extension === 'png' ||
                        extension === 'jpeg')
                        ? {
                            backgroundImage:
                              'url(' + test['thumbnailUrl'] + ')',
                          }
                        : {
                            backgroundImage:
                              'url(' + window.$visuel_defaut + ')',
                          }
                    }
                    className='pbv-button'
                  >
                    <div
                      className='pbv-raccourcis-container'
                      style={
                        props.selectionMode && selectedItems.includes(index)
                          ? { display: 'none' }
                          : {}
                      }
                    >
                      <FontAwesomeIcon
                        className={
                          props.cart.items
                            .map((item) => item['id'])
                            .includes(test['id'])
                            ? 'pbv-star'
                            : 'pbv-starv2'
                        }
                        icon={faShoppingBasket}
                        onClick={(e) => handleMediaCartClick(e, test)}
                      />
                      <Tooltip
                        title={<Translate value='tooltips.favourite' />}
                        arrow
                        placement='top'
                      >
                        <div>
                          <FontAwesomeIcon
                            className={
                              props.wishlist
                                .map((item) => item['id'])
                                .includes(test['id'])
                                ? 'pbv-star'
                                : 'pbv-starv2'
                            }
                            icon={faStar}
                            onClick={(e) => handleStarClick(e, test)}
                          />
                        </div>
                      </Tooltip>
                      <a
                        href={
                          'mailto:?subject=' +
                          props.admin.quableUser.givenName +
                          ' ' +
                          props.admin.quableUser.familyName +
                          ' vous envoie le document ' +
                          test['name'] +
                          '&body=' +
                          baseUrl +
                          'product?' +
                          test['id']
                        }
                      >
                        <FontAwesomeIcon
                          className='pbv-starv2'
                          icon={faPaperPlane}
                          onClick={(e) => handleSendClick(e)}
                        ></FontAwesomeIcon>
                      </a>
                      <FontAwesomeIcon
                        className='pbv-starv2'
                        icon={faDownload}
                        onClick={(e) => downloadMedia(e, test['url'])}
                      />
                    </div>
                    {props.selectionMode && selectedItems.includes(index)
                      ? selected
                      : notSelected}
                  </div>
                  <p
                    className='pbv-media-name'
                    style={{ color: props.bloctxtcolor }}
                  >
                    {test['name']}
                  </p>
                  <div className='pbv-info-container'>
                    <FontAwesomeIcon style={{ marginRight: 5 }} icon={faFile} />
                    <p style={{ marginRight: 10 }}>
                      {test['dateCreated'].split('T')[0]}
                    </p>
                    <FontAwesomeIcon style={{ marginRight: 5 }} icon={faSync} />
                    <p style={{ marginRight: 10 }}>
                      {test['dateModified'].split('T')[0]}
                    </p>
                  </div>
                </div>
              </Link>
            </Fade>
          );
        })}
      </div>
    );
  } else if (
    props.whereFrom === 'RelatedMedia' &&
    selectedOption !== undefined &&
    options.length > 0
  ) {
    let mergedMedias = Object.values(props.tests);
    mergedMedias = [].concat
      .apply([], mergedMedias)
      .map((media) => media['id']);
    return (
      <div className='pbv-root-container-rm'>
        <div className='pbv-select-container'>
          <Select
            styles={customStyles}
            value={options[selectedOption]}
            onChange={handleChange}
            options={options}
            // onCreateOption={handleCreate}
            // isClearable
          />
        </div>
        <div className='pbv-linktype-container'>
          <div className='pbv-button-container-rm'>
            {Object.entries(props.tests)[selectedOption][1].map(
              (media, index) => {
                let extension = media['thumbnailUrl']
                  .substring(0, media['thumbnailUrl'].lastIndexOf('.'))
                  .substring(
                    media['thumbnailUrl'].lastIndexOf('-') + 1,
                    media['thumbnailUrl'].length
                  );
                return (
                  <Fade key={index}>
                    <Link
                      onClick={(e) =>
                        props.selectionMode ? e.preventDefault() : null
                      }
                      to={{
                        pathname: '/mediapage',
                        query: 'RelatedMedia',
                        mediaIndex: mergedMedias.indexOf(media['id']),
                      }}
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      <div className='pbv-button-subcontainer-rm'>
                        <div
                          onClick={
                            props.selectionMode
                              ? () =>
                                  handleSelectionClickFromRelatedMedia(
                                    Object.entries(props.tests)[
                                      selectedOption
                                    ][0],
                                    index
                                  )
                              : handleClick()
                          }
                          style={
                            media['thumbnailUrl'] !== undefined &&
                            media['thumbnailUrl'] !== '' &&
                            (extension === 'jpg' ||
                              extension === 'png' ||
                              extension === 'jpeg')
                              ? {
                                  backgroundImage:
                                    'url(' + media['thumbnailUrl'] + ')',
                                }
                              : {
                                  backgroundImage:
                                    'url(' + window.$visuel_defaut + ')',
                                }
                          }
                          className={
                            Object.keys(selectedItems).length !== 0 &&
                            selectedItems[
                              Object.entries(props.tests)[selectedOption][0]
                            ].includes(index)
                              ? ['pbv-btn-selected', 'pbv-button'].join(' ')
                              : 'pbv-button'
                          }
                        >
                          <div
                            className='pbv-raccourcis-container'
                            style={
                              props.selectionMode &&
                              selectedItems[
                                Object.entries(props.tests)[selectedOption][0]
                              ].includes(index)
                                ? { display: 'none' }
                                : {}
                            }
                          >
                            <FontAwesomeIcon
                              className={
                                props.cart.items
                                  .map((item) => item['id'])
                                  .includes(media['id'])
                                  ? 'pbv-star'
                                  : 'pbv-starv2'
                              }
                              icon={faShoppingBasket}
                              onClick={(e) => handleMediaCartClick(e, media)}
                            />
                            <Tooltip
                              title={<Translate value='tooltips.favourite' />}
                              arrow
                              placement='top'
                            >
                              <div>
                                <FontAwesomeIcon
                                  className={
                                    props.wishlist
                                      .map((item) => item['id'])
                                      .includes(media['id'])
                                      ? 'pbv-star'
                                      : 'pbv-starv2'
                                  }
                                  icon={faStar}
                                  onClick={(e) => handleStarClick(e, media)}
                                />
                              </div>
                            </Tooltip>
                            <a
                              href={
                                'mailto:?subject=' +
                                props.admin.quableUser.givenName +
                                ' ' +
                                props.admin.quableUser.familyName +
                                ' vous envoie le document ' +
                                media['name'] +
                                '&body=' +
                                baseUrl +
                                'product?' +
                                media['id']
                              }
                            >
                              <FontAwesomeIcon
                                className='pbv-starv2'
                                icon={faPaperPlane}
                                onClick={(e) => handleSendClick(e)}
                              ></FontAwesomeIcon>
                            </a>
                            <FontAwesomeIcon
                              className='pbv-starv2'
                              icon={faDownload}
                              onClick={(e) => downloadMedia(e, media['url'])}
                            />
                          </div>
                          {props.selectionMode &&
                          Object.keys(selectedItems).length !== 0 &&
                          selectedItems[
                            Object.entries(props.tests)[selectedOption][0]
                          ].includes(index)
                            ? selected
                            : notSelected}
                        </div>
                        <p
                          className='pbv-media-name'
                          style={{
                            color: props.bloctxtcolor,
                          }}
                        >
                          {media['name']}
                        </p>
                        <div
                          className='pbv-info-container'
                          style={{ color: 'white' }}
                        >
                          <FontAwesomeIcon
                            style={{ marginRight: 5 }}
                            icon={faFile}
                          />
                          <p style={{ marginRight: 10 }}>
                            {media['dateCreated'].split('T')[0]}
                          </p>
                          <FontAwesomeIcon
                            style={{ marginRight: 5 }}
                            icon={faSync}
                          />
                          <p style={{ marginRight: 10 }}>
                            {media['dateModified'].split('T')[0]}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </Fade>
                );
              }
            )}
          </div>
        </div>
      </div>
    );
  } else if (props.whereFrom === 'Cart') {
    return (
      <div
        style={{ justifyContent: 'flex-start' }}
        className='pbv-root-container'
      >
        {props.tests.map((test, index) => {
          let extension = test['thumbnailUrl']
            .substring(0, test['thumbnailUrl'].lastIndexOf('.'))
            .substring(
              test['thumbnailUrl'].lastIndexOf('-') + 1,
              test['thumbnailUrl'].length
            );

          return (
            <Fade key={index}>
              <Link
                to={{
                  pathname: '/mediapage',
                  query: 'Cart',
                  mediaIndex: index,
                }}
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  // width: vignetteWidth,
                }}
              >
                <div
                  className='pbv-button-subcontainer'
                  // style={{ width: vignetteWidth }}
                >
                  <div
                    style={
                      test['thumbnailUrl'] !== undefined &&
                      test['thumbnailUrl'] !== '' &&
                      (extension === 'jpg' ||
                        extension === 'png' ||
                        extension === 'jpeg')
                        ? {
                            backgroundImage:
                              'url(' + test['thumbnailUrl'] + ')',
                          }
                        : {
                            backgroundImage:
                              'url(' + window.$visuel_defaut + ')',
                          }
                    }
                    className='pbv-button'
                  >
                    <div className='pbv-raccourcis-container'>
                      <FontAwesomeIcon
                        className={
                          props.cart.items
                            .map((item) => item['id'])
                            .includes(test['id'])
                            ? 'pbv-star'
                            : 'pbv-starv2'
                        }
                        icon={faShoppingBasket}
                        onClick={(e) => handleMediaCartClick(e, test)}
                      />
                      <Tooltip
                        title={<Translate value='tooltips.favourite' />}
                        arrow
                        placement='top'
                      >
                        <div>
                          <FontAwesomeIcon
                            className={
                              props.wishlist
                                .map((item) => item['id'])
                                .includes(test['id'])
                                ? 'pbv-star'
                                : 'pbv-starv2'
                            }
                            icon={faStar}
                            onClick={(e) => handleStarClick(e, test)}
                          />
                        </div>
                      </Tooltip>
                      <a
                        href={
                          'mailto:?subject=' +
                          props.admin.quableUser.givenName +
                          ' ' +
                          props.admin.quableUser.familyName +
                          ' vous envoie le document ' +
                          test['name'] +
                          '&body=' +
                          baseUrl +
                          'product?' +
                          test['id']
                        }
                      >
                        <FontAwesomeIcon
                          className='pbv-starv2'
                          icon={faPaperPlane}
                          onClick={(e) => handleSendClick(e)}
                        ></FontAwesomeIcon>
                      </a>
                      <FontAwesomeIcon
                        className='pbv-starv2'
                        icon={faDownload}
                        onClick={(e) => downloadMedia(e, test['url'])}
                      />
                    </div>
                  </div>
                  <p
                    className='pbv-media-name'
                    style={{ color: props.bloctxtcolor }}
                  >
                    {test['name']}
                  </p>
                  <p className='pbv-subtitle'>{test['id']}</p>
                </div>
              </Link>
            </Fade>
          );
        })}
      </div>
    );
  } else if (props.whereFrom === 'RelatedMedia') {
    return <div className='pbv-root-container-rm'></div>;
  } else if (props.whereFrom === 'DocumentLinks') {
    return (
      <div
        style={{ justifyContent: 'flex-start' }}
        className='pbv-root-container'
      >
        {props.tests.map((test, index) => {
          return (
            <Fade key={index}>
              <Link
                to={{
                  pathname: '/product',
                  search: '?' + test['id'],
                }}
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  // width: vignetteWidth,
                }}
              >
                <div
                  className='pbv-button-subcontainer'
                  // style={{ width: vignetteWidth }}
                >
                  <div
                    style={
                      test['mainAssetUrl'] !== null &&
                      test['mainAssetUrl'] !== undefined
                        ? {
                            backgroundImage:
                              'url(' + test['mainAssetUrl'] + ')',
                          }
                        : {
                            backgroundImage:
                              'url(' + window.$visuel_defaut + ')',
                          }
                    }
                    className='pbv-button'
                  >
                    <div
                      className='pbv-raccourcis-container'
                      style={
                        props.selectionMode &&
                        selectedItems[
                          Object.entries(props.tests)[selectedOption][0]
                        ].includes(index)
                          ? { display: 'none' }
                          : {}
                      }
                    >
                      <FontAwesomeIcon
                        className={
                          productsInCart.includes(test['id'])
                            ? 'pbv-star'
                            : 'pbv-starv2'
                        }
                        icon={faShoppingBasket}
                        onClick={(e) => handleProduitCartClick(e, test['id'])}
                      />
                      <Tooltip
                        title={<Translate value='tooltips.favourite' />}
                        arrow
                        placement='top'
                      >
                        <div>
                          <FontAwesomeIcon
                            className={
                              props.wishlist
                                .map((item) => item['id'])
                                .includes(test['id'])
                                ? 'pbv-star'
                                : 'pbv-starv2'
                            }
                            icon={faStar}
                            onClick={(e) => handleStarClick(e, test)}
                          />
                        </div>
                      </Tooltip>
                      <a
                        href={
                          'mailto:?subject=' +
                          props.admin.quableUser.givenName +
                          ' ' +
                          props.admin.quableUser.familyName +
                          ' vous envoie le document ' +
                          test['name'] +
                          '&body=' +
                          baseUrl +
                          'product?' +
                          test['id']
                        }
                      >
                        <FontAwesomeIcon
                          className='pbv-starv2'
                          icon={faPaperPlane}
                          onClick={(e) => handleSendClick(e)}
                        ></FontAwesomeIcon>
                      </a>
                    </div>
                  </div>
                  <p
                    className='pbv-media-name'
                    style={{ color: props.bloctxtcolor }}
                  >
                    {
                      test.attributes[test.documentType.id + '_name'][
                        props.locale
                      ]
                    }
                  </p>
                  {props.attributes.map((attribute) => {
                    return Object.entries(test.attributes).map((tattr) => {
                      if (attribute.id === tattr[0] && attribute.isPrimary) {
                        console.log(
                          attribute.name[props.locale.replace('_', '-')]
                        );
                        return (
                          <p className='pbv-subtitle'>
                            {attribute.name[props.locale.replace('_', '-')] +
                              ': ' +
                              (test.attributes[attribute.id][props.locale] !==
                              undefined
                                ? test.attributes[attribute.id][props.locale]
                                : test.attributes[attribute.id])}
                          </p>
                        );
                      }
                    });
                  })}
                </div>
              </Link>
            </Fade>
          );
        })}
      </div>
    );
  }
};

ProductsBlocView.propTypes = {
  whereFrom: PropTypes.string.isRequired,
  tests: PropTypes.array.isRequired,
  bloctxtcolor: PropTypes.string,
  star: PropTypes.bool.isRequired,
  selectionItems: PropTypes.array,
  selectionMode: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    wishlist: state.user.wishlist,
    mapping: state.product.assetsLinkTypesMapping,
    cart: state.cart,
    admin: state.adminReducer,
    locale: state.i18n.locale,
    attributes: state.adminReducer.attributes,
  };
}

export default connect(mapStateToProps)(ProductsBlocView);
