import React, { useState, useEffect } from 'react';
import { Header, ProductsListView, SearchFilters } from '../components';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import '../styles/pages/Search.css';
import { fetchSearchResults } from '../redux/actions/searchActions';
import Footer from '../components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'react-redux-i18n';
import Loader from 'react-loader-spinner';

const Search = (props) => {
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [viewProducts, setViewProducts] = useState(false);
  const [viewMedias, setViewMedias] = useState(false);

  const [search, setSearch] = useState();

  var I18n = require('react-redux-i18n').I18n;

  useEffect(() => {
    if (document.getElementById('search-input-id') !== null) {
      document.getElementById('search-input-id').value = props.search_input;
    }
  }, [props.nbproducts_results, props.nbmedias_results, props.search_input]);

  useEffect(() => {
    props.dispatch(
      fetchSearchResults(
        props.search_input,
        undefined,
        undefined,
        undefined,
        props.classificationIds.map((item) => item['value']),
        props.mediasClassficationIds.map((item) => item['value']),
        activePageNumber,
        props.locale,
        props.audience
      )
    );
    setViewProducts(false);
    setViewMedias(false);
  }, [props.locale, props.search_input]);

  function handleChange(pageNumber) {
    if (activePageNumber !== pageNumber) {
      setActivePageNumber(pageNumber);
    }
  }

  function handleViewProductsClick() {
    setViewProducts(true);
    window.scrollTo(0, 0);
  }

  function handleViewMediasClick() {
    setViewMedias(true);
    window.scrollTo(0, 0);
  }

  function handleReturnClick() {
    props.dispatch(
      fetchSearchResults(
        props.search_input,
        undefined,
        undefined,
        undefined,
        props.classificationIds.map((item) => item['value']),
        props.mediasClassficationIds.map((item) => item['value']),
        1,
        props.locale,
        props.audience
      )
    );
    setActivePageNumber(1);
    setViewProducts(false);
    setViewMedias(false);
  }

  function handleSearchSubmit(e) {
    e.preventDefault();
    props.dispatch(
      fetchSearchResults(
        search,
        undefined,
        undefined,
        undefined,
        props.classificationIds.map((item) => item['value']),
        props.mediasClassficationIds.map((item) => item['value']),
        1,
        props.locale,
        props.audience
      )
    );
  }

  function handleSearchInputChange(e) {
    setSearch(e.target.value);
  }

  function setPageNumberFromChild(pageNumber) {
    setActivePageNumber(pageNumber);
  }

  if (props.search_input !== null) {
    if (
      (props.isFetchingProducts || props.isFetchingMedias) &&
      viewProducts === false &&
      viewMedias === false
    ) {
      return (
        <div>
          <Header></Header>
          <div className='search-loader'>
            <Loader type='TailSpin' color='black' height={100} width={100} />
          </div>
        </div>
      );
    } else {
      if (viewProducts || viewMedias) {
        return (
          <div>
            <Header></Header>
            <div className='search-input-container'>
              <div className='search-input-subcontainer'>
                <form onSubmit={() => handleSearchSubmit()}>
                  <input
                    id='search-input-id'
                    className='search-input'
                    type='text'
                    placeholder={I18n.t('search.placeholder')}
                    onChange={() => handleSearchInputChange()}
                  ></input>
                </form>
              </div>
            </div>
            <div className='search-container'>
              <div className='search-subcontainer'>
                <div className='search-heading-container'>
                  <div
                    className='search-retour'
                    onClick={() => handleReturnClick()}
                  >
                    <FontAwesomeIcon className='icon' icon={faArrowLeft} />
                    <Translate
                      className='search-retour-txt'
                      value='subheader.goback'
                    />
                  </div>
                  <h1 className='search-heading'>Nous avons trouvé</h1>
                  <div className='search-divider'></div>
                  <h1 className='search-heading-b'>
                    {props.nbproducts_results} produits,
                  </h1>
                  <div className='search-divider'></div>
                  <h1 className='search-heading-b'>
                    {props.nbmedias_results} médias
                  </h1>
                  <div className='search-divider'></div>
                  <h1 className='search-heading'>
                    pour "{props.search_input}"
                  </h1>
                </div>
                <SearchFilters
                  type={viewProducts ? 'Products' : 'Medias'}
                  pageNumber={activePageNumber}
                  setPageNumberFromChild={setPageNumberFromChild}
                />
                <div
                  style={
                    viewProducts
                      ? { height: 450, display: 'block' }
                      : { display: 'none' }
                  }
                >
                  <p className='search-type-heading'>
                    PRODUITS ({props.nbproducts_results})
                  </p>
                  <ProductsListView
                    whereFrom='Search'
                    whereTo='ProductPage'
                    tests={props.products}
                  />
                </div>
                <div
                  style={
                    viewMedias
                      ? { height: 450, display: 'block' }
                      : { display: 'none' }
                  }
                >
                  <p className='search-type-heading'>
                    MEDIAS ({props.nbmedias_results})
                  </p>
                  <ProductsListView
                    whereFrom='Search'
                    whereTo='MediaPage'
                    tests={props.medias}
                  />
                </div>
                <Pagination
                  activePage={activePageNumber}
                  itemsCountPerPage={6}
                  totalItemsCount={
                    viewProducts
                      ? props.nbproducts_results
                      : props.nbmedias_results
                  }
                  pageRangeDisplayed={5}
                  onChange={handleChange}
                  prevPageText={I18n.t('pagination.previous')}
                  nextPageText={I18n.t('pagination.next')}
                  innerClass='search-pagination-innerClass'
                  activeClass='search-pagination-activeClass'
                  activeLinkClass='search-pagination-activeLinkClass'
                  itemClass='search-pagination-itemClass'
                  itemClassFirst='search-pagination-itemClassFirst'
                  itemClassPrev='search-pagination-itemClassPrev'
                  itemClassNext='search-pagination-itemClassNext'
                  itemClassLast='search-pagination-itemClassLast'
                  linkClass='search-pagination-linkClass'
                  linkClassFirst='search-pagination-linkClassFirst'
                  linkClassPrev='search-pagination-linkClassPrev'
                  linkClassNext='search-pagination-linkClassNext'
                  linkClassLast='search-pagination-linkClassLast'
                  disabledClass='search-pagination-disabledClass'
                />
              </div>
            </div>
            <Footer></Footer>
          </div>
        );
      } else if (
        props.nbproducts_results !== null &&
        props.nbmedias_results !== null
      ) {
        return (
          <div>
            <Header></Header>
            <div className='search-input-container'>
              <div className='search-input-subcontainer'>
                <form onSubmit={handleSearchSubmit}>
                  <input
                    id='search-input-id'
                    className='search-input'
                    type='text'
                    placeholder={I18n.t('search.placeholder')}
                    onChange={handleSearchInputChange}
                  ></input>
                </form>
              </div>
            </div>
            <div className='search-container'>
              <div className='search-subcontainer'>
                <div className='search-heading-container'>
                  <h1 className='search-heading'>Nous avons trouvé</h1>
                  <div className='search-divider'></div>
                  <h1 className='search-heading-b'>
                    {props.nbproducts_results} produits,
                  </h1>
                  <div className='search-divider'></div>
                  <h1 className='search-heading-b'>
                    {props.nbmedias_results} médias
                  </h1>
                  <div className='search-divider'></div>
                  <h1 className='search-heading'>
                    pour "{props.search_input}"
                  </h1>
                </div>
                <div
                  style={
                    props.nbproducts_results > 0
                      ? { display: 'block' }
                      : { display: 'none' }
                  }
                >
                  <p className='search-type-heading'>
                    PRODUITS ({props.nbproducts_results})
                  </p>
                  <ProductsListView
                    whereFrom='Search'
                    whereTo='ProductPage'
                    tests={props.products}
                  />
                  <div className='search-btn-container'>
                    <button
                      className='search-btn'
                      onClick={() => handleViewProductsClick()}
                    >
                      Tous les résultats
                    </button>
                  </div>
                </div>
                <div
                  style={
                    props.nbmedias_results > 0
                      ? { display: 'block' }
                      : { display: 'none' }
                  }
                >
                  <p className='search-type-heading'>
                    MEDIAS ({props.nbmedias_results})
                  </p>
                  <ProductsListView
                    whereFrom='Search'
                    whereTo='MediaPage'
                    tests={props.medias}
                  />
                  <div className='search-btn-container'>
                    <button
                      className='search-btn'
                      onClick={() => handleViewMediasClick()}
                    >
                      Tous les résultats
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Footer></Footer>
          </div>
        );
      }
    }
  } else {
    return (
      <div>
        <Header></Header>
        <div className='search-input-container'>
          <div className='search-input-subcontainer'>
            <form onSubmit={handleSearchSubmit}>
              <input
                id='search-input-id'
                className='search-input'
                type='text'
                placeholder={I18n.t('search.placeholder')}
                onChange={handleSearchInputChange}
              ></input>
            </form>
          </div>
        </div>
        <p style={{ textAlign: 'center', marginTop: 50 }}>
          Pas de recherche effectuée
        </p>
      </div>
    );
  }
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    search_input: state.search.search_input,
    nbproducts_results: state.search.products.nbresults,
    products: state.search.products.data,
    nbmedias_results: state.search.medias.nbresults,
    medias: state.search.medias.data,
    isFetchingProducts: state.search.products.isFetching,
    isFetchingMedias: state.search.medias.isFetching,
    audience: state.adminReducer.useraudience,
    classificationIds: state.canal.classificationIds,
    mediasClassficationIds: state.classification_medias.classificationIds,
  };
}

export default connect(mapStateToProps)(Search);
