/**
 * Created by mnassih on 25/09/2020.
 */
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Page not found (404) componenet
 */
const AccessDenied = () => {
    return (
        <div className="not-found" style={notFoundStyle}>
            <h1>Votre compte ne vous permet d'accéder au portail </h1>


        </div>
    )
}

const notFoundStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '50vw',
    alignItems: 'center'
}

export default AccessDenied
