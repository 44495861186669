import React, { useState, useEffect } from 'react';
import '../../styles/components/ProductPage/HorizontalScrollInfo.css';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';

const HorizontalScrollInfo = (props) => {
  return (
    <div
      className='hsi-root'
      style={props.variants.length === 0 ? { display: 'none' } : {}}
    >
      <div className='hsi-title-container'>
        <h5 className='hsi-skus'>SKUS</h5>
      </div>
      <div className='hsi-subroot'>
        <table className='hsi-table'>
          <thead className='hsi-head'>
            <tr className='hsi-header'>
              {props.variantsNames.map((vnts_name, index) => {
                return (
                  <th key={vnts_name[1]} className='hsi-hitem'>
                    {vnts_name[1]}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className='hsi-body'>
            {props.variants.map((variant, index) => (
              <tr
                key={'variant' + index}
                className={'hsi-row' + ((index % 2) + 1)}
              >
                {props.variantsNames.map((vnts_name, i) => {
                  if (
                    vnts_name[0] === 'sku_poids' ||
                    vnts_name[0] === 'sku_volume'
                  ) {
                    return (
                      <td key={vnts_name[0] + i} className='hsi-item'>
                        {props.variants[index][vnts_name[0]]}
                      </td>
                    );
                  } else if (vnts_name[0] === 'sku_statut') {
                    return (
                      <td key={vnts_name[0] + i} className='hsi-item'>
                        {
                          props.variants[index][vnts_name[0]][0].labels[
                            props.locale
                          ]
                        }
                      </td>
                    );
                  } else {
                    if (vnts_name[0] in variant) {
                      return (
                        <td key={vnts_name[0] + i} className='hsi-item'>
                          {props.variants[index][vnts_name[0]][props.locale]}
                        </td>
                      );
                    } else {
                      return (
                        <td key={vnts_name[0] + i} className='hsi-item'>
                          None
                        </td>
                      );
                    }
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    variants: state.product.variantsData.variants,
    variantsNames: state.product.variantsData.variantsNamesToCodesMapping,
  };
}

export default connect(mapStateToProps)(HorizontalScrollInfo);
