import {
  REQUEST_PRODUCTS_MAJ,
  REQUEST_MEDIAS_MAJ,
  RECEIVE_PRODUCTS_MAJ,
  RECEIVE_MEDIAS_MAJ,
} from './types';
import axios from 'axios';

export const requestProductsMAJ = () => {
  return {
    type: REQUEST_PRODUCTS_MAJ,
  };
};

export const receiveProductsMAJ = (data, length) => {
  return {
    type: RECEIVE_PRODUCTS_MAJ,
    payload: { data: data, length: length },
  };
};

export const requestMediasMAJ = () => {
  return {
    type: REQUEST_MEDIAS_MAJ,
  };
};

export const receiveMediasMAJ = (data, length) => {
  return {
    type: RECEIVE_MEDIAS_MAJ,
    payload: { data: data, length: length },
  };
};

function audienceParameterToArray(object, array) {
  var a = Object.entries(object);
  if (typeof a[0][1] === 'string') {
    array.push(a[0][0]);
    return [array.join('.'), a[0][1]];
  } else {
    array.push(a[0][0]);
    return audienceParameterToArray(a[0][1], array);
  }
}

export function fetchDocumentsMAJ(
  classificationIds,
  mediasClassificationIds,
  page,
  limit,
  audience
) {
  let addFilterAudience = '';
  if (audience && audience.filters) {
    let audienceParameter = audienceParameterToArray(audience.filters, []);
    addFilterAudience = '&' + audienceParameter[0] + '=' + audienceParameter[1];
  }
  return (dispatch) => {
    dispatch(
      fetchProductsMAJ(classificationIds, page, limit, addFilterAudience)
    );
    dispatch(
      fetchMediasMAJ(mediasClassificationIds, page, limit, addFilterAudience)
    );
  };
}

export function fetchProductsMAJ(
  classificationIds,
  page,
  limit,
  filterAudience
) {
  return (dispatch) => {
    classificationIds =
      classificationIds.length > 137
        ? classificationIds.slice(0, 137)
        : classificationIds;
    let classificationIdsCall =
      classificationIds !== undefined
        ? classificationIds.map((item) => '&classifications.id%5B%5D=' + item)
        : [];
    dispatch(requestProductsMAJ());
    return axios
      .get(
        process.env.REACT_APP_API_URL +
          '/documents?order%5BdateModified%5D=DESC&page=' +
          page +
          '&limit=' +
          limit +
          classificationIdsCall.join('') +
          filterAudience,
        {}
      )
      .then((response) => {
        let sortedProductsMAJ = response.data['hydra:member'].sort(
          (a, b) => new Date(b.dateModified) - new Date(a.dateModified)
        );
        dispatch(
          receiveProductsMAJ(
            response.data['hydra:member'],
            response.data['hydra:totalItems']
          )
        );
      })
      .catch((err) => {});
  };
}

export function fetchMediasMAJ(
  mediasClassificationIds,
  page,
  limit,
  filterAudience
) {
  return (dispatch) => {
    mediasClassificationIds =
      mediasClassificationIds.length > 137
        ? mediasClassificationIds.slice(0, 137)
        : mediasClassificationIds;
    let mediasClassificationIdsCall =
      mediasClassificationIds !== undefined
        ? mediasClassificationIds.map(
            (item) => '&classifications.id%5B%5D=' + item
          )
        : [];
    dispatch(requestMediasMAJ());
    return axios
      .get(
        process.env.REACT_APP_API_URL +
          '/assets?order%5BdateModified%5D=DESC&page=' +
          page +
          '&limit=' +
          limit +
          mediasClassificationIdsCall.join('') +
          filterAudience,
        {}
      )
      .then((response) => {
        let sortedMediasMAJ = response.data['hydra:member'].sort(
          (a, b) => new Date(b.dateModified) - new Date(a.dateModified)
        );
        dispatch(
          receiveMediasMAJ(
            response.data['hydra:member'],
            response.data['hydra:totalItems']
          )
        );
      })
      .catch((err) => {});
  };
}
