/**
 * Created by mnassih on 05/11/2020.
 */
import React from 'react';
import {
  faUsers,
  faUserPlus,
  faPlusCircle,
  faProjectDiagram,
  faEdit,
  faBan,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import '../../styles/components/Parameters/ParametersMenu.css';

const ParamMenu = (props) => {
  function openModal() {
    props.openInCreateMode();
  }

  return (
    <div className='parametres-leftmenu'>
      <h1>
        <Translate value='settings.menuHeading1' />
      </h1>
      <Link className='parameters-menuelement' to='/Parametres'>
        <div className='parametres-leftmenu-element'>
          <FontAwesomeIcon className='parametres-icon' icon={faUsers} />
          <p>
            <Translate value='settings.listUsers' />
          </p>
        </div>
      </Link>
      <h1>
        <Translate value='settings.manageProfiles' />
      </h1>
      <Link className='parameters-menuelement' to='/profils'>
        <div className='parametres-leftmenu-element'>
          <FontAwesomeIcon
            className='parametres-icon'
            icon={faProjectDiagram}
          />
          <p style={{ whiteSpace: 'nowrap' }}>
            <Translate value='settings.listProfiles' />
          </p>
        </div>
      </Link>
      <a className='parameters-menuelement' onClick={() => openModal()}>
        <div className='parametres-leftmenu-element'>
          <FontAwesomeIcon className='parametres-icon' icon={faPlusCircle} />
          <p>
            <Translate value='settings.createProfile' />
          </p>
        </div>
      </a>
      <h1>
        <Translate value='settings.menuHeading3' />
      </h1>
      <Link className='parameters-menuelement' to='/attributes'>
        <div className='parametres-leftmenu-element'>
          <FontAwesomeIcon
            className='parametres-icon'
            icon={faProjectDiagram}
          />
          <p>
            <Translate value='settings.listAttributes' />
          </p>
        </div>
      </Link>
      <a
        href={props.prismicConnection.adminUrl}
        target='_blanc'
        className='parametres-prismic'
      >
        <Translate value='settings.menuHeading4' />
      </a>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userstatus: state.adminReducer.quableuserinstance.status,
    gotQuableInfo: state.adminReducer.gotQuableInfo,
    prismicConnection: state.adminReducer.instancedata.prismicParameters
  };
}

export default connect(mapStateToProps)(ParamMenu);
