import {
    SET_USER_CREDENTIALS,
    ADD_ITEM_TO_WISHLIST,
    REMOVE_ITEM_FROM_WISHLIST,
    STORE_USER_INSTANCE
} from '../actions/types';

const initialState = {
    username: '',
    password: '',
    wishlist:(localStorage.getItem('userwishlits'))?JSON.parse(localStorage.getItem('userwishlits')): [],
    userinstance: ''
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_CREDENTIALS:
            return {
                ...state,
                username: action.payload.username,
                password: action.payload.password,
            };
        case ADD_ITEM_TO_WISHLIST:
            localStorage.setItem('userwishlits',JSON.stringify([...state.wishlist, action.payload]));
            return {
                username: state.username,
                password: state.password,
                wishlist: [...state.wishlist, action.payload],
            };
        case REMOVE_ITEM_FROM_WISHLIST:
            let newWishlist = state.wishlist.filter(
                (item, index) => index !== action.payload
            );
            localStorage.setItem('userwishlits',JSON.stringify(newWishlist));
            return {
                username: state.username,
                password: state.password,
                wishlist: newWishlist,
            };
        case STORE_USER_INSTANCE:
            return {
                ...state,
                userinstance: action.payload
            };
        default:
            return state;
    }
};
