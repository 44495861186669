import {
  REQUEST_PRODUCTS_MAJ,
  RECEIVE_PRODUCTS_MAJ,
  REQUEST_MEDIAS_MAJ,
  RECEIVE_MEDIAS_MAJ,
} from '../actions/types';

const initialState = {
  productsMAJ: {
    status: null,
    error: null,
    isFetching: false,
    nbresults: null,
    data: [],
  },
  mediasMAJ: {
    status: null,
    error: null,
    isFetching: false,
    nbresults: null,
    data: [],
  },
};

export const homepageReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PRODUCTS_MAJ:
      return {
        ...state,
        productsMAJ: {
          ...state.productsMAJ,
          isFetching: true,
        },
      };
    case RECEIVE_PRODUCTS_MAJ:
      return {
        ...state,
        productsMAJ: {
          ...state.productsMAJ,
          isFetching: false,
          nbresults: action.payload.length,
          data: action.payload.data,
        },
      };
    case REQUEST_MEDIAS_MAJ:
      return {
        ...state,
        mediasMAJ: {
          ...state.mediasMAJ,
          isFetching: true,
        },
      };
    case RECEIVE_MEDIAS_MAJ:
      return {
        ...state,
        mediasMAJ: {
          ...state.mediasMAJ,
          isFetching: false,
          nbresults: action.payload.length,
          data: action.payload.data,
        },
      };
    default:
      return state;
  }
};
