import React, { useEffect, useState } from 'react';
import {
  Header,
  ListPathButtons,
  SubHeader,
  MaterialUITreeView,
  TreeViewSelectedPath,
  ItemsContainer,
} from '../components';
import { connect } from 'react-redux';
import {
  fetchMedias,
  updateMediasPath,
  updateMediasVisiblePathItems,
  updateMediasCurrentPathItems,
  initializeItemsContainerAction,
  resetCurrentPathAssets,
  resetMedias,
  setMediasTreeViewExpanded,
  setCurrentAssetsClassificationId,
} from '../redux/actions/mediasActions';
import '../styles/pages/Medias.css';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import Footer from '../components/Footer';
import history from '../history';
import { Translate } from 'react-redux-i18n';

const Medias = (props) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentButtonsLength, setCurrentButtonsLength] = useState();
  const [nbPage, setNbPage] = useState(1);
  const [nbValues, setNbValues] = useState('20');
  const [selectedTreeViewElement, setSelectedTreeViewElement] = useState();
  const [historyLocationSearch, setHistoryLocationSearch] = useState(
    props.location.search
  );
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    setFirstRender(false);
    setNbPage(1);
    function start() {
      let path = ['medias'];
      if (historyLocationSearch.length > 0) {
        path = path.concat(
          historyLocationSearch
            .replace('?', '')
            .split('+')
            .map((item) => decodeURIComponent(item))
        );
      } else {
        path = ['medias'];
      }

      let cpi = [props.currentPathItems[0]];
      path.map((pathitem, parentIndex) => {
        if (parentIndex < 2) {
          cpi[cpi.length - 1][1].map((item, index) => {
            return item[0] === pathitem
              ? cpi.push([
                  cpi[cpi.length - 1][1][index][0],
                  [cpi[cpi.length - 1][1][index][2]],
                  cpi[cpi.length - 1][1][index][3],
                  cpi[cpi.length - 1][1][index][6],
                ])
              : {};
          });
        } else {
          cpi[cpi.length - 1][1][0].map((item, index) => {
            return item[0] === pathitem
              ? cpi.push([
                  cpi[cpi.length - 1][1][0][index][0],
                  [cpi[cpi.length - 1][1][0][index][2]],
                  cpi[cpi.length - 1][1][0][index][3],
                  cpi[cpi.length - 1][1][0][index][6],
                ])
              : {};
          });
        }
      });

      // console.log(path);
      props.dispatch(updateMediasPath(path));
      // console.log(cpi);
      props.dispatch(updateMediasCurrentPathItems(cpi));
      // console.log(cpi[cpi.length - 1][1][0]);
      props.dispatch(updateMediasVisiblePathItems(cpi[cpi.length - 1][1][0]));
      // console.log(cpi[cpi.length - 1][2]);
      props.dispatch(
        initializeItemsContainerAction(
          cpi[cpi.length - 1][2],
          nbPage,
          nbValues,
          props.locale,
          props.audience
        )
      );
      // console.log(cpi[cpi.length - 1][2]);
      props.dispatch(setCurrentAssetsClassificationId(cpi[cpi.length - 1][2]));
      // console.log(cpi[cpi.length - 1][1][0].length);
      setCurrentButtonsLength(cpi[cpi.length - 1][1][0].length);
      // console.log(cpi);
      let exp = cpi.slice(1).map((item) => item[3]);
      // console.log(exp);
      props.dispatch(setMediasTreeViewExpanded(exp));
    }

    if (historyLocationSearch.length > 0) {
      start();
    } else {
      props.dispatch(setCurrentAssetsClassificationId(null));
      setCurrentButtonsLength(props.currentPathItems[0][1].length);
      props.dispatch(updateMediasPath(['medias']));
      props.dispatch(updateMediasCurrentPathItems([props.currentPathItems[0]]));
      props.dispatch(
        updateMediasVisiblePathItems(props.currentPathItems[0][1])
      );
      props.dispatch(setMediasTreeViewExpanded([]));
    }
  }, [historyLocationSearch]);

  function updateHistory(path) {
    history.push({
      pathname: '/medias',
      search: '?' + path,
    });
    setHistoryLocationSearch(path);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    if (!firstRender) {
      props.dispatch(resetCurrentPathAssets());
      if (
        nbPage !== undefined &&
        nbValues !== undefined &&
        props.currentAssetsClassificationId !== null
      ) {
        // setCurrentButtonsLength(props.currentElts.length);
        props.dispatch(
          initializeItemsContainerAction(
            props.currentAssetsClassificationId,
            nbPage,
            nbValues,
            props.locale,
            props.audience
          )
        );
      }
    }
  }, [nbPage, nbValues]);

  function setSelectedItemsFromChild(newItems) {
    setSelectedItems(newItems);
  }

  function setExpandedFromChild(expanded) {
    props.dispatch(setMediasTreeViewExpanded(expanded));
  }

  async function downloadSelectedMedia() {
    var zip = new JSZip();
    await Promise.all(
      selectedItems.map(async (itemIndex) => {
        return new Promise((resolve) => {
          JSZipUtils.getBinaryContent(
            props.currentAssets[itemIndex].url,
            (err, data) =>
              resolve(
                zip.file(props.currentAssets[itemIndex].name, data, {
                  binary: true,
                })
              )
          );
        });
      })
    );
    zip
      .generateAsync({
        type: 'blob',
      })
      .then(function (content) {
        const element = document.createElement('a');
        element.setAttribute('href', window.URL.createObjectURL(content));
        element.setAttribute('download', 'medias.zip');
        element.click();
      });
  }

  function handleScroll() {
    let footer = document.getElementById('ic-footer');
    if (document.getElementById('ic-container-id') !== null) {
      let rmPositionY = document
        .getElementById('ic-container-id')
        .getBoundingClientRect().y;
      rmPositionY < 250 && document.getElementById('ic-check').checked
        ? footer.classList.add('ic-footerstyle')
        : footer.classList.remove('ic-footerstyle');
    }
  }

  function updateCurrentButtonsItemsFromPath(index, newCurrentButtonsItems) {
    let newCurrentPathItems = props.currentPathItems.slice(0, index + 1);

    setCurrentButtonsLength(newCurrentButtonsItems.length);
    props.dispatch(updateMediasVisiblePathItems(newCurrentButtonsItems));
    props.dispatch(updateMediasCurrentPathItems(newCurrentPathItems));

    if (newCurrentPathItems.length === 1) {
      props.dispatch(setCurrentAssetsClassificationId(null));
    } else {
      props.dispatch(
        setCurrentAssetsClassificationId(
          newCurrentPathItems[newCurrentPathItems.length - 1][2]
        )
      );
    }
  }

  function updatePath(from, newPath) {
    if (from == 'frompath') {
      props.dispatch(resetCurrentPathAssets());
      if (newPath.length === 1) {
        props.dispatch(updateMediasPath([]));
      } else {
        props.dispatch(updateMediasPath(newPath));
      }
    } else if (from == 'frombutton') {
      if (props.path.length === 0) {
        props.dispatch(updateMediasPath([...['Medias'], newPath]));
      } else {
        props.dispatch(updateMediasPath([...props.path, newPath]));
      }
    }
  }

  function setApiCallNumberOfPageAndNumberOfValues(nbPage, nbValues) {
    setNbPage(nbPage);
    setNbValues(nbValues);
  }

  return (
    <div id='medias-outercontainer' className='medias-outercontainer'>
      <Header />
      <SubHeader type='Main' mediasSelected={true} />
      <div className='medias-container'>
        <div className='medias-subcontainer'>
          <div className='medias-innerview'>
            <TreeViewSelectedPath
              path={props.path}
              updateHistory={updateHistory}
            />
          </div>
          <div className='medias-innerview'>
            <MaterialUITreeView
              // style={{ marginTop: 10, marginLeft: 50 }}
              whereFrom='Medias'
              items={props.treeItems}
              currentElts={props.currentElts}
              currentPathItems={props.currentPathItems}
              path={props.path}
              expanded={props.expanded}
              updateHistory={updateHistory}
            />
            <ListPathButtons
              whereFrom='Medias'
              style={
                currentButtonsLength === 0 || currentButtonsLength === undefined
                  ? { display: 'none' }
                  : {
                      display: 'flex',
                    }
              }
              currentElts={props.currentElts}
              path={props.path}
              updateHistory={updateHistory}
            />
            <ItemsContainer
              star={false}
              whereFrom='Medias'
              currentElements={props.currentAssets}
              currentButtonsLength={currentButtonsLength}
              handleScroll={handleScroll}
              setSelectedItems={setSelectedItemsFromChild}
              setApiCallNumberOfPageAndNumberOfValues={
                setApiCallNumberOfPageAndNumberOfValues
              }
              nbPage={nbPage}
              path={props.path}
              setExpandedFromChild={setExpandedFromChild}
              expanded={props.expanded}
              updatePath={updatePath}
              updateCurrentButtonsItemsFromPath={
                updateCurrentButtonsItemsFromPath
              }
              currentPathItems={props.currentPathItems}
              historyLocationSearch={historyLocationSearch}
              updateHistory={updateHistory}
            />
          </div>
          <div id='ic-footer' className='ic-footer-hide'>
            <p className='ic-footerstyle-p'>
              {selectedItems.length} éléments(s) sélectionné(s)
            </p>
            <button
              className='ic-footerstyle-b'
              onClick={() => downloadSelectedMedia()}
            >
              <Translate value='productPage.relatedMedia.download' />
            </button>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    treeItems: state.classification_medias.items,
    path: state.classification_medias.selectedPath,
    currentPathItems: state.classification_medias.currentPathItems,
    currentElts: state.classification_medias.visiblePathItems,
    currentAssets: state.classification_medias.currentAssets,
    currentAssetsClassificationId:
      state.classification_medias.currentAssetsClassificationId,
    expanded: state.classification_medias.expanded,
    audience: state.adminReducer.quableuserinstance.audience,
  };
}

export default connect(mapStateToProps)(Medias);
