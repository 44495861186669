import React, { useState, useEffect } from 'react';
import {
  Header,
  MultiMediaImageCarousel,
  BottomInfo,
  TreeViewSelectedPath,
} from '../components';
import '../styles/pages/MediaPage.css';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Downloader from 'js-file-downloader';
import Footer from '../components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import { addToCart } from '../redux/actions/cartActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShoppingBasket,
  faFile,
  faSync,
} from '@fortawesome/free-solid-svg-icons';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
import ReactAudioPlayer from 'react-audio-player';
import { Translate } from 'react-redux-i18n';

const MediaPage = (props) => {
  const [currentImageInfo, setCurrentImageInfo] = useState();
  const [productMedias, setProductMedias] = useState();

  var I18n = require('react-redux-i18n').I18n;

  const notify = () => toast(I18n.t('toast.addMediaToCart'));

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.location.query === 'RelatedMedia') {
      let pm = [];
      Object.values(props.productAssets).forEach(
        (productAssetsCategory, index) => {
          if (productAssetsCategory.length > 0) {
            productAssetsCategory.forEach((productAsset, index) => {
              pm.push(productAsset);
            });
          }
        }
      );
      setCurrentImageInfo(pm[props.location.mediaIndex]);
      setProductMedias(pm);
    } else if (props.location.query === 'Search') {
      let pm = [];
      Object.values(props.searchMedias).forEach((searchMedia, index) => {
        pm.push(searchMedia);
      });
      setCurrentImageInfo(pm[props.location.mediaIndex]);
      setProductMedias(pm);
    } else if (props.location.query === 'Cart') {
      let pm = [];
      props.cartItems.map((cartItem, index) => {
        pm.push(cartItem);
      });
      setCurrentImageInfo(pm[props.location.mediaIndex]);
      setProductMedias(pm);
    } else if (props.location.query === 'Favoris') {
      let pm = [];
      pm.push(props.location.media);
      setCurrentImageInfo(pm[0]);
      setProductMedias(pm);
    } else if (props.location.query === 'MediasMAJ') {
      let pm = [];
      props.mediasMAJ.map((media, index) => {
        pm.push(media);
      });
      setCurrentImageInfo(pm[props.location.mediaIndex]);
      setProductMedias(pm);
    } else {
      setCurrentImageInfo(props.currentAssets[props.location.mediaIndex]);
      setProductMedias(props.currentAssets);
    }
  }, []);

  const history = useHistory();

  function setCurrentImageInfoFromChild(info) {
    setCurrentImageInfo(info);
  }

  function sendMediaToCart() {
    let containsItem = props.cart.items.filter(
      (item) => item.name === currentImageInfo['name']
    );
    if (containsItem.length === 0) {
      props.dispatch(addToCart(currentImageInfo));
      notify();
    }
  }

  function downloadMedia() {
    new Downloader({
      url: currentImageInfo.url,
    })
      .then(function () {})
      .catch(function (error) {});
  }

  function updateHistory(path) {
    if (path.length > 0) {
      history.push({
        pathname: '/medias',
        search: '?' + path,
      });
    } else {
      history.push({
        pathname: '/medias',
      });
    }
  }

  let treeview =
    props.location.query !== 'RelatedMedia' &&
    props.location.query !== 'Search' &&
    props.location.query !== 'Cart' &&
    props.location.query !== 'Favoris' &&
    props.location.query !== 'MediasMAJ' &&
    currentImageInfo !== undefined ? (
      <div className='mediapage-path'>
        <TreeViewSelectedPath
          whereFrom='MediaPage'
          path={props.path}
          updateHistory={updateHistory}
        />
      </div>
    ) : (
      <div className='mediapage-path'>
        <TreeViewSelectedPath
          whereFrom='MediaPage'
          path={['medias']}
          updateHistory={updateHistory}
        />
      </div>
    );

  if (currentImageInfo !== undefined && productMedias !== undefined) {
    let extension = currentImageInfo['url'].substring(
      0,
      currentImageInfo['url'].lastIndexOf('.')
    );
    extension = extension.substring(
      extension.lastIndexOf('-') + 1,
      extension.length
    );
    if (
      extension !== 'jpg' &&
      extension !== 'png' &&
      extension !== 'jpeg' &&
      extension !== 'pdf' &&
      extension !== 'xlsx' &&
      extension !== 'pptx' &&
      extension !== 'mp4' &&
      extension !== 'mp3' &&
      extension !== 'wma' &&
      extension !== 'ogg'
    ) {
      extension = currentImageInfo['url'].substring(
        currentImageInfo['url'].lastIndexOf('.') + 1,
        currentImageInfo.length
      );
    }
    console.log(extension);
    console.log(productMedias);
    console.log(currentImageInfo);
    return (
      <div>
        <ToastContainer hideProgressBar />
        <Header />
        <div className='mediapage-container'>
          {treeview}
          <div className='mediapage-carouselcontainer'>
            <p className='mediapage-name'>{currentImageInfo['name']}</p>
            <div className='mediapage-fullinfo-container'>
              <div className='mediapage-info-container'>
                <FontAwesomeIcon style={{ marginRight: 5 }} icon={faFile} />
                <p style={{ marginRight: 10 }}>
                  {currentImageInfo['dateCreated'].split('T')[0]}
                </p>
                <FontAwesomeIcon style={{ marginRight: 5 }} icon={faSync} />
                <p style={{ marginRight: 10 }}>
                  {currentImageInfo['dateModified'].split('T')[0]}
                </p>
              </div>
              <p
                style={
                  currentImageInfo.attributes.media_droits !== undefined
                    ? {}
                    : { display: 'none' }
                }
              >
                {currentImageInfo.attributes.media_droits !== undefined
                  ? 'Droits : ' +
                    currentImageInfo.attributes.media_droits[props.locale]
                  : ''}
              </p>
            </div>
            <div className='mediapage-button-container'>
              <button className='mediapage-download-btn'>
                <a
                  className='mediapage-download-a'
                  onClick={() => downloadMedia()}
                >
                  <Translate value='productPage.relatedMedia.download' />
                </a>
              </button>
              <button
                style={
                  props.cart.items.filter(
                    (item) => item.name === currentImageInfo['name']
                  ).length > 0
                    ? { display: 'none' }
                    : {}
                }
                className='mediapage-add-btn'
                onClick={() => sendMediaToCart()}
              >
                <FontAwesomeIcon
                  className='mediapage-cart-icon'
                  icon={faShoppingBasket}
                />
                Ajouter au panier
              </button>
            </div>
            <div
              className='mediapage-mainimage'
              style={
                extension === 'jpg' ||
                extension === 'png' ||
                extension === 'jpeg'
                  ? {
                      backgroundImage: 'url(' + currentImageInfo['url'] + ')',
                    }
                  : { display: 'none' }
              }
            ></div>
            <div
              className='mediapage-mainimage'
              style={
                extension === 'xlsx' || extension === 'pptx'
                  ? {
                      backgroundImage: 'url(' + window.$visuel_defaut + ')',
                    }
                  : { display: 'none' }
              }
            ></div>
            <iframe
              sandbox
              style={extension === 'pdf' ? {} : { display: 'none' }}
              className='mediapage-mainimage'
              src={
                extension === 'pdf'
                  ? currentImageInfo['url'].replace('jpg', 'pdf')
                  : ''
              }
            ></iframe>
            <div
              className='mediapage-video'
              style={extension === 'mp4' ? {} : { display: 'none' }}
            >
              <Player src={currentImageInfo['url'].replace('jpg', 'mp4')} />
            </div>
            <div
              className='mediapage-video'
              style={
                extension === 'mp3' ||
                extension === 'wma' ||
                extension === 'ogg'
                  ? {}
                  : { display: 'none' }
              }
            >
              <ReactAudioPlayer
                src={currentImageInfo['url'].replace('jpg', 'mp3')}
                autoPlay
                controls
              />
            </div>
            <MultiMediaImageCarousel
              initialIndex={props.location.mediaIndex}
              currentAssets={productMedias}
              setCurrentImageInfo={setCurrentImageInfoFromChild}
            />
          </div>
          <BottomInfo currentImageInfo={currentImageInfo} />
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

function mapStateToProps(state) {
  return {
    searchMedias: state.search.medias.data,
    productAssets: state.product.assetsData.assets,
    currentAssets: state.classification_medias.currentAssets,
    cartItems: state.cart.items,
    path: state.classification_medias.selectedPath,
    currentPathItems: state.classification_medias.currentPathItems,
    expanded: state.classification_medias.expanded,
    cart: state.cart,
    mediasMAJ: state.homepageReducer.mediasMAJ.data,
    locale: state.i18n.locale,
  };
}

export default connect(mapStateToProps)(MediaPage);
