import React from 'react';
import '../../styles/components/MediaPage/BottomInfo.css';
import { connect } from 'react-redux';

const BottomInfo = (props) => {
  return (
    <div className='bi-container'>
      {Object.entries(props.currentImageInfo).map((assetAttribute, index) => {
        let title = assetAttribute[0];
        if (title === 'name') {
          title = 'Nom du fichier';
        } else if (title === 'active') {
          title = 'Actif';
        }
        if (
          assetAttribute[0] === 'name' ||
          assetAttribute[0] === 'id' ||
          assetAttribute[0] === 'active'
        ) {
          return (
            <div className='bi-elts-container'>
              <h4 className='bi-title'>{title}</h4>
              <p className='bi-content'>{assetAttribute[1].toString()}</p>
              {/* <p>{assetAttribute[1]}</p> */}
            </div>
          );
        } else if (assetAttribute[0] === 'attributes') {
          return Object.entries(assetAttribute[1]).map((attribute, index) => {
            if (props.mediasAttributesMapping[attribute[0]] !== undefined) {
              return (
                <div className='bi-elts-container'>
                  <h4 className='bi-title'>
                    {props.mediasAttributesMapping[attribute[0]]}
                  </h4>
                  {typeof attribute[1] === 'string' ? (
                    <p className='bi-content'>{attribute[1]}</p>
                  ) : Array.isArray(attribute[1]) ? (
                    <div>
                      {attribute[1].map((el) => (
                        <p className='bi-content'>{el.labels[props.locale]}</p>
                      ))}
                    </div>
                  ) : (
                    <p className='bi-content'>{attribute[1][props.locale]}</p>
                  )}

                  {/* <p>{assetAttribute[1]}</p> */}
                </div>
              );
            }
          });
        }
      })}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    mediasAttributesMapping:
      state.classification_medias.mediasAttributesMapping,
  };
}

export default connect(mapStateToProps)(BottomInfo);
