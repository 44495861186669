import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Nav, Row, Col } from 'react-bootstrap';
import '../../styles/components/ProductPage/AllProductInfo.css';
import { connect } from 'react-redux';
import { Markup } from 'interweave';

const AllProductInfo = (props) => {
  const [selected, setSelected] = useState([true, false]);
  const [subSelected, setSubSelected] = useState([]);
  const [info, setInfo] = useState([]);

  useEffect(() => {
    if (
      props.product.productData.product !== undefined &&
      Object.entries(
        props.documentTypeInfo[
          props.product.productData.product.data.documentType.id
        ]
      ).length > 0
    ) {
      let info = setArborescence(
        props.documentTypeInfo[
          props.product.productData.product.data.documentType.id
        ][0].children
      );
      console.log(info);
      info = info.filter(
        (el) =>
          el[1].filter(
            (e) =>
              e[1].filter(
                (ee) =>
                  ee[1].filter(
                    (eee) =>
                      props.product.productData.product.data.attributes[eee] !==
                      undefined
                  ).length > 0
              ).length > 0
          ).length > 0
      );
      setInfo(info);
    }
  }, [props.documentTypeInfo, props.product.productData.product]);

  function setArborescence(data) {
    if (data !== undefined) {
      return data.map((item, index) => {
        if (item.type !== undefined) {
          return [item.id];
        } else {
          return [item.name['fr-FR'], setArborescence(item.children)];
        }
      });
    } else {
      return [];
    }
  }

  function handleClick(index) {
    var links = [];
    for (var i = 0; i < selected.length; i++) {
      links.push(false);
    }
    links[index] = true;
    setSelected(links);
  }

  function handleSubClick(parentIndex, index) {
    // var links = [];
    // for (var i = 0; i < subSelected.length; i++) {
    //   links.push(false);
    // }
    // links[index] = true;
    let links = [...subSelected];
    links[parentIndex] = index;
    setSubSelected(links);
  }

  function resetSubSelected() {
    // setSubSelected([true, false]);
  }

  return (
    <div className='api-container'>
      <div className='api-subcontainer'>
        <div className='api-subsubcontainer'>
          <Tab.Container defaultActiveKey='thisgoes0'>
            <Col>
              <Row style={{ marginBottom: 10 }}>
                <Nav variant='pills red' className='flex-row'>
                  {info.map((item, index) => {
                    return (
                      <Nav.Item>
                        <Nav.Link
                          onClick={() => handleClick(index)}
                          style={
                            selected[index]
                              ? {
                                  backgroundColor: 'rgb(242, 246, 248)',
                                  fontWeight: 'bold',
                                  color: '#3e5a77',
                                  border: '1px solid #ebeef0',
                                }
                              : {}
                          }
                          className='api-navlink'
                          eventKey={'thisgoes' + index}
                        >
                          {item[0]}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </Nav>
              </Row>
              <Row>
                <Tab.Content>
                  {info.map((item, index) => {
                    if (item[1].length > 0) {
                      return (
                        <Tab.Pane eventKey={'thisgoes' + index}>
                          <Tabs
                            defaultActiveKey={item[1][0][0]}
                            transition={false}
                            id='noanim-tab-example'
                            className='api-tabs'
                            onClick={() => resetSubSelected()}
                          >
                            {item[1].map((ite, parentIndex) => {
                              if (
                                ite[1].filter((el) => el[1].length > 0).length >
                                0
                              ) {
                                return (
                                  <Tab eventKey={ite[0]} title={ite[0]}>
                                    <Tab.Container
                                      defaultActiveKey={ite[1][0][0]}
                                    >
                                      <Row style={{ marginTop: '1rem' }}>
                                        <Col className='api-col'>
                                          {ite[1].map((it, index) => {
                                            if (
                                              it[1].filter(
                                                (e) =>
                                                  props.product.productData
                                                    .product.data.attributes[
                                                    e
                                                  ] !== undefined
                                              ).length > 0
                                            ) {
                                              return (
                                                <Nav variant='pills'>
                                                  <Nav.Item>
                                                    <Nav.Link
                                                      onClick={() =>
                                                        handleSubClick(
                                                          parentIndex,
                                                          index
                                                        )
                                                      }
                                                      style={
                                                        subSelected[
                                                          parentIndex
                                                        ] === index ||
                                                        (index === 0 &&
                                                          subSelected[
                                                            parentIndex
                                                          ] === undefined)
                                                          ? {
                                                              backgroundColor:
                                                                'rgb(242, 246, 248)',
                                                              fontWeight:
                                                                'bold',
                                                              color: '#3e5a77',
                                                              border:
                                                                '1px solid #ebeef0',
                                                            }
                                                          : {}
                                                      }
                                                      className='api-navlink'
                                                      eventKey={it[0]}
                                                    >
                                                      {it[0]}
                                                    </Nav.Link>
                                                  </Nav.Item>
                                                </Nav>
                                              );
                                            }
                                          })}
                                        </Col>
                                        <Col>
                                          <Tab.Content className='api-fields-container'>
                                            {ite[1].map((it) => {
                                              // if (
                                              //   it[1].filter(
                                              //     (e) =>
                                              //       props.product.productData
                                              //         .product.data.attributes[
                                              //         e
                                              //       ] !== undefined
                                              //   ).length > 0
                                              // ) {
                                              let index = -1;
                                              return (
                                                <Tab.Pane
                                                  className='api-fields-subcontainer'
                                                  eventKey={it[0]}
                                                >
                                                  {it[1].map((i) => {
                                                    if (
                                                      props.product.productData
                                                        .product.data
                                                        .attributes[i] !==
                                                      undefined
                                                    ) {
                                                      if (
                                                        props.product
                                                          .productData.product
                                                          .data.attributes[i][
                                                          props.locale
                                                        ] !== undefined &&
                                                        props.product
                                                          .productData.product
                                                          .data.attributes[i][
                                                          props.locale
                                                        ] !== null
                                                      ) {
                                                        index++;
                                                        let content = props.product.productData.product.data.attributes[
                                                          i
                                                        ][
                                                          props.locale
                                                        ].replaceAll(
                                                          '&lt;br&gt;',
                                                          ''
                                                        );
                                                        return (
                                                          <div
                                                            className={
                                                              index % 2 === 0
                                                                ? 'api-field1'
                                                                : 'api-field2'
                                                            }
                                                          >
                                                            <h4 className='api-field-h4'>
                                                              {
                                                                props
                                                                  .documentTypeInfoMapping[
                                                                  props.product
                                                                    .productData
                                                                    .product
                                                                    .data
                                                                    .documentType
                                                                    .id
                                                                ][i]
                                                              }
                                                            </h4>
                                                            <div className='api-field-p'>
                                                              <Markup
                                                                tagName='p'
                                                                content={
                                                                  content
                                                                }
                                                              />
                                                            </div>
                                                            {/* <p className='api-field-p'>
                                                          {props.product.productData.product.data.attributes[
                                                            i
                                                          ][props.locale]
                                                            .replaceAll(
                                                              '<p>',
                                                              ''
                                                            )
                                                            .replaceAll(
                                                              '</p>',
                                                              ''
                                                            )}
                                                        </p> */}
                                                          </div>
                                                        );
                                                      } else if (
                                                        !isNaN(
                                                          props.product
                                                            .productData.product
                                                            .data.attributes[i]
                                                        ) ||
                                                        typeof props.product
                                                          .productData.product
                                                          .data.attributes[
                                                          i
                                                        ] === 'boolean'
                                                      ) {
                                                        index++;
                                                        return (
                                                          <div
                                                            className={
                                                              index % 2 === 0
                                                                ? 'api-field1'
                                                                : 'api-field2'
                                                            }
                                                          >
                                                            <h4 className='api-field-h4'>
                                                              {
                                                                props
                                                                  .documentTypeInfoMapping[
                                                                  props.product
                                                                    .productData
                                                                    .product
                                                                    .data
                                                                    .documentType
                                                                    .id
                                                                ][i]
                                                              }
                                                            </h4>
                                                            <p className='api-field-p'>
                                                              {props.product.productData.product.data.attributes[
                                                                i
                                                              ].toString()}
                                                            </p>
                                                          </div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div
                                                            style={{
                                                              display: 'none',
                                                            }}
                                                            className={
                                                              index % 2 === 0
                                                                ? 'api-field1'
                                                                : 'api-field2'
                                                            }
                                                          >
                                                            <h4 className='api-field-h4'>
                                                              {
                                                                props
                                                                  .documentTypeInfoMapping[
                                                                  props.product
                                                                    .productData
                                                                    .product
                                                                    .data
                                                                    .documentType
                                                                    .id
                                                                ][i]
                                                              }
                                                            </h4>
                                                            <p className='api-field-p'></p>
                                                          </div>
                                                        );
                                                      }
                                                    } else {
                                                      return (
                                                        <div
                                                          style={{
                                                            display: 'none',
                                                          }}
                                                          className={
                                                            index % 2 === 0
                                                              ? 'api-field1'
                                                              : 'api-field2'
                                                          }
                                                        >
                                                          <h4 className='api-field-h4'>
                                                            {
                                                              props
                                                                .documentTypeInfoMapping[
                                                                i
                                                              ]
                                                            }
                                                          </h4>
                                                          <p className='api-field-p'></p>
                                                        </div>
                                                      );
                                                    }
                                                  })}
                                                </Tab.Pane>
                                              );
                                              // }
                                            })}
                                          </Tab.Content>
                                        </Col>
                                      </Row>
                                    </Tab.Container>
                                  </Tab>
                                );
                              }
                            })}
                          </Tabs>
                        </Tab.Pane>
                      );
                    }
                  })}
                </Tab.Content>
              </Row>
            </Col>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    locale: state.i18n.locale,
    documentTypeInfo: state.product.documentTypeInfo,
    documentTypeInfoMapping: state.product.documentTypeInfoMapping,
    product: state.product,
  };
}

export default connect(mapStateToProps)(AllProductInfo);
