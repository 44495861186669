import {
  REQUEST_SEARCH_RESULTS,
  RECEIVE_PRODUCTS_SEARCH_RESULTS,
  REQUEST_PRODUCTS_SEARCH_RESULTS_FAILED,
  RECEIVE_MEDIAS_SEARCH_RESULTS,
  REQUEST_MEDIAS_SEARCH_RESULTS_FAILED,
  REQUEST_ATTRIBUTE_SETS,
  RECEIVE_ATTRIBUTE_SETS,
  REQUEST_DOCUMENT_TYPES,
  RECEIVE_DOCUMENT_TYPES,
} from './types';
import axios from 'axios';

export const requestSearchResults = (search_input) => {
  return {
    type: REQUEST_SEARCH_RESULTS,
    payload: search_input,
  };
};

export const requestProductsSearchResultsFailed = (err) => {
  return {
    type: REQUEST_PRODUCTS_SEARCH_RESULTS_FAILED,
    payload: err,
  };
};

export const receiveProductsSearchResults = (search) => {
  return {
    type: RECEIVE_PRODUCTS_SEARCH_RESULTS,
    payload: {
      search_input: search['search_input'],
      nbresults: search['nbresults'],
      products: search['products'],
    },
  };
};

export function fetchSearchResults(
  search_input,
  dates,
  documentTypesIds,
  attributeSetsIds,
  classificationIds,
  mediasClassificationIds,
  pageNumber,
  locale,
  audience
) {
  return (dispatch) => {
    dispatch(requestSearchResults(search_input));
    // delete after quable fixes the api call
    classificationIds =
      classificationIds.length > 137
        ? classificationIds.slice(0, 137)
        : classificationIds;
    dispatch(
      fetchProductsSearchResults(
        search_input,
        dates,
        documentTypesIds,
        attributeSetsIds,
        classificationIds,
        pageNumber,
        locale,
        audience
      )
    );
    // delete after quable fixes the api call
    mediasClassificationIds =
      mediasClassificationIds.length > 137
        ? mediasClassificationIds.slice(0, 137)
        : mediasClassificationIds;
    dispatch(
      fetchMediasSearchResults(
        search_input,
        dates,
        mediasClassificationIds,
        pageNumber,
        audience
      )
    );
  };
}

function audienceParameterToArray(object, array) {
  var a = Object.entries(object);
  if (typeof a[0][1] === 'string') {
    array.push(a[0][0]);
    return [array.join('.'), a[0][1]];
  } else {
    array.push(a[0][0]);
    return audienceParameterToArray(a[0][1], array);
  }
}

export function fetchProductsSearchResults(
  search_input,
  dates,
  documentTypesIds,
  attributeSetsIds,
  classificationIds,
  pageNumber,
  locale,
  audience
) {
  console.log('REQUESTED AUDIENCE :', audience);
  let addFilterAudience = '';
  if (audience && audience.filters) {
    let audienceParameter = audienceParameterToArray(audience.filters, []);
    addFilterAudience = '&' + audienceParameter[0] + '=' + audienceParameter[1];
  }
  return (dispatch) => {
    let documentTypesIdsCall =
      documentTypesIds !== undefined
        ? documentTypesIds.map((item) => '&documentType.id%5B%5D=' + item)
        : [];
    let attributeSetsIdsCall =
      attributeSetsIds !== undefined
        ? attributeSetsIds.map((item) => '&attributeSet.id%5B%5D=' + item)
        : [];
    let classificationIdsCall =
      classificationIds !== undefined
        ? classificationIds.map((item) => '&classifications.id%5B%5D=' + item)
        : [];
    return axios
      .get(
        dates !== undefined
          ? process.env.REACT_APP_API_URL +
              '/documents?name=' +
              search_input +
              (dates['dateCreatedAfter'] !== null
                ? '&dateCreated%5Bafter%5D=' + dates['dateCreatedAfter']
                : '') +
              (dates['dateCreatedBefore'] !== null
                ? '&dateCreated%5Bbefore%5D=' + dates['dateCreatedBefore']
                : '') +
              (dates['dateModifiedAfter'] !== null
                ? '&dateModified%5Bafter%5D=' + dates['dateModifiedAfter']
                : '') +
              (dates['dateModifiedBefore'] !== null
                ? '&dateModified%5Bbefore%5D=' + dates['dateModifiedBefore']
                : '') +
              documentTypesIdsCall.join('') +
              attributeSetsIdsCall.join('') +
              classificationIdsCall.join('') +
              '&page=' +
              pageNumber +
              '&limit=6&pagination=true' +
              addFilterAudience
          : process.env.REACT_APP_API_URL +
              '/documents?name=' +
              search_input +
              classificationIdsCall.join('') +
              '&page=' +
              pageNumber +
              '&limit=6&pagination=true' +
              addFilterAudience,
        {}
      )
      .then((response) => {
        dispatch(
          receiveProductsSearchResults({
            nbresults: response.data['hydra:totalItems'],
            products: response.data['hydra:member'],
          })
        );
      })
      .catch((err) => {
        dispatch(requestProductsSearchResultsFailed(err));
      });
  };
}

export const requestMediasSearchResultsFailed = (err) => {
  return {
    type: REQUEST_MEDIAS_SEARCH_RESULTS_FAILED,
    payload: err,
  };
};

export const receiveMediasSearchResults = (search) => {
  return {
    type: RECEIVE_MEDIAS_SEARCH_RESULTS,
    payload: {
      search_input: search['search_input'],
      nbresults: search['nbresults'],
      medias: search['medias'],
    },
  };
};

export function fetchMediasSearchResults(
  search_input,
  dates,
  classificationIds,
  pageNumber,
  audience
) {
  let addFilterAudience = '';
  if (audience && audience.filters) {
    let audienceParameter = audienceParameterToArray(audience.filters, []);
    addFilterAudience = '&' + audienceParameter[0] + '=' + audienceParameter[1];
  }
  return (dispatch) => {
    let classificationIdsCall =
      classificationIds !== undefined
        ? classificationIds.map((item) => '&classifications.id%5B%5D=' + item)
        : [];
    return axios
      .get(
        dates !== undefined
          ? process.env.REACT_APP_API_URL +
              '/assets?name=' +
              search_input +
              (dates['dateCreatedAfter'] !== null
                ? '&dateCreated%5Bafter%5D=' + dates['dateCreatedAfter']
                : '') +
              (dates['dateCreatedBefore'] !== null
                ? '&dateCreated%5Bbefore%5D=' + dates['dateCreatedBefore']
                : '') +
              (dates['dateModifiedAfter'] !== null
                ? '&dateModified%5Bafter%5D=' + dates['dateModifiedAfter']
                : '') +
              (dates['dateModifiedBefore'] !== null
                ? '&dateModified%5Bbefore%5D=' + dates['dateModifiedBefore']
                : '') +
              classificationIdsCall.join('') +
              '&page=' +
              pageNumber +
              '&limit=6&pagination=true' +
              addFilterAudience
          : process.env.REACT_APP_API_URL +
              '/assets?name=' +
              search_input +
              classificationIdsCall.join('') +
              '&page=' +
              pageNumber +
              '&limit=6&pagination=true' +
              addFilterAudience,
        {}
      )
      .then((response) => {
        dispatch(
          receiveMediasSearchResults({
            nbresults: response.data['hydra:totalItems'],
            medias: response.data['hydra:member'],
          })
        );
      })
      .catch((err) => {
        dispatch(requestMediasSearchResultsFailed(err));
      });
  };
}

export const requestAttributeSets = () => {
  return {
    type: REQUEST_ATTRIBUTE_SETS,
  };
};

export const receiveAttributeSets = (data) => {
  return {
    type: RECEIVE_ATTRIBUTE_SETS,
    payload: data,
  };
};

export const requestDocumentTypes = () => {
  return {
    type: REQUEST_DOCUMENT_TYPES,
  };
};

export const receiveDocumentTypes = (data) => {
  return {
    type: RECEIVE_DOCUMENT_TYPES,
    payload: data,
  };
};

export function fetchSearchParameters(locale, audience) {
  return (dispatch) => {
    dispatch(fetchAttributeSets(locale, audience));
    dispatch(fetchDocumentTypes(locale, audience));
  };
}

function fetchAttributeSets(locale, audience) {
  let addFilterAudience = '';
  if (audience && audience.filters) {
    let audienceParameter = audienceParameterToArray(audience.filters, []);
    addFilterAudience = '&' + audienceParameter[0] + '=' + audienceParameter[1];
  }
  return (dispatch) => {
    dispatch(requestAttributeSets());
    return axios
      .get(
        process.env.REACT_APP_API_URL + '/attribute-sets' + addFilterAudience,
        {}
      )
      .then((response) => {
        dispatch(
          receiveAttributeSets(
            response.data['hydra:member'].map((attributeSet) => {
              return {
                value: attributeSet.id,
                label: attributeSet.name[locale.replace('_', '-')],
              };
            })
          )
        );
      })
      .catch((err) => {});
  };
}

function fetchDocumentTypes(locale, audience) {
  let addFilterAudience = '';
  if (audience && audience.filters) {
    let audienceParameter = audienceParameterToArray(audience.filters, []);
    addFilterAudience = '&' + audienceParameter[0] + '=' + audienceParameter[1];
  }
  return (dispatch) => {
    dispatch(requestDocumentTypes());
    return axios
      .get(
        process.env.REACT_APP_API_URL + '/document-types' + addFilterAudience,
        {}
      )
      .then((response) => {
        dispatch(
          receiveDocumentTypes(
            response.data['hydra:member']
              .filter((item) => item.objectType === 'document')
              .map((documentType) => {
                return {
                  value: documentType.id,
                  label: documentType.name[locale.replace('_', '-')],
                };
              })
          )
        );
      })
      .catch((err) => {});
  };
}
