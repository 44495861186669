import {
  REQUEST_MEDIAS_CLASSIFICATION,
  RECEIVE_MEDIAS_CLASSIFICATION,
  REQUEST_MEDIAS_CLASSIFICATION_FAILED,
  UPDATE_MEDIAS_PATH,
  UPDATE_MEDIAS_VISIBLE_PATH_ITEMS,
  UPDATE_MEDIAS_CURRENT_PATH_ITEMS,
  SET_CURRENT_PATH_ASSETS_LENGTH,
  ADD_CURRENT_PATH_ASSET,
  RESET_CURRENT_PATH_ASSETS,
  SET_CURRENT_ASSETS_CLASSIFICATION_ID,
  SET_MEDIAS_TREEVIEW_EXPANDED,
  REQUEST_MEDIAS,
  RECEIVE_MEDIAS,
  REQUEST_MEDIAS_FAILED,
  RESET_MEDIAS,
  ADD_MEDIA_ATTRIBUTE_TO_MAPPING,
  RECEIVE_MEDIAS_CLASSIFICATION_IDS,
  SET_MEDIAS_IDS_TO_IMAGES,
} from './types';
import axios from 'axios';

function createArborescenceList(data, locale) {
  return data.map((item, index) => {
    if (!Array.isArray(item.fieldData)) {
      return item.children !== undefined && item.children.length > 0
        ? [
            item.fieldData.quable_classification_name.values[locale],
            createArborescenceList(item.children, locale),
          ]
        : item.fieldData.quable_classification_name.values[locale];
    }
  });
}
export { createArborescenceList };

function createArborescenceListOfIds(data, locale) {
  return data.map((item) => {
    return item.children !== undefined && item.children.length > 0
      ? [
          {
            value: item.id,
            label: item.fieldData.quable_classification_name.values[locale],
          },
          createArborescenceListOfIds(item.children, locale),
        ]
      : {
          value: item.id,
          label: item.fieldData.quable_classification_name.values[locale],
        };
  });
}
export { createArborescenceListOfIds };

function buttonsSetup(buttons, parentIndex, locale) {
  let newButtons = [];
  buttons.map((item, index) => {
    if (!Array.isArray(item.fieldData)) {
      newButtons.push([
        item.fieldData.quable_classification_name.values[locale],
        '',
        buttonsSetup(
          item.children,
          parentIndex.toString() + index.toString(),
          locale
        ),
        item.id,
        item.dateCreated,
        item.dateModified,
        parentIndex.toString() + index.toString(),
      ]);
    }
  });
  return newButtons;
}

function pathSetup(followedPath, locale) {
  let newPath = [];
  followedPath.map((item, index) => {
    newPath.push(item.fieldData.quable_classification_name.values[locale]);
  });
  return newPath;
}

// ACTION CREATORS

export function requestMediasClassification() {
  return {
    type: REQUEST_MEDIAS_CLASSIFICATION,
  };
}

export function receiveMediasClassification(data) {
  return {
    type: RECEIVE_MEDIAS_CLASSIFICATION,
    payload: data,
    receivedAt: Date.now(),
  };
}

function requestMediasClassificationFailed(err) {
  return {
    type: REQUEST_MEDIAS_CLASSIFICATION_FAILED,
    payload: err,
  };
}

export function requestMedias() {
  return {
    type: REQUEST_MEDIAS,
    receivedAt: Date.now(),
  };
}

export function requestMediasFailed() {
  return {
    type: REQUEST_MEDIAS_FAILED,
    receivedAt: Date.now(),
  };
}

export function receiveMedias() {
  return {
    type: RECEIVE_MEDIAS,
    receivedAt: Date.now(),
  };
}

export function addMediaAttributeToMapping(data) {
  return {
    type: ADD_MEDIA_ATTRIBUTE_TO_MAPPING,
    payload: data,
    receivedAt: Date.now(),
  };
}

export function setTheMapping(response, locale) {
  return (dispatch) => {
    response.map((mediaAttribute, index) => {
      dispatch(
        addMediaAttributeToMapping([
          mediaAttribute.id,
          mediaAttribute.name[locale.replace('_', '-')],
        ])
      );
    });
  };
}

export function setMediasAttributesMapping(locale) {
  return (dispatch) => {
    return axios
      .get(
        process.env.REACT_APP_API_URL +
          '/attributes?catalogs.id=pub&documentTypeId=media.quable_media&type=Asset',
        {}
      )
      .then((response) => {
        dispatch(setTheMapping(response.data['hydra:member'], locale));
      });
  };
}

export function fetchMedias(locale) {
  return (dispatch) => {
    dispatch(requestMediasClassification());
    return axios
      .get(
        process.env.REACT_APP_API_URL +
          '/catalogs/dam/classifications?classification_id=dam',
        {}
      )
      .then((response) => {
        let newButtons = buttonsSetup(
          response.data['hydra:member'][0].children,
          '',
          locale
        );
        let newPath = pathSetup([], locale);
        dispatch(updateMediasCurrentPathItems([['Medias', newButtons]]));
        dispatch(updateMediasVisiblePathItems(newButtons));
        dispatch(updateMediasPath(newPath));
        dispatch(setCurrentAssetsClassificationId(null));
        dispatch(
          receiveClassificationIds(
            createArborescenceListOfIds(
              response.data['hydra:member'][0].children,
              locale
            ).flat(Infinity)
          )
        );
        dispatch(
          receiveMediasClassification(
            createArborescenceList(
              response.data['hydra:member'][0].children,
              locale
            )
          )
        );
        dispatch(setMediasAttributesMapping(locale));
        axios
          .get(
            process.env.REACT_APP_API_URL +
              '/classifications?catalog.code=' +
              'dam',
            {}
          )
          .then((response) => {
            axios
              .get(
                process.env.REACT_APP_API_URL +
                  '/classifications?catalog.code=' +
                  'dam&limit=' +
                  response.data['hydra:totalItems'] +
                  '&pagination=false',
                {}
              )
              .then((response) => {
                let mediasIdsToImages = {};
                response.data['hydra:member'].map((classification) => {
                  mediasIdsToImages[classification.id] =
                    classification.mainAssetUrl;
                });
                dispatch(setMediasIdsToImages(mediasIdsToImages));
              });
          });
      })
      .catch((err) => {
        dispatch(requestMediasClassificationFailed(err));
      });
  };
}

export function updateMediasPath(path) {
  return {
    type: UPDATE_MEDIAS_PATH,
    payload: path,
    changedAt: Date.now(),
  };
}

export function updateMediasVisiblePathItems(items) {
  return {
    type: UPDATE_MEDIAS_VISIBLE_PATH_ITEMS,
    payload: items,
    changedAt: Date.now(),
  };
}

export function updateMediasCurrentPathItems(items) {
  return {
    type: UPDATE_MEDIAS_CURRENT_PATH_ITEMS,
    payload: items,
    changedAt: Date.now(),
  };
}

export function initializeItemsContainerAction(id, nbPage, nbValues) {
  return (dispatch) => {
    dispatch(requestMedias());
    return axios
      .get(
        process.env.REACT_APP_API_URL +
          '/assets?classifications.id=' +
          id +
          '&page=' +
          nbPage +
          '&limit=' +
          nbValues,
        {}
      )
      .then((response) => {
        if (response.data['hydra:totalItems'] === 0) {
          dispatch(receiveMedias());
        }
        dispatch(resetCurrentPathAssets());
        dispatch(setCurrentPathAssetsLength(response.data['hydra:totalItems']));

        response.data['hydra:member'].map((item, index) => {
          dispatch(addCurrentPathAsset(item));
          if (
            index === response.data['hydra:member'].length - 1 ||
            response.data['hydra:member'].length * nbPage -
              (response.data['hydra:member'].length - index) ===
              response.data['hydra:totalItems'] - 1
          ) {
            dispatch(receiveMedias());
          }
        });
      })
      .catch((err) => {
        dispatch(requestMediasFailed(err));
      });
  };
}

export function setMediasTreeViewExpanded(expanded) {
  return {
    type: SET_MEDIAS_TREEVIEW_EXPANDED,
    payload: expanded,
    changedAt: Date.now,
  };
}

export function setCurrentAssetsClassificationId(id) {
  return {
    type: SET_CURRENT_ASSETS_CLASSIFICATION_ID,
    payload: id,
    addedAt: Date.now(),
  };
}

export function setCurrentPathAssetsLength(totalLength) {
  return {
    type: SET_CURRENT_PATH_ASSETS_LENGTH,
    payload: totalLength,
    addedAt: Date.now(),
  };
}

export function addCurrentPathAsset(asset) {
  return {
    type: ADD_CURRENT_PATH_ASSET,
    payload: asset,
    addedAt: Date.now(),
  };
}

export function resetCurrentPathAssets() {
  return {
    type: RESET_CURRENT_PATH_ASSETS,
    changedAt: Date.now(),
  };
}

export function resetMedias() {
  return {
    type: 'RESET_MEDIAS',
    updatedAt: Date.now(),
  };
}

export function receiveClassificationIds(data) {
  return {
    type: RECEIVE_MEDIAS_CLASSIFICATION_IDS,
    payload: data,
    receivedAt: Date.now(),
  };
}

export function setMediasIdsToImages(data) {
  return {
    type: SET_MEDIAS_IDS_TO_IMAGES,
    payload: data,
    receivedAt: Date.now(),
  };
}
