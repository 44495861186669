import {
  REQUEST_PRODUCT,
  RECEIVE_PRODUCT,
  REQUEST_PRODUCT_FAILED,
  REQUEST_PATH,
  REQUEST_PATH_FAILED,
  RECEIVE_PATH,
  REQUEST_ATTRIBUTES,
  RECEIVE_ATTRIBUTES,
  SET_ASSETS_LINK_TYPES_MAPPING,
  REQUEST_ASSETS,
  RECEIVE_ASSETS,
  REQUEST_ASSET_FAILED,
  REQUEST_VARIANTS,
  RECEIVE_VARIANTS,
  REQUEST_VARIANT_FAILED,
  REQUEST_DOCUMENT_LINKS,
  RECEIVE_DOCUMENT_LINKS,
  REQUEST_DOCUMENT_LINK_FAILED,
  REQUEST_DOCUMENT_TYPE_INFO,
  RECEIVE_DOCUMENT_TYPE_INFO,
  ADD_DOCUMENT_TYPE_INFO_ATTRIBUTE_TO_MAPPING,
} from '../actions/types';

const initialState = {
  isFetchingProduct: false,
  isFetchingPath: false,
  isFetchingAttributes: false,
  isFetchingAssets: false,
  isFetchingVariants: false,
  isFetchingDocumentLinks: false,
  productData: {
    status: 'ok',
    error: {},
    product: undefined,
  },
  pathData: [],
  attributesData: [],
  assetsLinkTypesMapping: null,
  assetsData: {
    status: 'ok',
    errors: [],
    assets: [],
  },
  variantsData: {
    status: 'ok',
    errors: [],
    variants: [],
    variantsNamesToCodesMapping: [],
  },
  documentLinksData: {
    status: 'ok',
    errors: [],
    documentLinks: {},
    documentLinksNamesToCodesMapping: {},
  },
  documentTypeInfo: {},
  documentTypeInfoMapping: {},
};

export function productReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_PRODUCT:
      return Object.assign({}, state, { isFetchingProduct: true });
    case RECEIVE_PRODUCT:
      return Object.assign({}, state, {
        isFetchingProduct: false,
        productData: {
          status: 'ok',
          error: {},
          product: action.payload,
        },
        lastUpdated: action.receivedAt,
      });
    case REQUEST_PRODUCT_FAILED:
      return Object.assign({}, state, {
        isFetchingProduct: false,
        productData: {
          status: 'error',
          error: action.payload,
          product: undefined,
        },
      });
    case REQUEST_PATH:
      return Object.assign({}, state, { isFetchingPath: true });
    case REQUEST_PATH_FAILED:
      return Object.assign({}, state, {
        isFetchingPath: false,
      });
    case RECEIVE_PATH:
      return Object.assign({}, state, {
        isFetchingPath: false,
        pathData: action.payload,
        lastUpdated: action.receivedAt,
      });
    case REQUEST_ATTRIBUTES:
      return Object.assign({}, state, { isFetchingAttributes: true });
    case RECEIVE_ATTRIBUTES:
      return Object.assign({}, state, {
        isFetchingAttributes: false,
        attributesData: action.payload,
        lastUpdated: action.receivedAt,
      });
    case SET_ASSETS_LINK_TYPES_MAPPING:
      return Object.assign({}, state, {
        assetsLinkTypesMapping: action.payload,
        lastUpdated: action.setAt,
      });
    case REQUEST_ASSETS:
      return Object.assign({}, state, { isFetchingAssets: true });
    case RECEIVE_ASSETS:
      return Object.assign({}, state, {
        isFetchingAssets: false,
        assetsData: {
          status: state.assetsData.status,
          errors: state.assetsData.errors,
          assets: action.payload,
        },
        lastUpdated: action.receivedAt,
      });
    case REQUEST_ASSET_FAILED:
      return Object.assign({}, state, {
        assetsData: {
          status: 'error',
          errors: [...state.assetsData.errors, action.payload],
          assets: state.assetsData.assets,
        },
      });
    case REQUEST_VARIANTS:
      return Object.assign({}, state, { isFetchingVariants: true });
    case REQUEST_VARIANT_FAILED:
      return Object.assign({}, state, {
        variantsData: {
          status: 'error',
          errors: [...state.variantsData.errors, action.payload],
          variants: state.variantsData.variants,
          variantsNamesToCodesMapping:
            state.variantsData.variantsNamesToCodesMapping,
        },
      });
    case RECEIVE_VARIANTS:
      return Object.assign({}, state, {
        isFetchingVariants: false,
        variantsData: {
          status: state.variantsData.status,
          errors: state.variantsData.errors,
          variants: action.payload['variants'],
          variantsNamesToCodesMapping:
            action.payload['variantsNamesToCodesMapping'],
        },
        lastUpdated: action.receivedAt,
      });
    case REQUEST_DOCUMENT_LINKS:
      return Object.assign({}, state, { isFetchingDocumentLinks: true });
    case REQUEST_DOCUMENT_LINK_FAILED:
      return Object.assign({}, state, {
        documentLinksData: {
          status: 'error',
          errors: [...state.documentLinksData.errors, action.payload],
          documentLinks: state.documentLinksData.documentLinks,
        },
      });
    case RECEIVE_DOCUMENT_LINKS:
      return Object.assign({}, state, {
        isFetchingDocumentLinks: false,
        documentLinksData: {
          status: state.documentLinksData.status,
          errors: state.documentLinksData.errors,
          documentLinks: action.payload['documentLinks'],
          documentLinksNamesToCodesMapping:
            action.payload['documentLinksNamesToCodesMapping'],
        },
        lastUpdated: action.receivedAt,
      });
    case REQUEST_DOCUMENT_TYPE_INFO:
      let dti = { ...state.documentTypeInfo };
      dti[action.payload] = {};
      let dtim = { ...state.documentTypeInfoMapping };
      dtim[action.payload] = {};
      return Object.assign({}, state, {
        documentTypeInfo: dti,
        documentTypeInfoMapping: dtim,
      });
    case ADD_DOCUMENT_TYPE_INFO_ATTRIBUTE_TO_MAPPING:
      let newObj = { ...state.documentTypeInfoMapping };
      newObj[action.payload.id][action.payload.data[0]] =
        action.payload.data[1];
      return Object.assign({}, state, {
        documentTypeInfoMapping: newObj,
        lastUpdated: action.addedAt,
      });
    case RECEIVE_DOCUMENT_TYPE_INFO:
      let dti2 = { ...state.documentTypeInfo };
      dti2[action.payload.id] = action.payload.data;
      return Object.assign({}, state, {
        documentTypeInfo: dti2,
        lastUpdated: action.addedAt,
      });
    default:
      return state;
  }
}
