import { ADD_TO_CART, REMOVE_FROM_CART, EMPTY_CART } from '../actions/types';

const initialState = {
  items: [],
};

export const cartReducer = (state = initialState, action) => {
  let items = state.items;
  switch (action.type) {
    case ADD_TO_CART:
      items.push(action.payload);
      return {
        ...state,
        items: items,
      };
    case REMOVE_FROM_CART:
      let newItems = [...items];
      newItems = newItems.filter(
        (item, index) => item['id'] !== action.payload.productId
      );
      return {
        ...state,
        items: newItems,
      };
    case EMPTY_CART:
      return {
        ...state,
        items: [],
      };
    default:
      return state;
  }
};
