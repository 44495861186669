import React, { useState, useEffect } from 'react';
import {
  Header,
  ImagesAndInfo,
  AllProductInfo,
  HorizontalScrollInfo,
  DocumentLinksInfo,
  RelatedMedia,
  TreeViewSelectedPath,
} from '../components';
import '../styles/pages/ProductPage.css';
import { fetchProduct } from '../redux/actions/productActions';
import { connect } from 'react-redux';
import Footer from '../components/Footer';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';

const ProductPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.currentPathItems.length > 0 && props.items.length > 0) {
      props.dispatch(
        fetchProduct(
          props.location.search.replace('?', ''),
          props.locale,
          props.items,
          props.currentPathItems,
          props.audience,
          props.classificationIds.map((item) => item['value']),
          props.pimParameters.classificationCatalog
        )
      );
    }
  }, [props.items]);
  const history = useHistory();

  function updateHistory(path) {
    if (path.length > 0) {
      history.push({
        pathname: '/produits',
        search: '?' + path,
      });
    } else {
      history.push({
        pathname: '/produits',
      });
    }
  }

  // if (
  //   props.product.isFetchingProduct === true ||
  //   props.product.isFetchingPath === true ||
  //   props.product.isFetchingAttributes === true ||
  //   props.product.isFetchingAssets === true ||
  //   props.product.isFetchingVariants === true ||
  //   props.product.isFetchingDocumentLinks === true ||
  //   props.product.productData.product === undefined
  // ) {
  //   return (
  //     <div>
  //       <Header />
  //       <div className='productpage-loader-container'>
  //         <p className='productpage-loader-msg'>Chargement du produit</p>
  //         <Loader type='TailSpin' color='black' height={100} width={100} />
  //       </div>
  //     </div>
  //   );
  // } else {
  return (
    <div>
      <Header />
      <div className='productpage-path-container'>
        <div className='productpage-path'>
          <TreeViewSelectedPath
            whereFrom='ProductPage'
            path={props.path}
            updateHistory={updateHistory}
          />
        </div>
      </div>
      {!props.product.isFetchingProduct ? (
        <ImagesAndInfo />
      ) : (
        <div className='productpage-loader-container'>
          <p className='productpage-loader-msg'>
            Chargement des infos du produit
          </p>
          <Loader type='TailSpin' color='black' height={100} width={100} />
        </div>
      )}
      {!props.product.isFetchingAttributes ? (
        <AllProductInfo />
      ) : (
        <div className='productpage-loader-container'>
          <p className='productpage-loader-msg'>
            Chargement des champs du produit
          </p>
          <Loader type='TailSpin' color='black' height={100} width={100} />
        </div>
      )}
      {!props.product.isFetchingVariants ? (
        <HorizontalScrollInfo />
      ) : (
        <div className='productpage-loader-container'>
          <p className='productpage-loader-msg'>Chargement des SKUS</p>
          <Loader type='TailSpin' color='black' height={100} width={100} />
        </div>
      )}
      {!props.product.isFetchingDocumentLinks ? (
        <DocumentLinksInfo />
      ) : (
        <div className='productpage-loader-container'>
          <p className='productpage-loader-msg'>
            Chargement des documents associés
          </p>
          <Loader type='TailSpin' color='black' height={100} width={100} />
        </div>
      )}
      {/* <DocumentLinksTable type='link_checklist_articles' />
        <DocumentLinksTable type='link_crossell' /> */}
      {!props.product.isFetchingAssets ? (
        <RelatedMedia />
      ) : (
        <div className='productpage-loader-container'>
          <p className='productpage-loader-msg'>Chargement des médias</p>
          <Loader type='TailSpin' color='black' height={100} width={100} />
        </div>
      )}
      <Footer></Footer>
    </div>
  );
  // }
};

function mapStateToProps(state) {
  return {
    product: state.product,
    locale: state.i18n.locale,
    currentProducts: state.canal.currentProducts,
    path: state.canal.selectedPath,
    currentPathItems: state.canal.currentPathItems,
    items: state.canal.items,
    expanded: state.canal.expanded,
    audience: state.adminReducer.useraudience,
    classificationIds: state.canal.classificationIds,
    pimParameters: state.adminReducer.instancedata.pimParameters,
  };
}

export default connect(mapStateToProps)(ProductPage);
