// In src/pages/Page.js
import React, { useEffect, useState } from 'react';
import NotFound from '../pages/NotFound';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import '../styles/fromprismic/Page.css';
import { MultiItemCarousel } from '../components';
import Fade from 'react-reveal/Fade';
import { Translate } from 'react-redux-i18n';
import { Redirect } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import { HeadingText1, ProductsBlocView } from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const Page = (props) => {
  const [notFound, toggleNotFound] = useState(false);

  const images = ['', '', '', '', '', '', '', '', ''];

  const AutoplaySlider = withAutoplay(AwesomeSlider);
  // const { t } = useTranslation();

  function redirect(link) {
    console.log('link', link);
  }

  const [products, setProducts] = useState();
  const [medias, setMedias] = useState();

  useEffect(() => {
    if (props.productsMAJ.length > 0) {
      setProducts(
        <div>
          <Fade top>
            <HeadingText1 className='heading'>
              <Translate value='homepage.productsMAJ' />
              {/* {t('homepage.products')} */}
            </HeadingText1>
          </Fade>
          <div className='homepage-buttonscontainer'>
            <ProductsBlocView
              whereFrom='HProductsMAJ'
              tests={props.productsMAJ}
            />
            <div style={{ whiteSpace: 'pre' }}></div>
            <Fade top>
              <Link
                to={{ pathname: '/majpage', whereFrom: 'ProductsMAJ' }}
                className='tousproduits'
              >
                <FontAwesomeIcon
                  style={{ marginRight: 5, color: 'grey' }}
                  icon={faPlus}
                ></FontAwesomeIcon>
                <Translate
                  className='homepage-headingtxt'
                  value='homepage.seeall'
                />
                {/* {t('homepage.allproducts')} */}
              </Link>
            </Fade>
          </div>
        </div>
      );
    } else {
      setProducts(<div></div>);
    }

    if (props.mediasMAJ.length > 0) {
      setMedias(
        <div>
          <Fade top>
            <HeadingText1 className='heading'>
              <Translate value='homepage.mediasMAJ' />
              {/* {t('homepage.products')} */}
            </HeadingText1>
          </Fade>
          <div className='homepage-buttonscontainer'>
            <ProductsBlocView whereFrom='HMediasMAJ' tests={props.mediasMAJ} />
            <div style={{ whiteSpace: 'pre' }}></div>
            <Fade top>
              <Link
                to={{ pathname: '/majpage', whereFrom: 'MediasMAJ' }}
                className='tousproduits'
              >
                <FontAwesomeIcon
                  style={{ marginRight: 5, color: 'grey' }}
                  icon={faPlus}
                ></FontAwesomeIcon>
                <Translate
                  className='homepage-headingtxt'
                  value='homepage.seeall'
                />
                {/* {t('homepage.allproducts')} */}
              </Link>
            </Fade>
          </div>
        </div>
      );
    } else {
      setProducts(<div></div>);
    }
  }, [props.productsMAJ, props.mediasMAJ]);

  if (props.doc) {
    return (
      <div className='page'>
        {props.doc.data.body.map(function (slice, index) {
          switch (slice.slice_type) {
            case 'bloc_slider':
              var all = [];
              var width = slice.items[0].image.dimensions.width;
              slice.items.map(function (item, index) {
                all.push(
                  <div key={index} data-src={item.image.url}>
                    <a
                      className='sliderinfoa'
                      target='_blank'
                      href={item.link.url}
                    >
                      {/* <button className='sliderinfobtn'>
                        <p className='sliderinfobtntxt'>
                          <Translate value='homepage.slideriteminfo' />
                        </p>
                      </button> */}
                    </a>
                  </div>
                );
              });
              return (
                <Fade key={index}>
                  <div className='slidercontainer'>
                    <AutoplaySlider
                      className='awesomeslider'
                      play={true}
                      cancelOnInteraction={false}
                      interval={4000}
                      organicArrows={false}
                      style={{ maxHeight: props.doc.data.bloc_slider_height }}
                    >
                      {all}
                    </AutoplaySlider>
                  </div>
                </Fade>
              );
            case 'derniere_mises_a_jour':
              if (slice.primary.titre !== undefined) {
                return (
                  // <Fade key={index}>
                  //   <div className='misesajourcontainer'>
                  //     <p className='misesajourtitle headingText1'>
                  //       {slice.primary.titre[0].text}
                  //     </p>
                  //     <MultiItemCarousel
                  //       allimages={images}
                  //       nbImages={slice.primary.nombre_de_contenus}
                  //     />
                  //   </div>
                  // </Fade>
                  <div>
                    {products}
                    {medias}
                  </div>
                );
              }
            case 'bloc_vignette':
              var all = [];
              slice.items.map(function (item, index) {
                all.push(
                  <Fade key={index}>
                    <div
                      className='blocvignettecontent'
                      style={{
                        width: props.doc.data.bloc_vignette_width,
                        height: props.doc.data.bloc_vignette_height,
                      }}
                    >
                      <a className='blocvignettecontenta' href={item.lien.url}>
                        <img
                          className='blocvignettecontentimage'
                          src={item.image_vignette.url}
                        ></img>
                        <h4 className='blocvignettecontenttitle'>
                          {item.titre_de_la_vignette[0].text}
                        </h4>
                      </a>
                    </div>
                  </Fade>
                );
              });
              return (
                <div key={index}>
                  <div className='CONTAINER'>{all}</div>
                </div>
              );
          }
        })}
      </div>
    );
  } else if (notFound) {
    return <NotFound />;
  }
  return null;
};

function mapStateToProps(state) {
  return {
    productsMAJ: state.homepageReducer.productsMAJ.data,
    mediasMAJ: state.homepageReducer.mediasMAJ.data,
  };
}

export default connect(mapStateToProps)(Page);
