import { combineReducers } from 'redux';
import { canalReducer } from './canalReducer';
import { productReducer } from './productReducer';
import { i18nReducer } from 'react-redux-i18n';
import { cartReducer } from './cartReducer';
import { mediasReducer } from './mediasReducer';
import { userReducer } from './userReducer';
import { searchReducer } from './searchReducer';
import { adminReducer } from './adminReducer';
import { homepageReducer } from './homepageReducer';
import { languagesReducer } from './languagesReducer';

const rootReducer = combineReducers({
  i18n: i18nReducer,
  canal: canalReducer,
  product: productReducer,
  cart: cartReducer,
  classification_medias: mediasReducer,
  user: userReducer,
  search: searchReducer,
  adminReducer: adminReducer,
  homepageReducer: homepageReducer,
  languagesReducer: languagesReducer,
});

export default rootReducer;
